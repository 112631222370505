import Toast from 'components/Toast';
import { useSelector } from 'react-redux';
import { uid } from 'react-uid';
import { getToasts } from 'state/modules/toasts';

export interface ToastRouterProps {}

const ToastRouter = () => {
  const toasts = useSelector(getToasts);

  return (
    <div className="toast-container" data-testid="toastRouter">
      {toasts.map((toast, index) => (
        <Toast key={uid(toast)} toastIndex={index} toast={toast} />
      ))}
    </div>
  );
};

export default ToastRouter;
