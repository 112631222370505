import AlignmentPlugin from '@ckeditor/ckeditor5-alignment/src/alignment';
import AutoFormatPlugin from '@ckeditor/ckeditor5-autoformat/src/autoformat';
import BoldPlugin from '@ckeditor/ckeditor5-basic-styles/src/bold';
import ItalicPlugin from '@ckeditor/ckeditor5-basic-styles/src/italic';
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';
import EssentialsPlugin from '@ckeditor/ckeditor5-essentials/src/essentials';
import LinkPlugin from '@ckeditor/ckeditor5-link/src/link';
import ListPlugin from '@ckeditor/ckeditor5-list/src/list';
import MentionPlugin from '@ckeditor/ckeditor5-mention/src/mention';
import ParagraphPlugin from '@ckeditor/ckeditor5-paragraph/src/paragraph';
import CKEditor from '@ckeditor/ckeditor5-react';
import WordCountPlugin from '@ckeditor/ckeditor5-word-count/src/wordcount';
import FieldText from 'components/FieldText';
import { useMemo, useState } from 'react';

interface Props {
  handleChange: (editorData: any) => void;
  mentions?: string[];
  maxLength?: number;
  classNames?: string;
  placeholder?: string;
  isDisabled?: boolean;
  isReadOnly?: boolean;
  testid?: string;
  hasCounter?: boolean;
  value?: any;
}

const TextArea = ({
  handleChange,
  mentions,
  maxLength,
  classNames,
  placeholder,
  isDisabled,
  isReadOnly,
  testid,
  hasCounter,
  value,
}: Props) => {
  const [characterCount, setCharacterCount] = useState<number>(maxLength || 5000);

  const changeValue = (event: any, editor: any) => {
    handleChange(editor.getData());
  };

  const config = useMemo(
    () => ({
      plugins: [
        EssentialsPlugin,
        BoldPlugin,
        ItalicPlugin,
        ParagraphPlugin,
        AlignmentPlugin,
        LinkPlugin,
        ListPlugin,
        MentionPlugin,
        AutoFormatPlugin,
        WordCountPlugin,
      ],
      toolbar: ['bold', 'italic', 'alignment', '|', 'bulletedList', 'numberedList', '|', 'link'],
      link: {
        addTargetToExternalLinks: true,
      },
      mention: {
        feeds: [
          {
            marker: '@',
            feed: () => (mentions as string[]).map((mention) => `@${mention}`),
            minimumCharacters: 0,
          },
        ],
      },
      wordCount: {
        onUpdate: ({ characters }: { words: number; characters: number }) => {
          setCharacterCount(characterCount - characters);
        },
      },
    }),
    [characterCount, mentions]
  );

  return (
    <>
      <div className={classNames || ''}>
        <CKEditor
          data={value}
          editor={ClassicEditor}
          config={config}
          placeholder={placeholder || ''}
          disabled={isDisabled || false}
          readOnly={isReadOnly || false}
          data-testid={testid}
          onChange={changeValue}
        />
      </div>
      {hasCounter && characterCount !== undefined ? <FieldText>{characterCount} characters remaining</FieldText> : null}
      <br></br>
    </>
  );
};

export default TextArea;
