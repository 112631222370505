import { useContext, useEffect, useState } from 'react';
import { ReactReduxContext, useSelector } from 'react-redux';
import {
  createPaginationActions,
  createPaginationReducer,
  getPaginationParams,
  getPaginationSorting,
} from 'state/modules/pagination';
import { PaginationParams, PaginationSort } from 'types';

type UsePaginationHook = {
  paginationParams: PaginationParams;
  paginationSorting: PaginationSort[];
  paginationActions: any;
};

const usePagination = (
  section: string,
  state: { params: Partial<PaginationParams>; sorting: PaginationSort[] } = { params: {}, sorting: [] }
): UsePaginationHook => {
  const [isReducerAdded, setIsReducerAdded] = useState<boolean>(false);
  const { store } = useContext(ReactReduxContext);
  const { addReducer } = store as any;
  const paginationParams: PaginationParams = useSelector(getPaginationParams(section));
  const paginationSorting: PaginationSort[] = useSelector(getPaginationSorting(section));
  const paginationActions = createPaginationActions(section);

  useEffect(() => {
    if (!isReducerAdded) {
      addReducer(`pagination.${section}`, createPaginationReducer(section, state));

      setIsReducerAdded(true);
    }
  }, [addReducer, section, state, isReducerAdded]);

  return {
    // @ts-ignore
    paginationParams: paginationParams ?? state.params,
    paginationSorting: paginationSorting ?? state.sorting,
    paginationActions,
  };
};

export default usePagination;
