import { normalizeState } from 'state/modules/utils';
import { Notification, PaginatedResults, StoreAction, StoreThunk } from 'types';

export const FETCH_ALL_NOTIFICATIONS = 'notifications/FETCH_ALL_NOTIFICATIONS';
export const FETCH_UNREAD_NOTIFICATIONS = 'notifications/FETCH_UNREAD_NOTIFICATIONS';
export const MARK_AS_READ = 'notifications/MARK_AS_READ';
export const RESET_NOTIFICATIONS = 'notifications/RESET_NOTIFICATIONS';
export const CHANGE_NOTIFICATIONS_TOTAL = 'notifications/CHANGE_NOTIFICATIONS_TOTAL';

export const fetchAllNotifications: StoreThunk =
  () =>
  async (dispatch, getState, { api }) => {
    try {
      const { results, totalResults }: PaginatedResults<Notification> = await api('/notifications/me/all');

      dispatch({
        type: FETCH_ALL_NOTIFICATIONS,
        data: { ...normalizeState('id', results), totalResults },
      });
    } catch (error) {
      dispatch({
        type: FETCH_ALL_NOTIFICATIONS,
        data: {
          ids: [],
          byId: {},
          totalResults: 0,
        },
      });
    }
  };

export const fetchUnreadNotifications: StoreThunk =
  () =>
  async (dispatch, getState, { api, history }) => {
    if (history.location.pathname !== '/login' && history.location.pathname !== '/logout') {
      try {
        const { totalResults }: PaginatedResults<Notification> = await api('/notifications/me/unread');
        dispatch({
          type: FETCH_UNREAD_NOTIFICATIONS,
          data: { totalResults },
        });
      } catch (error) {
        dispatch({
          type: FETCH_UNREAD_NOTIFICATIONS,
          data: {
            totalResults: 0,
          },
        });
      }
    }
  };

export const markAsRead: StoreThunk =
  (notification: Notification) =>
  async (dispatch, getState, { api }) => {
    try {
      const response: Notification[] = await api(`/notifications/read/${notification.id}`, {
        method: 'PUT',
        data: notification,
      });

      dispatch({
        type: MARK_AS_READ,
        data: {
          notification: response,
        },
      });
    } catch (error) {
      dispatch({
        type: MARK_AS_READ,
        data: {},
      });
    }
  };

export const resetNotifications: StoreAction = () => ({
  type: RESET_NOTIFICATIONS,
  data: {
    ids: [],
    byId: {},
  },
});

export const changetNotificationsTotal: StoreThunk = () => async (dispatch, getState) => {
  const state = getState();
  dispatch({
    type: CHANGE_NOTIFICATIONS_TOTAL,
    data: {
      ...state.notifications,
      totalResults: state.notifications.totalResults - 1,
    },
  });
};
