import { useState } from 'react';
import { ApiError } from 'types';

type Hook<T> = [(message: string) => void, (response: T | ApiError) => response is ApiError];

const useError = <T>(): Hook<T> => {
  const [, setError] = useState();

  return [
    (message: string) => {
      setError(() => {
        throw new Error(message);
      });
    },
    (response: T | ApiError): response is ApiError => {
      return (response as ApiError).status === 'error';
    },
  ];
};

export default useError;
