import api from 'services/api';
import { Notification, NotificationFiltersState } from 'types';

export const fetchNotificationTypes = async () => {
  const response: { id: number; name: string; displayName: string }[] = await api(`/user-groups/notification-types`);

  return response;
};

export const fetchNotificationCategories = async () => {
  const response: { id: number; name: string; displayName: string }[] = await api(`/notifications/categories`);

  return response;
};

export const markNotificationAsRead = async (notification: Notification) => {
  const response = await api(`/notifications/read/${notification.id}`, {
    method: 'PUT',
    data: notification,
  });

  return response;
};

export const markAllNotificationsAsRead = async (
  notificationFilters: NotificationFiltersState,
  dates: { startDate?: string; endDate?: string }
) => {
  const response = await api(`/notifications/read-all`, {
    method: 'PUT',
    data: { notificationFilters, dates },
  });

  return response;
};
