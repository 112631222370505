import immer from 'immer';
import { NotificationState, StoreReducer } from 'types';

import {
  CHANGE_NOTIFICATIONS_TOTAL,
  FETCH_ALL_NOTIFICATIONS,
  FETCH_UNREAD_NOTIFICATIONS,
  RESET_NOTIFICATIONS,
} from './actions';

export * from './actions';
export * from './selectors';

export const initialState: NotificationState = {
  ids: [],
  byId: {},
  totalResults: 0,
};

export const notificationsReducer: StoreReducer<NotificationState> = immer((draft, action) => {
  const { data } = action;
  let next = draft;

  switch (action.type) {
    case FETCH_ALL_NOTIFICATIONS:
    case FETCH_UNREAD_NOTIFICATIONS:
      next = data;
      break;
    case CHANGE_NOTIFICATIONS_TOTAL:
      next = data;
      break;
    case RESET_NOTIFICATIONS:
      next = initialState;
      break;
  }

  return next;
}, initialState);
