import { ProfileContact } from 'types';

type Hook = {
  formatContacts: (contacts: ProfileContact[]) => ProfileContact[];
  formatContactTypes: (type: string) => string;
};

const useProfileContacts = (): Hook => {
  const formatContacts = (contacts: ProfileContact[]): ProfileContact[] => {
    const LinkedIn: ProfileContact = { type: 'twitter', value: '', primary: 0 };
    const Twitter: ProfileContact = { type: 'linkedin', value: '', primary: 0 };
    if (contacts && !contacts.find((contact) => contact?.type === 'twitter')) {
      contacts.push(LinkedIn);
    }
    if (contacts && !contacts.find((contact) => contact?.type === 'linkedin')) {
      contacts.push(Twitter);
    }

    return contacts;
  };

  const formatContactTypes = (type: string): string => {
    let formattedType = '';

    switch (type) {
      case 'linkedin':
        formattedType = 'LinkedIn';
        break;
      case 'twitter':
        formattedType = 'Twitter';
        break;
      default:
        break;
    }

    return formattedType;
  };

  return { formatContacts, formatContactTypes };
};
export default useProfileContacts;
