import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';

const useIsCreate = () => {
  const location = useLocation();
  const [isCreate, setIsCreate] = useState<boolean>(false);

  useEffect(() => {
    if (location.pathname.search(/\/create/) !== -1) {
      setIsCreate(true);
    }
  }, [location]);

  return isCreate;
};
export default useIsCreate;
