import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';

const useIsJob = () => {
  const location = useLocation();
  const [isJob, setIsJob] = useState<boolean>(false);

  useEffect(() => {
    if (location.pathname.search(/\/work/) !== -1) {
      setIsJob(true);
    }
  }, [location]);

  return isJob;
};
export default useIsJob;
