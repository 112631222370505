import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';

const useIsUpdate = () => {
  const location = useLocation();
  const [isUpdate, setIsUpdate] = useState<boolean>(false);

  useEffect(() => {
    if (location.pathname.search(/\/update/) !== -1) {
      setIsUpdate(true);
    }
  }, [location]);

  return isUpdate;
};
export default useIsUpdate;
