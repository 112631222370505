import AppHeader from 'components/App/AppHeader';
import CompanyHeader from 'components/CompanyHeader';
import * as config from 'config';
import { useIsCompanyPage } from 'hooks';
import { useEffect } from 'react';

export interface HeaderProps {}

/**
 * Header component
 * @param {HeaderProps} props props for the component
 */
declare global {
  interface Window {
    _walkmeConfig?: { smartLoad: boolean };
  }
}
const Header = ({ isLoggedIn }) => {
  const { isCompanyPage, companySlug } = useIsCompanyPage();
  useEffect(() => {
    if (isLoggedIn) {
      const walkme = document.createElement('script');
      walkme.type = 'text/javascript';
      walkme.async = true;
      walkme.src = config.app.walkmeurl;
      const handleScriptLoad = () => {
        window._walkmeConfig = { smartLoad: true };
      };
      walkme.onload = handleScriptLoad;
      const head = document.head || document.getElementsByTagName('head')[0];
      head.appendChild(walkme);
    }
  }, [isLoggedIn]);
  return <header>{isCompanyPage ? <CompanyHeader slug={companySlug} /> : <AppHeader />}</header>;
};

export default Header;
