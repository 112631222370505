import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunkMiddleware from 'redux-thunk';
import api from 'services/api';
import history from 'services/history';

import { addReducer, setupReducers } from './setupReducers';

const setupStore = (initalValues = {}) => {
  const store = createStore(
    setupReducers(),
    initalValues,
    composeWithDevTools(applyMiddleware(thunkMiddleware.withExtraArgument({ api, history })))
  );

  // @ts-ignore
  store.addReducer = addReducer(store);
  // @ts-ignore
  store.reducers = {};

  return store;
};

export default setupStore;
