import { parse } from 'qs';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';

export const useUtmParams = (): string => {
  const location = useLocation();
  const query = parse(location.search, { ignoreQueryPrefix: true });
  const { qwsrc } = query;
  const [src, setSrc] = useState<string>('');

  useEffect(() => {
    if (qwsrc) {
      const src = (qwsrc as string).toLowerCase();
      setSrc(src);
    }
  }, [qwsrc]);

  return src;
};

export default useUtmParams;
