import moment, { DurationInputArg1, DurationInputArg2, Moment } from 'moment-timezone';

export const convertDate = (date: string | undefined, format: string = 'MM/DD/YYYY', utc: boolean = false): Moment => {
  let newDate: Moment = moment();

  if (typeof date === 'string' && utc) {
    newDate = moment.utc(date, 'YYYY-MM-DDTHH:mm:ss.SSS');
  } else if (typeof date === 'string' && typeof format === 'string' && format.length) {
    newDate = moment(date, format);
  } else {
    newDate = moment(date);
  }

  return newDate;
};

export const formatDate = (
  date: Moment | string | null | undefined,
  format: string = 'MMM DD, YYYY',
  relative: boolean = false,
  operation?: [string, DurationInputArg1, DurationInputArg2] | null,
  timezone?: string
): string => {
  let newDate: Moment = moment();
  let formattedDate = '';

  if (moment.isMoment(date)) {
    if (timezone) {
      newDate = date.tz(timezone);
    } else {
      newDate = date;
    }
  } else if (typeof date === 'string') {
    if ((date.match(/\d{2}\/\d{2}\/\d{4}/) || []).length) {
      if (timezone) {
        newDate = moment(date, 'MM/DD/YYYY').tz(timezone);
      } else {
        newDate = moment(date, 'MM/DD/YYYY');
      }
    } else {
      if (timezone) {
        newDate = moment.utc(date, 'YYYY-MM-DDTHH:mm:ss.SSS').tz(timezone);
      } else {
        newDate = moment.utc(date, 'YYYY-MM-DDTHH:mm:ss.SSS');
      }
    }
  }

  if (operation) {
    const [type, value, unit] = operation;

    newDate = type === 'add' ? newDate.add(value, unit) : newDate;
    newDate = type === 'subtract' ? newDate.subtract(value, unit) : newDate;
  }

  if (format && newDate.isValid()) {
    formattedDate = relative ? newDate.fromNow() : newDate.format(format);
  }

  return formattedDate;
};
