import get from 'lodash/get';
import set from 'lodash/set';
import { normalizeState } from 'state/modules/utils';
import { ApiError, CompanySetting, CompanySettingsInvoices, EmailTemplate, StoreAction, StoreThunk, User } from 'types';

export const actions = {
  FETCH_USER: 'user/FETCH_USER',
  FETCH_USER_SUCCESS: 'user/FETCH_USER_SUCCESS',
  FETCH_USER_ERROR: 'user/FETCH_USER_ERROR',

  FETCH_BASIC_USER_SUCCESS: 'user/FETCH_BASIC_USER_SUCCESS',

  INACTIVE_USER: 'user/INACTIVE_USER',
  RESET_USER: 'user/RESET_USER',

  SET_COMPANY_SETTING: 'user/SET_COMPANY_SETTING',

  SET_USER_DETAILS: 'user/SET_USER_DETAILS',

  DEL_USER_EMAIL_TEMPLATE: 'user/DEL_USER_EMAIL_TEMPLATE',
  SET_USER_EMAIL_TEMPLATE: 'user/SET_USER_EMAIL_TEMPLATE',
};

export const fetchUser: StoreThunk =
  () =>
  async (dispatch, getState, { api }) => {
    try {
      dispatch({
        type: actions.FETCH_USER,
      });

      const basicResponse: User | ApiError = await api('/users/me/basic');
      if (ifUser(basicResponse)) {
        const propsToNormalize = [
          { path: 'company.serviceCloudPartners', key: 'partnerId' },
          { path: 'company.suppliers', key: 'supplierId' },
        ];

        propsToNormalize.forEach((item) => {
          set(basicResponse, item.path, normalizeState(item.key, get(basicResponse, item.path)));
        });

        dispatch({
          type: actions.FETCH_BASIC_USER_SUCCESS,
          data: {
            user: basicResponse,
          },
        });
      }

      // 'users/me' call may return with an invalid user error, added ApiError typing to catch this
      const response: User | ApiError = await api('/users/me');

      // leveraged typeGuard for this condition to ensure the users/me response is a valid User object and not an error
      if (ifUser(response)) {
        const propsToNormalize = [
          { path: 'company.users', key: 'id' },
          { path: 'company.settings.profiles.dispositionReasons', key: 'id' },
          { path: 'company.settings.profiles.noteTypes', key: 'id' },
          { path: 'company.settings.jobs.jobSources', key: 'id' },
          { path: 'company.settings.jobs.jobStatuses', key: 'id' },
          { path: 'company.settings.jobs.jobStatusChangeReasons', key: 'id' },
          { path: 'company.settings.jobs.noteTypes', key: 'id' },
        ];

        propsToNormalize.forEach((item) => {
          set(response, item.path, normalizeState(item.key, get(response, item.path)));
        });

        dispatch({
          type: actions.FETCH_USER_SUCCESS,
          data: {
            user: response,
          },
        });
      } else if (response.code === 'userInactive' && response.status === 'error') {
        dispatch({
          type: actions.INACTIVE_USER,
          data: {
            err: response,
          },
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: actions.FETCH_USER_ERROR,
        data: {
          err: {
            message: 'Something went wrong fetching user data.',
          },
        },
      });
    }
  };

export const resetUser: StoreAction = () => ({
  type: actions.RESET_USER,
  data: {},
});

export const setCompanySettings: StoreAction = (data: CompanySetting) => ({
  type: actions.SET_COMPANY_SETTING,
  data,
});

export const setCompanySettingsJobs: StoreAction = (data: CompanySetting) => {
  console.log('setting company settings jobs function hit');
  let jobs = data.value;
  const paths = ['jobSources', 'jobStatuses', 'jobStatusChangeReasons', 'noteTypes'];
  paths.forEach((path) => {
    console.log(`setting updated work company settings values in path ${path}`);
    set(jobs, path, normalizeState('id', get(jobs, path)));
    console.log(`new values set in ${path} for work company settings`);
  });
  return {
    type: actions.SET_COMPANY_SETTING,
    data: {
      ...data,
      value: jobs,
    },
  };
};

export function setUserEmailTemplate(data: EmailTemplate) {
  return {
    type: actions.SET_USER_EMAIL_TEMPLATE,
    data,
  };
}

export function deleteUserEmailTemplate(data: EmailTemplate) {
  return {
    type: actions.DEL_USER_EMAIL_TEMPLATE,
    data,
  };
}

export const setCompanySettingsInvoices: StoreAction = (data: CompanySettingsInvoices) => ({
  type: actions.SET_COMPANY_SETTING,
  data,
});

export const setCompanySettingsProfiles: StoreAction = (data: CompanySetting) => {
  let profiles = data.value;
  const paths = ['dispositionReasons', 'noteTypes'];
  paths.forEach((path) => {
    console.log(`setting ${path} from updated company settings in redux`);
    set(profiles, path, normalizeState('id', get(profiles, path)));
    console.log(`successfully set ${path} from updated company settings in redux`);
  });
  return {
    type: actions.SET_COMPANY_SETTING,
    data: {
      ...data,
      value: profiles,
    },
  };
};

export const setUserDetails: StoreAction = (userDetails) => {
  return {
    type: actions.SET_USER_DETAILS,
    data: userDetails,
  };
};

// typeGuard function leveraged to confirm if the users/me response is of type User
export function ifUser(toBeDetermined: User | ApiError): toBeDetermined is User {
  if ((toBeDetermined as User).company) {
    return true;
  }
  return false;
}
