import { normalizeState } from 'state/modules/utils';
import {
  Category,
  DeliveryCenter,
  EducationLevel,
  EmsiSkill,
  ExperienceLevel,
  GlobalUsers,
  Location,
  ProfileCurrency,
  ProfileSource,
  RateCard,
  StoreThunk,
  Typeform,
} from 'types';

export const FETCH_CATEGORIES = 'metadata/FETCH_CATEGORIES';
export const FETCH_EDUCATION_LEVELS = 'metadata/FETCH_EDUCATION_LEVELS';
export const FETCH_EXPERIENCE_LEVELS = 'metadata/FETCH_EXPERIENCE_LEVELS';
export const FETCH_LOCATIONS = 'metadata/FETCH_LOCATIONS';
export const FETCH_SOURCES = 'metadata/FETCH_SOURCES';
export const FETCH_CURRENCIES = 'metadata/FETCH_CURRENCIES';
export const FETCH_DELIVERY_CENTERS = 'metadata/FETCH_DELIVERY_CENTERS';
export const FETCH_COMPENSATION_TYPES = 'metadata/FETCH_COMPENSATION_TYPES';
export const FETCH_LANGUAGES_METADATA = 'metadata/FETCH_LANGUAGES_METADATA';
export const FETCH_COMPENSATION_ClASSIFICATIONS = 'metadata/FETCH_COMPENSATION_CLASSIFICATIONS';
export const FETCH_PAYMENT_MODEL_TYPES = 'metadata/FETCH_PAYMENT_MODEL_TYPES';
export const FETCH_SKILLS = 'metadata/FETCH_SKILLS';
export const FETCH_CONTINGENT_RATE_CARDS = 'metadata/FETCH_CONTINGENT_RATE_CARDS';
export const FETCH_FULLTIME_RATE_CARDS = 'metadata/FETCH_FULLTIME_RATE_CARDS';
export const FETCH_EMSI_SKILLS = 'metadata/FETCH_EMSI_SKILLS';
export const FETCH_GLOBAL_USERS = 'metadata/FETCH_GLOBAL_USERS';
export const FETCH_TYPEFORMS = 'metadata/FETCH_TYPEFORMS';
export const FETCH_TIMEZONES = 'metadata/FETCH_TIMEZONES';
export const FETCH_WORK_CATEGORIES = 'metadata/FETCH_WORK_CATEGORIES';
export const FETCH_COMPANY_INVOICE_TYPES = 'metadata/FETCH_COMPANY_INVOICE_TYPES';

export const fetchMetadata: StoreThunk = (companyId: number) => async (dispatch) => {
  dispatch(fetchEducationLevels(companyId));
  dispatch(fetchExperienceLevels(companyId));
  dispatch(fetchLocations(companyId));
  dispatch(fetchSources(companyId));
  dispatch(fetchCurrencies());
  dispatch(fetchCompensationTypes());
  dispatch(fetchLanguagesMetadata());
  dispatch(fetchCompensationClassifications());
  dispatch(fetchPaymentModelTypes());
  dispatch(fetchContingentRateCards(companyId));
  dispatch(fetchFulltimeRateCards(companyId));
  dispatch(fetchEmsiSkills(companyId));
  dispatch(fetchGlobalUsers(companyId));
  dispatch(fetchTypeforms(companyId));
  dispatch(fetchTimezones());
  dispatch(fetchWorkCategories());
  dispatch(fetchDeliveryCenters());
  dispatch(fetchCompanyInvoiceTypes());
};

export const fetchMetadataPublic: StoreThunk = (companyId: number) => async (dispatch) => {
  dispatch(fetchEducationLevelsPublic(companyId));
};

export const fetchCategories: StoreThunk =
  (companyId: number) =>
  async (dispatch, getState, { api }) => {
    try {
      const response: Category[] = await api(`/metadata/${companyId}/categories`);

      dispatch({
        type: FETCH_CATEGORIES,
        data: normalizeState('id', response),
      });
    } catch (error) {
      // handle error
    }
  };

export const fetchEducationLevels: StoreThunk =
  (companyId: number) =>
  async (dispatch, getState, { api }) => {
    try {
      const response: EducationLevel[] = await api(`/metadata/${companyId}/education-levels`);

      dispatch({
        type: FETCH_EDUCATION_LEVELS,
        data: normalizeState('id', response),
      });
    } catch (error) {
      // handle error
    }
  };

export const fetchEducationLevelsPublic: StoreThunk =
  (companyId: number) =>
  async (dispatch, getState, { apiPublic }) => {
    try {
      const response: EducationLevel[] = await apiPublic(`/metadata/${companyId}/education-levels`);

      dispatch({
        type: FETCH_EDUCATION_LEVELS,
        data: normalizeState('id', response),
      });
    } catch (error) {
      // handle error
    }
  };

export const fetchExperienceLevels: StoreThunk =
  (companyId: number) =>
  async (dispatch, getState, { api }) => {
    try {
      const response: ExperienceLevel[] = await api(`/metadata/${companyId}/experience-levels`);

      dispatch({
        type: FETCH_EXPERIENCE_LEVELS,
        data: normalizeState('id', response),
      });
    } catch (error) {
      // handle error
    }
  };

export const fetchLocations: StoreThunk =
  (companyId: number) =>
  async (dispatch, getState, { api }) => {
    try {
      const response: Location[] = await api(`/metadata/${companyId}/locations`);

      dispatch({
        type: FETCH_LOCATIONS,
        data: normalizeState('id', response),
      });
    } catch (error) {
      // handle error
    }
  };

export const fetchSources: StoreThunk =
  (companyId: number) =>
  async (dispatch, getState, { api }) => {
    try {
      const response: ProfileSource[] = await api(`/metadata/${companyId}/sources`);

      dispatch({
        type: FETCH_SOURCES,
        data: normalizeState('id', response),
      });
    } catch (error) {
      // handle error
    }
  };

export const fetchCurrencies: StoreThunk =
  () =>
  async (dispatch, getState, { api }) => {
    try {
      const response: ProfileCurrency[] = await api(`/metadata/currencies`);

      dispatch({
        type: FETCH_CURRENCIES,
        data: normalizeState('id', response),
      });
    } catch (error) {
      // handle error
    }
  };

export const fetchDeliveryCenters: StoreThunk =
  () =>
  async (dispatch, getState, { api }) => {
    try {
      const response: DeliveryCenter[] = await api(`/metadata/delivery-centers`);

      dispatch({
        type: FETCH_DELIVERY_CENTERS,
        data: normalizeState('id', response),
      });
    } catch (error) {
      // here it should dispatch an error action.
    }
  };

export const fetchCompensationTypes: StoreThunk =
  () =>
  async (dispatch, getState, { api }) => {
    try {
      const response: { id: number; name: string }[] = await api(`/metadata/compensation-types`);

      dispatch({
        type: FETCH_COMPENSATION_TYPES,
        data: normalizeState('id', response),
      });
    } catch (error) {
      // handle error
    }
  };

export const fetchLanguagesMetadata: StoreThunk =
  () =>
  async (dispatch, getState, { api }) => {
    try {
      const response: { id: number; name: string }[] = await api(`/metadata/languages-metadata`);

      dispatch({
        type: FETCH_LANGUAGES_METADATA,
        data: response,
      });
    } catch (error) {
      // handle error
    }
  };

export const fetchCompensationClassifications: StoreThunk =
  () =>
  async (dispatch, getState, { api }) => {
    try {
      const response: { id: number; name: string }[] = await api(`/metadata/compensation-classifications`);

      dispatch({
        type: FETCH_COMPENSATION_ClASSIFICATIONS,
        data: normalizeState('id', response),
      });
    } catch (error) {
      // handle error
    }
  };

export const fetchPaymentModelTypes: StoreThunk =
  () =>
  async (dispatch, getState, { api }) => {
    try {
      const response: { id: number; name: string }[] = await api(`/metadata/payment-model-types`);

      dispatch({
        type: FETCH_PAYMENT_MODEL_TYPES,
        data: normalizeState('id', response),
      });
    } catch (error) {
      // handle error
    }
  };

export const fetchContingentRateCards: StoreThunk =
  (companyId: number) =>
  async (dispatch, getState, { api }) => {
    try {
      const response: RateCard[] = await api(`/metadata/${companyId}/rate-cards/contingent`);

      dispatch({
        type: FETCH_CONTINGENT_RATE_CARDS,
        data: normalizeState('id', response),
      });
    } catch (error) {
      // handle error
    }
  };

export const fetchFulltimeRateCards: StoreThunk =
  (companyId: number) =>
  async (dispatch, getState, { api }) => {
    try {
      const response: RateCard[] = await api(`/metadata/${companyId}/rate-cards/full-time`);

      dispatch({
        type: FETCH_FULLTIME_RATE_CARDS,
        data: normalizeState('id', response),
      });
    } catch (error) {
      // handle error
    }
  };

export const fetchEmsiSkills: StoreThunk =
  (companyId: number) =>
  async (dispatch, getState, { api }) => {
    try {
      const response: EmsiSkill[] = await api(`/metadata/${companyId}/emsi-skills`);

      dispatch({
        type: FETCH_EMSI_SKILLS,
        data: normalizeState('id', response),
      });
    } catch (error) {
      // handle error
    }
  };

export const fetchGlobalUsers: StoreThunk =
  (companyId: number) =>
  async (dispatch, getState, { api }) => {
    try {
      const response: GlobalUsers[] = await api(`/metadata/${companyId}/global-users`);
      dispatch({
        type: FETCH_GLOBAL_USERS,
        data: normalizeState('id', response),
      });
    } catch (error) {
      // handle error
    }
  };

export const fetchTypeforms: StoreThunk =
  (companyId: number) =>
  async (dispatch, getState, { api }) => {
    try {
      const response: Typeform[] = await api(`/metadata/${companyId}/typeforms`);

      dispatch({
        type: FETCH_TYPEFORMS,
        data: normalizeState('id', response),
      });
    } catch (error) {
      // handle error
    }
  };
export const fetchTimezones: StoreThunk =
  () =>
  async (dispatch, getState, { api }) => {
    try {
      const response: { id: number; timezone: string }[] = await api(`/metadata/timezones`);

      dispatch({
        type: FETCH_TIMEZONES,
        data: normalizeState('id', response),
      });
    } catch (error) {
      // handle error
    }
  };
export const fetchWorkCategories: StoreThunk =
  () =>
  async (dispatch, getState, { api }) => {
    try {
      const response: { id: number; category: string }[] = await api(`/metadata/work-categories`);

      dispatch({
        type: FETCH_WORK_CATEGORIES,
        data: normalizeState('id', response),
      });
    } catch (error) {
      // handle error
    }
  };

export const fetchCompanyInvoiceTypes: StoreThunk =
  () =>
  async (dispatch, getState, { api }) => {
    try {
      const response: { id: number; category: string }[] = await api(`/metadata/company-invoice-types`);

      dispatch({
        type: FETCH_COMPANY_INVOICE_TYPES,
        data: normalizeState('id', response),
      });
    } catch (error) {
      // handle error
    }
  };
