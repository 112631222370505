import has from 'lodash/has';
import set from 'lodash/set';
import { combineReducers } from 'redux';
import { metadataReducer } from 'state/modules/metadata';
import { notificationsReducer } from 'state/modules/notifications';
import { paginationReducer } from 'state/modules/pagination';
import { toastsReducer } from 'state/modules/toasts';
import { userReducer } from 'state/modules/user';
import { StoreReducer } from 'types';

export const addReducer = (store: any) => {
  return (name: string, reducer: StoreReducer) => {
    if (!has(store.reducers, name)) {
      set(store.reducers, name, reducer);

      store.replaceReducer(setupReducers(store.reducers));
    }
  };
};

export const setupReducers = (reducers: { pagination: object } = { pagination: {} }) => {
  return combineReducers({
    pagination:
      reducers?.pagination && Object.keys(reducers.pagination).length
        ? combineReducers({
            ...reducers.pagination,
          })
        : paginationReducer,
    metadata: metadataReducer,
    notifications: notificationsReducer,
    toasts: toastsReducer,
    user: userReducer,
  });
};

export default setupReducers;
