import { useCompanySettings } from 'hooks';
import { CompanyFeaturesType } from 'types';

export const withCompanySettingFeature = (WrappedComponent: any, feature: CompanyFeaturesType) => {
  const WithCompanySettingFeature = () => {
    const companySettings = useCompanySettings();

    return <>{companySettings.features[feature] ? <WrappedComponent /> : null}</>;
  };

  return WithCompanySettingFeature;
};

export default withCompanySettingFeature;
