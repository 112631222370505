import classnames from 'classnames';
import { memo, ReactNode } from 'react';

export interface FieldTextProps {
  className?: string;
  children: ReactNode;
}

/**
 * FieldText component
 * @param {FieldTextProps} props props for the component
 */
const FieldText = ({ className, children }: FieldTextProps) => {
  const classNames = classnames('form-text', className, {
    'text-muted': !className,
  });

  return children ? <small className={classNames}>{children}</small> : null;
};

FieldText.defaultProps = {
  className: '',
};

export default memo(FieldText);
