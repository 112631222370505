import logo from 'assets/img/google/desktop/powered_by_google_on_white_hdpi.png';
import Icon from 'components/Icon';
import { debounce } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { fetchGeocode, fetchPlaces } from 'services/google';
import { GoogleLocation, GooglePlace } from 'types';

interface Props {
  onSelect: (location: any) => void;
  formatted_address?: string;
}

const LocationTypeahead: React.FC<Props> = ({ onSelect, formatted_address }) => {
  const inputRef: any = useRef(null);
  const [query, setQuery] = useState('');
  const [locations, setLocations] = useState<any>([]);
  const [displayValue, setDisplayValue] = useState('');
  const [focus, setFocus] = useState<boolean>(false);

  useEffect(() => {
    if (formatted_address) setDisplayValue(formatted_address);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    const fetchLocations = debounce(async () => {
      const locations: any = await fetchPlaces(query);
      setLocations(locations ? locations : []);
    }, 500);

    if (query) {
      fetchLocations();
    }
  }, [query]);

  const handleChange = async (place: GooglePlace) => {
    if (place === null) {
      onSelect({});
    } else {
      const geocode: GoogleLocation = await fetchGeocode(place.description);
      const location = {
        ...geocode,
        geometry: {
          ...geocode.geometry,
          location: {
            ...geocode.geometry.location,
            lat: geocode.geometry.location.lat(),
            lng: geocode.geometry.location.lng(),
          },
        },
      };
      setDisplayValue(place.description);
      setLocations([]);
      onSelect({ ...location });
    }
  };

  const handlerFocus = () => {
    setFocus(true);
  };

  const handlerBlur = () => {
    setFocus(false);
  };

  return (
    <div>
      <div className="form-control" style={{ width: '100%', height: 'fit-content', padding: '0px' }}>
        <div
          tabIndex={0}
          className="InputContainer"
          onFocus={() => handlerFocus()}
          onBlur={() => handlerBlur()}
          style={{
            border: focus ? '1px solid #238CE7' : '1px solid white',
            boxShadow: focus ? '0 0 0 2pt #4a769d66' : 'unset',
          }}
        >
          <div className="InputContainerInner">
            <input
              ref={inputRef}
              className="InputInput"
              type="text"
              value={displayValue}
              data-testid="locationInputContainer"
              onChange={(event) => {
                setDisplayValue(event.target.value);
                setQuery(event.target.value);
              }}
              onFocus={(event) => {
                setDisplayValue('');
                setQuery('');
              }}
              onBlur={(event) => {
                setTimeout(() => {
                  setLocations([]);
                }, 500);
              }}
            />
          </div>
          <div className="Flex">
            <div
              className="InputIcon1"
              onClick={() => {
                inputRef.current?.focus();
                onSelect({});
              }}
              data-testid="locationInput"
            >
              <Icon name={'times'} />
            </div>
            <div className="InputIcon2" onClick={() => inputRef.current?.focus()}>
              <Icon name={'angle-down'} />
            </div>
          </div>
        </div>
      </div>
      {locations.length > 0 && (
        <div className="MultiSelectBody">
          {locations.map((location: any) => (
            <li
              style={{ listStyleType: 'none', margin: '5px' }}
              key={location.description}
              onClick={() => {
                handleChange(location);
                setQuery('');
              }}
            >
              {location.description}
            </li>
          ))}
          <div className="d-flex">
            <img
              className="ml-auto"
              style={{ height: '18px', marginBottom: '5px', marginRight: '5px' }}
              alt="Powered by Google"
              src={logo}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default LocationTypeahead;
