import api from 'services/api';
import { CompanyBranding } from 'types';

export const fetchCompanyBranding = async (slug: string): Promise<CompanyBranding | undefined> => {
  const response = await api(`/companies/${slug}/settings/public`);

  if (response.error || (response.status && response.status === 'error')) return undefined;

  return response;
};
