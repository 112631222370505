import immer from 'immer';
import { StoreReducer, ToastsState } from 'types';

import { actionTypes } from './actions';

export * from './actions';
export * from './selectors';

export const initialState: ToastsState = [];

export const toastsReducer: StoreReducer<ToastsState> = immer((draft, action) => {
  const next: ToastsState = draft;

  switch (action.type) {
    case actionTypes.SHOW_TOAST:
      next.push(action.data.toast);
      break;
    case actionTypes.REMOVE_TOAST:
      next.splice(action.data.index, 1);
      break;
    default:
      break;
  }

  return next;
}, initialState);
