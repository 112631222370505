import { memo, ReactNode, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { getCompanyId, getPermissions, getUserId } from 'state/modules/user';
import { PermissionsOverride } from 'types';

export interface PermissionsProps {
  userId?: number | null;
  companyId?: number | null;
  feature: string;
  action?: string | string[] | null;
  override?: (props: PermissionsOverride) => boolean;
  children: (hasPermission: boolean) => ReactNode;
}

/**
 * Permissions component
 * @param {PermissionsProps} props for the component
 */
const Permissions = ({ userId, companyId, feature, action, children, override }: PermissionsProps) => {
  let hasPermission = false;
  const userIdFromState: number = useSelector(getUserId);
  const companyIdFromState: number = useSelector(getCompanyId);
  const permissions = useSelector(getPermissions);
  const getPermission = useCallback<(feature: string, action: string | string[]) => boolean>(
    (feature: string, action: string | string[]) => {
      if (typeof action === 'string') {
        return permissions[feature] && permissions[feature][action] && permissions[feature][action] === true;
      } else if (typeof action === 'object') {
        // If action is an string array of permissions, if any of those permissions is true, then we grant access
        for (let permission of action) {
          if (permissions[feature] && permissions[feature][permission] && permissions[feature][permission] === true) {
            return true;
          }
        }
        return false;
      } else return false;
    },
    [permissions]
  );

  // This method is used in the changeOwner scenario, to compare the profile owner ID to the logged in user ID as well as to the master user IDs 1 and 5
  const handleCheckUserIdAndMasterId = (userId: number | null | undefined, userIdFromState: number) => {
    const systemUsers: number[] = [1, 5];
    if (userId && userId === userIdFromState) {
      return true;
    }
    if (userId && systemUsers.includes(userId)) {
      return true;
    }
    return false;
  };

  if (companyId && companyId === companyIdFromState) {
    hasPermission = getPermission(feature, `${action}Company`);

    if (!hasPermission && userId && userId === userIdFromState) {
      hasPermission = getPermission(feature, `${action}Own`);
    }
  }

  if (!hasPermission && userId && userId === userIdFromState) {
    hasPermission = getPermission(feature, `${action}Own`);
  }

  if (!hasPermission && action) {
    hasPermission = getPermission(feature, action);
  }

  if (override && typeof override === 'function' && typeof action === 'string') {
    hasPermission = override({
      userId,
      userIdFromState,
      companyId,
      companyIdFromState,
      feature,
      action,
      permissions,
      handleCheckUserIdAndMasterId,
    });
  }

  // console.log('permissions - %s:%s - %s', feature, action, hasPermission); // eslint-disable-line

  return <>{children(hasPermission)}</>;
};

Permissions.defaultProps = {
  userId: null,
  companyId: null,
  action: null,
  override: undefined,
};

export default memo(Permissions);
