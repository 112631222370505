import axios from 'axios';
import * as config from 'config';
import { stringify } from 'qs';
import authenticate from 'services/auth';

// eslint-disable-next-line
export default async (options: any = {}, url?: string): Promise<any> => {
  try {
    const auth = await authenticate();

    if (!options.headers) {
      options.headers = {
        accept: 'application/json',
        'content-type': 'application/json',
      };
    }

    if (auth.token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${auth.token}`,
      };
    }

    if (auth.key) {
      options.headers = {
        ...options.headers,
        'x-api-key': auth.key,
      };
    }

    const response = await axios({
      baseURL: config.eventsApi.url,
      url,
      ...options,
      paramsSerializer: (params: any) => {
        return stringify(params, {
          arrayFormat: 'repeat',
        });
      },
    });
    return response.data;
    // @ts-ignore
  } catch ({ response }) {
    // @ts-ignore
    return response.data;
  }
};
