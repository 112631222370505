import * as config from 'config';
import head from 'lodash/head';
import { useCallback, useEffect, useState } from 'react';
import { JobType } from 'types';

type Hook = {
  jobType: number | string | null;
  jobTypeId: number | null;
  isContingent: boolean;
  isContingentCustom: boolean;
  isFullTime: boolean;
  isFullTimeCustom: boolean;
  isPipeline: boolean;
  isShortlist: boolean;
  getJobTypeById: (id: number) => JobType;
  setJobTypeById: (id: number) => void;
};

const useJobType = (id: number): Hook => {
  const [jobTypes] = useState<JobType[]>(config.jobTypes);

  const [jobType, setJobType] = useState<JobType>({
    jobTypeId: null,
    jobType: null,
  });

  const [isContingent, setIsContingent] = useState<boolean>(false);
  const [isContingentCustom, setisContingentCustom] = useState<boolean>(false);
  const [isFullTime, setIsFullTime] = useState<boolean>(false);
  const [isFullTimeCustom, setIsFullTimeCustom] = useState<boolean>(false);
  const [isPipeline, setIsPipeline] = useState<boolean>(false);
  const [isShortlist, setIsShortlist] = useState<boolean>(false);

  const getJobTypeById = useCallback(
    (id: number) => {
      let currentJobType: JobType = { jobTypeId: null, jobType: null };
      const newJobType = head(jobTypes.filter(({ jobTypeId }) => jobTypeId === id));

      if (newJobType) {
        currentJobType = newJobType;
      }

      return currentJobType;
    },
    [jobTypes]
  );

  const setJobTypeById = useCallback(
    (id: number) => {
      let currentJobType: JobType = { jobTypeId: null, jobType: null };
      const newJobType = head(jobTypes.filter(({ jobTypeId }) => jobTypeId === id));

      if (newJobType) {
        currentJobType = newJobType;
      }

      setJobType(currentJobType);
    },
    [jobTypes]
  );

  useEffect(() => {
    if (id) {
      setJobType(getJobTypeById(id));
    } else {
      setJobType({ jobType: null, jobTypeId: null });
    }
  }, [getJobTypeById, id, jobTypes]);

  useEffect(() => {
    setIsContingent(jobType?.jobType === 'Contingent');
    setisContingentCustom(jobType?.jobType === 'Contingent-Custom');
    setIsFullTime(jobType?.jobType === 'Full-Time');
    setIsFullTimeCustom(jobType?.jobType === 'Full-Time-Custom');
    setIsPipeline(jobType?.jobType === 'Pipeline');
    setIsShortlist(jobType?.jobType === 'Shortlist');
  }, [jobType]);

  return {
    ...jobType, // jobType, jobTypeId
    isContingent,
    isContingentCustom,
    isFullTime,
    isFullTimeCustom,
    isPipeline,
    isShortlist,
    getJobTypeById,
    setJobTypeById,
  };
};

export default useJobType;
