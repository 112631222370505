import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import * as toastsState from 'state/modules/toasts';

type Hook = {
  copyToClipboard: (textToCopy: string, textToDisplay: string) => void;
};

const useCopyToClipboard = (): Hook => {
  const dispatch = useDispatch();
  const copyToClipboard = useCallback(
    (textToCopy: string, textToDisplay: string) => {
      const cleanCopyText = textToCopy.replace(/<\/?[^>]+(>|$)/g, '');
      navigator.clipboard.writeText(cleanCopyText);
      dispatch(
        toastsState.displayToast('success', {
          title: 'Copy to Clipboard',
          message: `${textToDisplay} was successfully copied.`,
        })
      );
    },
    [dispatch]
  );

  return {
    copyToClipboard,
  };
};

export default useCopyToClipboard;
