import { useSelector } from 'react-redux';
import { getErrorFetchingUser, getTimezone, getUser, isFetchingUser, isSuccessFetchingUser } from 'state/modules/user';

const useUser = () => {
  const user = useSelector(getUser);

  return user;
};

export const useUserTimezone = () => {
  const timezone = useSelector(getTimezone);

  return timezone;
};

export const useIsFetchingUser = () => useSelector(isFetchingUser);

export const useErrorFetchingUser = () => useSelector(getErrorFetchingUser);

export const useIsSuccessFetchingUser = () => useSelector(isSuccessFetchingUser);

export default useUser;
