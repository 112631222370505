import { createSelector } from 'reselect';
import { StoreState } from 'types';

export const getPagination = (state: StoreState) => state.pagination;

export const getPaginationSection = (section: string) =>
  createSelector([getPagination], (pagination) => pagination[section]);

export const getPaginationParams = (section: string) =>
  createSelector([getPaginationSection(section)], (pagination) => pagination?.params ?? null);

export const getPaginationQueryParam = (section: string) =>
  createSelector([getPaginationParams(section)], (params) => params.query);

export const getPaginationSortParam = (section: string) =>
  createSelector([getPaginationParams(section)], (params) => params.sort);

export const getPaginationOrderParam = (section: string) =>
  createSelector([getPaginationParams(section)], (params) => params.order);

export const getPaginationPageParam = (section: string) =>
  createSelector([getPaginationParams(section)], (params) => params.page);

export const getPaginationPerPageParam = (section: string) =>
  createSelector([getPaginationParams(section)], (params) => params.perPage);

export const getPaginationSorting = (section: string) =>
  createSelector([getPaginationSection(section)], (pagination) => pagination?.sorting ?? null);
