import classnames from 'classnames';
import HashLink from 'components/HashLink';
import startCase from 'lodash/startCase';
import { useDispatch } from 'react-redux';
import { Col, Row, Toast as ToastAlert, ToastBody, ToastHeader } from 'reactstrap';
import { removeToast } from 'state/modules/toasts';
import { Toast as ToastType } from 'types';

export interface ToastProps {
  toastIndex: number;
  toast: ToastType;
}

const Toast = ({ toastIndex, toast }: ToastProps) => {
  const dispatch = useDispatch();
  const headerClassNames = classnames('text-white', {
    'bg-secondary': toast.isSuccess,
    'bg-quinary': toast.isWarning,
    'bg-danger': toast.isError,
  });
  const buttonClassNames = classnames('ml-2', {
    'text-secondary': toast.isSuccess,
    'text-quinary': toast.isWarning,
    'text-danger': toast.isError,
  });

  const handleToggle = (index: number) => {
    dispatch(removeToast(index));
  };

  const handleTimeout = () => {
    handleToggle(toastIndex);
  };

  setTimeout(handleTimeout, toast.timeInMilliseconds || 5000);

  return (
    <ToastAlert data-testid="toast" fade>
      <ToastHeader className={headerClassNames} toggle={() => handleToggle(toastIndex)}>
        {toast.title}
      </ToastHeader>
      <ToastBody>
        {toast.url ? (
          <p className="mb-0">
            {toast.message}
            <HashLink className={buttonClassNames} to={toast.url} data-testid="Toast_Hlink">
              View {startCase(toast.type)}
            </HashLink>
          </p>
        ) : (
          <Row>
            <Col xs="12">
              <p className="mb-0">{toast.message}</p>
            </Col>
          </Row>
        )}
      </ToastBody>
    </ToastAlert>
  );
};

export default Toast;
