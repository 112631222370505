import logo from 'assets/img/logo.png';
import classnames from 'classnames';
import { memo } from 'react';

export interface LogoProps {
  className?: string;
}

/**
 * Logo component
 * @param {LogoProps} props props for the component
 */
const Logo = ({ className }: LogoProps) => {
  const classNames = classnames('img-fluid', className);

  return <img className={classNames} src={logo} alt="Quantum Work" />;
};

Logo.defaultProps = {
  className: '',
};

export default memo(Logo);
