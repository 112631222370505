import { useEffect, useState } from 'react';

type Hook = {
  profileTopSkills: string[];
};

const skillsToExclude = [
  '',
  'General',
  'General Skills and Activities',
  'Attitude',
  'Entry Level',
  'Standards',
  'Visual',
  'Skilled',
  'Removal',
  'Basic',
  'One on One',
  'Not Enough Data',
  'Direct',
  'Other',
  'Miscellaneous',
];

const compareArrays = (topSkills: string) => {
  const topSkillsArray = topSkills.split(', ');
  const sanitizedTopSkills: string[] = [];

  // eslint-disable-next-line
  topSkillsArray.map((skill) => {
    if (!skillsToExclude.map((s) => s.toLowerCase()).includes(skill.toLowerCase())) {
      sanitizedTopSkills.push(skill);
    }
  });

  return sanitizedTopSkills;
};

const useProfileTopSkills = (topSkills: string): Hook => {
  const [profileTopSkills, setProfileTopSkills] = useState<string[]>([]);

  useEffect(() => {
    if (topSkills) {
      setProfileTopSkills(compareArrays(topSkills));
    }
  }, [topSkills]);

  return { profileTopSkills };
};
export default useProfileTopSkills;
