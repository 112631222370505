import immer from 'immer';
import get from 'lodash/get';
import { Action } from 'redux';
import { StoreActionHandlers, StoreActionTypes } from 'types';

export const createActionTypes = (module: string, actions: string[] = []): StoreActionTypes => {
  const actionTypes: StoreActionTypes = {};

  if (Array.isArray(actions)) {
    actions.forEach((action: string) => {
      actionTypes[action] = `${module}/${action}`;
      actionTypes[`${action}_SUCCESS`] = `${module}/${action}_SUCCESS`;
      actionTypes[`${action}_ERROR`] = `${module}/${action}_ERROR`;
    });
  }

  return actionTypes;
};

export const createReducer = <T>(initialState: T, actionHandlers: StoreActionHandlers) => {
  return (state = initialState, action: Action) => {
    return immer(state, (draftState) => {
      let nextState = draftState;

      if (actionHandlers[action.type]) {
        nextState = actionHandlers[action.type](nextState, action);
      }

      return nextState;
    });
  };
};

export const normalizeState = (path: string, array: any[]): any => {
  return {
    ids: array.map((item) => item[path]),
    byId: normalize(path, array),
  };
};

export const normalize = (path: string, array: any[]): any => {
  let object = {};

  if (Array.isArray(array) && array.length) {
    object = array.reduce((accumulator, current) => {
      const key = get(current, path);
      accumulator[key] = current;
      return accumulator;
    }, {});
  }

  return object;
};

export const denormalizeState = (state: any) => {
  return state.ids.map((id: number) => state.byId[id]);
};

export const denormalize = <T>(object: object): T[] => {
  const array: T[] = Object.values(object);

  return array;
};
