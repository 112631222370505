import { useState } from 'react';

type Hook = {
  files: any;
  getBase64: (file: any, dataType?: String) => void;
  setFiles: (file: any) => void;
};

const useBase64 = (): Hook => {
  const [files, setFiles] = useState<any>([]);
  const getBase64 = async (file: any, dataType?: String) => {
    const name = file.name;
    try {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const result = reader.result as string;
        const rawBase64 = result?.slice(result.indexOf(',') + 1);

        const contentTypeStart = result.indexOf(':') + 1;
        const contentTypeEnd = result.indexOf(';');
        const contentType = result.slice(contentTypeStart, contentTypeEnd);

        setFiles([...files, { name: name, base64String: rawBase64, contentType, dataType }]);
      };
      reader.onerror = (error) => {
        console.error(error);
      };
    } catch (error) {
      console.error(error);
    }
  };

  return { files, setFiles, getBase64 };
};

export default useBase64;
