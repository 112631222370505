import * as eventsApi from 'api/events';
import { useEffect, useState } from 'react';

type Hook = {
  users: { id: number; firstName: string; lastName: string }[];
  relatedJobs: { id: number; name: string }[];
  activities: string[];
};

const activities = [
  'Job Created',
  'Job Viewed',
  'Job Deleted',
  'Job Status Changed',
  'Profile Created',
  'Profile Viewed',
  'Profile Deleted',
  'Job Profile Stage Changed',
  'Profile Added to Job',
  'Profile Showed Interest in Job',
  'User Created',
  'User Deactivated',
];

const useEventsFilters = (isNotificationsFilter: boolean = false): Hook => {
  const [users, setUsers] = useState<{ id: number; firstName: string; lastName: string }[]>([]);
  const [relatedJobs, setRelatedJobs] = useState<{ id: number; name: string }[]>([]);

  useEffect(() => {
    async function fetchUsersAndRelatedJobs() {
      const users = await eventsApi.fetchUsersForEvents();
      const relatedJobs = await eventsApi.fetchRelatedJobsForEvents();

      setUsers(users);
      setRelatedJobs(relatedJobs);
    }

    fetchUsersAndRelatedJobs();
  }, []);

  return {
    users,
    relatedJobs,
    activities,
  };
};

export default useEventsFilters;
