const CollapseTitle = ({ title, dirty }) => {
  return (
    <>
      {title}&nbsp;
      {dirty && (
        <span style={{ fontSize: '12px', fontStyle: 'italic', fontWeight: 'normal', lineHeight: '2' }}>
          (Unsaved changes)
        </span>
      )}
    </>
  );
};

export default CollapseTitle;
