import immer from 'immer';
import { combineReducers } from 'redux';
import { getPaginationParams, getPaginationSorting, UPDATE_PAGINATION } from 'state/modules/pagination';
import { PaginationParams, PaginationSort, StoreReducer, StoreThunk } from 'types';

export const createPaginationActions = (section: string) => {
  const updatePagination: StoreThunk = (newParams: Partial<PaginationParams>) => async (dispatch, getState) => {
    try {
      const state = getState();
      const currentParams = getPaginationParams(section)(state);
      const currentSorting = getPaginationSorting(section)(state);

      dispatch({
        type: `pagination/${section}/${UPDATE_PAGINATION}`,
        data: {
          params: {
            ...currentParams,
            ...newParams,
          },
          sorting: currentSorting,
        },
      });
    } catch (error) {
      // handle error
    }
  };

  return {
    updatePagination,
  };
};

export const createPaginationReducer = (section: string, initialState: any = {}) => {
  const { params, sorting } = initialState;

  const paramsReducer: StoreReducer<PaginationParams> = immer((draft, action) => {
    const { type, data } = action;
    let next = draft;

    switch (type) {
      case `pagination/${section}/${UPDATE_PAGINATION}`:
        next = {
          ...next,
          ...data.params,
        };
        break;
    }

    return next;
  }, params);

  const sortingReducer: StoreReducer<PaginationSort[]> = immer((draft, action) => {
    const { type, data } = action;
    let next = draft;

    switch (type) {
      case `pagination/${section}/${UPDATE_PAGINATION}`:
        next = data.sorting;
        break;
    }

    return next;
  }, sorting);

  const paginationReducer = combineReducers({
    params: paramsReducer,
    sorting: sortingReducer,
  });

  return paginationReducer;
};

export default createPaginationReducer;
