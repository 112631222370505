import { parse } from 'qs';
import { useState } from 'react';
import { useLocation } from 'react-router';

export const usePublicToken = () => {
  const [publicToken, setPublicToken] = useState<string>();
  const [hasPublicToken, setHasPublicToken] = useState(false);
  const location = useLocation();
  const params = parse(location.search, { ignoreQueryPrefix: true });

  if (!hasPublicToken && params.t && params.t.length) {
    setPublicToken(params.t as string);
    setHasPublicToken(true);
  }

  return { publicToken, hasPublicToken };
};

export default usePublicToken;
