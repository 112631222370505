import { useMemo } from 'react';

import { useCompanySettingsEmailTemplates } from './useCompanySettings';

const useCompanyEmailTemplates = (
  categoryName:
    | 'talentCommunityThankYou'
    | 'showInterestThankYou'
    | 'legitimateInterest'
    | 'systemGenerated'
    | 'GDPR'
    | 'interviewAccepted',
  useFirst: boolean = false
) => {
  const emailTemplates = useCompanySettingsEmailTemplates();

  const templates = useMemo(() => {
    return {
      categoryId: emailTemplates.companyEmailTemplates[categoryName]?.id,
      categoryName,
      mergeFields: emailTemplates.companyEmailTemplates[categoryName]?.mergeFields,
      emailTemplate:
        useFirst && emailTemplates.companyEmailTemplates[categoryName]?.templates
          ? emailTemplates.companyEmailTemplates[categoryName]?.templates[0]
          : null,
      emailTemplates:
        useFirst && emailTemplates.companyEmailTemplates[categoryName]?.templates
          ? []
          : emailTemplates.companyEmailTemplates[categoryName]?.templates ?? [],
    };
  }, [emailTemplates, categoryName, useFirst]);

  return templates;
};

export default useCompanyEmailTemplates;
