import { createSelector } from 'reselect';
import { StoreState } from 'types';

const getMetadata = (state: StoreState) => state.metadata;

export const getEducationLevelsMetadata = createSelector([getMetadata], (metadata) =>
  metadata.educationLevels.byId
    ? metadata.educationLevels.ids.map((id: number) => metadata.educationLevels.byId[id])
    : []
);

export const getEmsiSkillsMetadata = createSelector([getMetadata], (metadata) =>
  metadata.emsiSkills.byId ? metadata.emsiSkills.ids.map((id: number) => metadata.emsiSkills.byId[id]) : []
);

export const getGlobalUserMetadata = createSelector([getMetadata], (metadata) =>
  metadata.globalUsers.byId ? metadata.globalUsers.ids.map((id: number) => metadata.globalUsers.byId[id]) : []
);

export const getExperienceLevelsMetadata = createSelector([getMetadata], (metadata) =>
  metadata.experienceLevels.byId
    ? metadata.experienceLevels.ids.map((id: number) => metadata.experienceLevels.byId[id])
    : []
);

export const getLocationsMetadata = createSelector([getMetadata], (metadata) =>
  metadata.locations.byId ? metadata.locations.ids.map((id: number) => metadata.locations.byId[id]) : []
);

export const getSourcesMetadata = createSelector([getMetadata], (metadata) =>
  metadata.sources.byId ? metadata.sources.ids.map((id: number) => metadata.sources.byId[id]) : []
);

export const getCurrenciesMetadata = createSelector([getMetadata], (metadata) =>
  metadata.currencies.byId ? metadata.currencies.ids.map((id: number) => metadata.currencies.byId[id]) : []
);

export const getDeliveryCenterMetadata = createSelector([getMetadata], (metadata) =>
  metadata.deliveryCenter.byId ? metadata.deliveryCenter.ids.map((id: number) => metadata.deliveryCenter.byId[id]) : []
);

export const getCompensationTypesMetadata = createSelector([getMetadata], (metadata) =>
  metadata.compensationTypes.byId
    ? metadata.compensationTypes.ids.map((id: number) => metadata.compensationTypes.byId[id])
    : []
);

export const getCompensationClassificationsMetadata = createSelector([getMetadata], (metadata) =>
  metadata.compensationClassifications.byId
    ? metadata.compensationClassifications.ids.map((id: number) => metadata.compensationClassifications.byId[id])
    : []
);

export const getPaymentModelTypesMetadata = createSelector([getMetadata], (metadata) =>
  metadata.paymentModelTypes.byId
    ? metadata.paymentModelTypes.ids.map((id: number) => metadata.paymentModelTypes.byId[id])
    : []
);

export const getContingentRateCardsMetadata = createSelector([getMetadata], (metadata) =>
  metadata.contingentRateCards.byId
    ? metadata.contingentRateCards.ids.map((id: number) => metadata.contingentRateCards.byId[id])
    : []
);

export const getFulltimeRateCardsMetadata = createSelector([getMetadata], (metadata) =>
  metadata.fulltimeRateCards.byId
    ? metadata.fulltimeRateCards.ids.map((id: number) => metadata.fulltimeRateCards.byId[id])
    : []
);

export const getTypeformsMetadata = createSelector([getMetadata], (metadata) =>
  metadata.typeforms.byId ? metadata.typeforms.ids.map((id: number) => metadata.typeforms.byId[id]) : []
);

export const getLanguagesMetadata = createSelector([getMetadata], (metadata) => metadata.languages);

export const getTimezonesMetadata = createSelector([getMetadata], (metadata) =>
  metadata.timezones.byId ? metadata.timezones.ids.map((id: number) => metadata.timezones.byId[id]) : []
);

export const getWorkCategoriesMetadata = createSelector([getMetadata], (metadata) =>
  metadata.workCategories.byId ? metadata.workCategories.ids.map((id: number) => metadata.workCategories.byId[id]) : []
);

export const getCompanyInvoiceTypesMetadata = createSelector([getMetadata], (metadata) =>
  metadata.companyInvoiceTypes.byId
    ? metadata.companyInvoiceTypes.ids.map((id: number) => metadata.companyInvoiceTypes.byId[id])
    : []
);
