import { useUserSettings } from 'hooks';
import { useMemo } from 'react';
import { EmailTemplate } from 'types';

const useEmailTemplates = (categoryName: string, useFirst: boolean = false) => {
  const userSettings = useUserSettings();

  return useMemo(() => {
    return {
      categoryId: userSettings?.emailTemplates[categoryName]?.id ?? 0,
      categoryName,
      mergeFields: userSettings?.emailTemplates[categoryName]?.mergeFields ?? [],
      emailTemplate: useFirst ? (userSettings?.emailTemplates[categoryName]?.templates[0] as EmailTemplate) : null,
      emailTemplates: useFirst ? [] : (userSettings?.emailTemplates[categoryName]?.templates as EmailTemplate[]) ?? [],
    };
  }, [categoryName, userSettings, useFirst]);
};

export default useEmailTemplates;
