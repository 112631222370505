import { parse } from 'qs';
import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router';

const BOOLEAN_OPERATORS = ['and', 'or', 'not'];

const filterBooleanOperators = (words) => words.filter((word) => !BOOLEAN_OPERATORS.includes(word.toLowerCase()));
console.log('filterBooleanOperators', filterBooleanOperators);

const parseSkills = (skillsString) =>
  skillsString
    .split(',')
    .map((skill) => skill.trim())
    .filter(Boolean);

const parseKeywords = (keywordsString) => {
  const phrases = (keywordsString.match(/"(.*?)"/g) || []).map((keyword) => keyword.replace(/"/g, ''));
  const words = keywordsString
    .replace(/".*?"|"/g, '')
    .split(' ')
    .map((keyword) => keyword.replace(/,/, ''))
    .filter(Boolean);
  return [...phrases, ...words];
};

const useHighlighterWords = (): string[] => {
  const location = useLocation();
  const [wordsToHighlight, setWordsToHighlight] = useState<string[]>([]);
  const { keywords, skills } = useMemo(() => {
    const params = parse(location.search, { ignoreQueryPrefix: true });
    const parsedKeywords = (params?.keywords as string) ?? '';
    const parsedSkills = (params?.skills as string) ?? '';

    return { keywords: parsedKeywords, skills: parsedSkills };
  }, [location]);

  useEffect(() => {
    const wordsFromKeywords = filterBooleanOperators(parseKeywords(keywords));
    const wordsFromSkills = filterBooleanOperators(parseSkills(skills));

    setWordsToHighlight([...wordsFromKeywords, ...wordsFromSkills]);
  }, [keywords, skills]);

  return wordsToHighlight;
};

export default useHighlighterWords;
