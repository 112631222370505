import { GoogleLocation, GooglePlace } from 'types';

// @ts-ignore
// eslint-disable-next-line
var sessionToken = google && google.maps ? new google.maps.places.AutocompleteSessionToken() : undefined;

export const fetchGeocode = async (address: string): Promise<GoogleLocation> => {
  return new Promise((resolve) => {
    // @ts-ignore
    // eslint-disable-next-line
    const service = new google.maps.Geocoder();
    service.geocode({ address }, (results: GoogleLocation[]) => {
      resolve(results[0]);
    });
  });
};

//lat and lng defined as any as google location type is invalid and is levarge in other places
export const fetchAlternativeFormValues = async (lat: any, lng: any): Promise<any> => {
  return new Promise((resolve) => {
    // @ts-ignore
    // eslint-disable-next-line
    const service = new google.maps.Geocoder();
    // @ts-ignore
    const latlng = new google.maps.LatLng(lat, lng);
    service.geocode({ latLng: latlng }, function (results: any, status: any) {
      if (status === 'OK') {
        resolve(results[0]);
      } else {
        resolve(null);
      }
    });
  });
};

export const fetchPlaces = async (input: string): Promise<GooglePlace[]> => {
  return new Promise((resolve) => {
    // @ts-ignore
    // eslint-disable-next-line
    const service = new google.maps.places.AutocompleteService();
    service.getQueryPredictions({ input, sessionToken }, (predictions: GooglePlace[]) => {
      resolve(predictions);
    });
  });
};
