import { SecureRoute } from '@okta/okta-react';
import { Route, RouteProps } from 'react-router';

export interface AppRouteProps extends RouteProps {
  secure?: boolean;
}

const AppRoute = ({ secure, ...routeProps }: AppRouteProps) => {
  const Component = secure ? SecureRoute : Route;

  return <Component {...routeProps} />;
};

AppRoute.defaultProps = {
  secure: false,
};

export default AppRoute;
