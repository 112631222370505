import 'assets/scss/app.scss';
import 'react-toastify/dist/ReactToastify.css';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'services/fonts';

import { Security } from '@okta/okta-react';
import { LicenseManager } from 'ag-grid-enterprise';
import App from 'components/App/App';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { IntlProvider } from 'react-intl';
import { Provider as ReduxProvider } from 'react-redux';
import { Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { authService } from 'services/auth';
import history from 'services/history';
import * as intl from 'services/intl';
import store from 'state';

import * as serviceWorker from './serviceWorker';

const agGridLicense = process.env.REACT_APP_AG_GRID_LICENSE_KEY;
if (agGridLicense) {
  LicenseManager.setLicenseKey(agGridLicense);
}

ReactDOM.render(
  <Router history={history}>
    <IntlProvider locale={intl.locale} defaultLocale={intl.locale} messages={intl.messages[intl.locale]}>
      <ReduxProvider store={store}>
        <HelmetProvider>
          <Security authService={authService}>
            <App />
          </Security>
        </HelmetProvider>
      </ReduxProvider>
    </IntlProvider>
    <ToastContainer />
  </Router>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
