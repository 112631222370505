import Icon from 'components/Icon';
import { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import * as dateService from 'services/date';

export interface AppFooterProps {}

/**
 * Footer component
 * @param {AppFooterProps} props props for the component
 */
const AppFooter = () => {
  const date = dateService.convertDate(undefined);
  const year = dateService.formatDate(date, 'YYYY');

  return (
    <footer className="bg-primary text-white w-100 p-2 mt-auto app-footer">
      <Container>
        <Row className="d-flex flex-column flex-lg-row justify-content-center justify-content-md-start align-items-center align-items-lg-baseline">
          <Col xs="auto">
            <p className="mb-0 text-center">
              <Icon name="copyright" />
              <FormattedMessage
                id="allRightsReserved"
                defaultMessage="{year} Acumen® Source, an Allegis Group company. All rights reserved."
                values={{ year }}
              />
            </p>
          </Col>
          <Col xs="auto">
            <p className="mb-0">
              <a
                className="footer text-white"
                href="https://www.allegisgroup.com/en/privacy-notices"
                target="_blank"
                rel="noopener noreferrer"
                data-testid="appFooterOne_link"
              >
                <FormattedMessage id="privacyPolicy" defaultMessage="Privacy Policy" />
              </a>
            </p>
          </Col>
          <Col xs="auto">
            <p className="mb-0">
              <Link className="footer text-white" to="/terms" data-testid="termsAndCondtions_Link">
                <FormattedMessage id="termsAndConditions" defaultMessage="Terms & Conditions" />
              </Link>
            </p>
          </Col>
          <Col xs="auto">
            <p className="mb-0">
              <a
                className="text-white"
                href="https://ags360.force.com/ServiceRequests/s/"
                target="_blank"
                rel="noopener noreferrer"
                data-testid="appFooter_link"
              >
                <FormattedMessage id="support" defaultMessage="Support" />
              </a>
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default memo(AppFooter);
