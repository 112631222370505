import './DragAndDropTable.scss';

import Icon from 'components/Icon';
import { useEffect, useState } from 'react';
import { Button } from 'reactstrap';

interface Props {
  moreLabel?: string;
  showDelete?: boolean;
  isReadOnly?: boolean;
  rows: Array<any>;
  columns: Array<any>;
  setRowsOrder?: (arrayInOrder: any) => void;
  onEditRowClick?: (object: any, index: number) => void;
  onSubmitButton?: (arrayInOrder: any) => void;
  isQuickViewModal?: boolean;
}

const DragAndDropTable = ({
  rows,
  setRowsOrder,
  onSubmitButton,
  onEditRowClick,
  columns,
  moreLabel,
  showDelete,
  isReadOnly,
  isQuickViewModal,
}: Props) => {
  const [dragItemPosition, setDragItemPosition] = useState(0);
  const [dragOverItemPosition, setDragOverItemPosition] = useState(0);
  const [showNumber, setShowNumber] = useState(10);
  const [list, setList] = useState(rows);

  const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  const dragStart = (e: any, position: any) => {
    setDragItemPosition(position);
  };

  const dragEnter = (e: any, position: any) => {
    setDragOverItemPosition(position);
  };

  useEffect(() => {
    setList(rows);
  }, [rows]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setRowsOrder && setRowsOrder(list);
  }, [list]); // eslint-disable-line react-hooks/exhaustive-deps

  const drop = (e: any) => {
    const copyListItems = [...list];
    const dragItemContent = copyListItems[dragItemPosition];
    copyListItems.splice(dragItemPosition, 1);
    copyListItems.splice(dragOverItemPosition, 0, dragItemContent);
    setDragItemPosition(0);
    setDragOverItemPosition(0);
    setList(copyListItems);
  };

  const deleteIndex = (index: number) => {
    const copyListItems = [...list];
    setList(copyListItems.filter((element, indexE) => index !== indexE));
  };

  const editIndex = (index: number) => {
    onEditRowClick && onEditRowClick(list[index], index);
  };

  return (
    <div id="DragAndDropTable">
      <div id="DragAndDropTableColumnTItleContainer">
        {!isQuickViewModal ? (
          <>
            <div id="DragAndDropTableEllipsisIconSpacer"></div>
          </>
        ) : (
          <>
            <div style={{ marginRight: '14px' }}></div>
          </>
        )}
        {columns.map((element: Array<any>) => (
          <div className="DragAndDropTableColumnTItle" style={{ width: element[1].whide, minWidth: element[1].whide }}>
            {element[1].title}
          </div>
        ))}
      </div>
      {list &&
        list.map(
          (item: any, index: number) =>
            index < showNumber && (
              <div
                onDragOver={(e) => e.preventDefault()}
                onDragEnter={(e) => dragEnter(e, index)}
                className="DragAndDropTableRow"
              >
                <div
                  className="DragAndDropTableDragButton"
                  onDragStart={(e) => dragStart(e, index)}
                  onDragEnter={(e) => dragEnter(e, index)}
                  onDragEnd={drop}
                  key={index}
                  draggable
                >
                  {!isReadOnly && !isQuickViewModal && (
                    <div className="DragAndDropTableEllipsisIcon">
                      <Icon name="ellipsis-vertical" />
                      <Icon name="ellipsis-vertical" />
                      <Icon name="ellipsis-vertical" />
                      <Icon name="ellipsis-vertical" />
                    </div>
                  )}
                </div>
                {columns.map((element: Array<any>) => (
                  <div
                    key={`${element[0]} ${item[element[0]]}`}
                    className="DragAndDropTableRowElement"
                    style={{ width: element[1].whide, minWidth: element[1].whide }}
                  >
                    {element[1].type === 'date'
                      ? item[element[0]]
                        ? `${monthNames[new Date(item[element[0]]).getMonth()]} ${new Date(
                            item[element[0]]
                          ).getFullYear()}`
                        : ''
                      : element[1].type === 'object'
                      ? item[element[0]]
                        ? item[element[0]].join(', ')
                        : ''
                      : item[element[0]] || ''}
                  </div>
                ))}
                <div className="DragAndDropTableIconsContainer">
                  {onEditRowClick && (
                    <div className="DragAndDropTableEditIcon" onClick={() => editIndex(index)}>
                      <Icon name="edit" />
                    </div>
                  )}
                  {showDelete && (
                    <div className="DragAndDropTableTimesIcon" onClick={() => deleteIndex(index)}>
                      <Icon name="times" />
                    </div>
                  )}
                </div>
              </div>
            )
        )}
      <div id="DragAndDropTableButtonsBottomDiv">
        {showNumber < list.length ? (
          <button
            id="DragAndDropTableDragBottomButton"
            onClick={() => setShowNumber(showNumber + 10)}
            data-testid="drangAnddrop_btn"
          >
            {moreLabel ? moreLabel : 'Show next 10 rows'}
          </button>
        ) : (
          <div />
        )}
        {onSubmitButton ? (
          <Button style={{ width: '100px' }} onClick={() => onSubmitButton(list)} data-testid="dragAnddropSubmit_btn">
            Submit
          </Button>
        ) : (
          <div />
        )}
      </div>
    </div>
  );
};

export default DragAndDropTable;
