import './Table.scss';

import Icon from 'components/Icon';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { useEffect } from 'react';
import * as dropdownR from 'reactstrap';

interface Props {
  rowsArray: Array<any>;
  columnsArray: Array<any>;
  hideCheckBox?: boolean;
  showOptions?: boolean;
  iSortDescending?: boolean;
  tableHeight?: string;
  showElementsPerPage?: number;
  optionHandler?: (label: string, data: any) => void;
}

const Table = forwardRef(
  (
    {
      rowsArray,
      columnsArray,
      hideCheckBox = false,
      showOptions = false,
      iSortDescending = true,
      showElementsPerPage = 10,
      tableHeight = '525px',
      optionHandler,
    }: Props,
    Ref
  ) => {
    const [selectedColumnFilterIndex, setSelectedColumnFilterIndex] = useState<number>(0);
    const [sortDescending, setSortDescending] = useState<boolean>(true);
    const [originalData, setOriginalData] = useState<Array<any>>([]);
    const [forceUpdate, setForceUpdate] = useState<boolean>(true);
    const [pagination, setPagination] = useState<number>(showElementsPerPage);
    const [pages, setPages] = useState<number>(showElementsPerPage);
    const [actualPage, setActualPage] = useState<number>(1);
    const [firstElement, setFirstElement] = useState<number>(0);
    const [lastElement, setlastElement] = useState<number>(showElementsPerPage);

    useImperativeHandle(Ref, () => ({
      setForceUpdateFunction,
      sortArrayFunction,
    }));

    useEffect(() => {
      setSortDescending(iSortDescending);
      setOriginalData([...rowsArray]);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      setOriginalData([...rowsArray]);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rowsArray]);

    useEffect(() => {
      setPages(Math.floor((originalData.length - 1) / pagination));
      setActualPage(0);
      setFirstElement(pagination > 0 ? actualPage * pagination + 1 : 0);
      setlastElement(
        originalData.length >= (actualPage + 1) * pagination ? (actualPage + 1) * pagination : originalData.length
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [originalData]);

    const setSelectedRowIndexFuntion = (index: number) => {
      originalData[index]['isSelectedOnTable'] = !originalData[index]['isSelectedOnTable'];
      setOriginalData([...originalData]);
    };

    const setForceUpdateFunction = () => {
      setForceUpdate(!forceUpdate);
    };

    useEffect(() => {
      sortDescending ? sortArrayFunction() : setSortDescending(true);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedColumnFilterIndex]);

    useEffect(() => {
      sortArrayFunction();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sortDescending, selectedColumnFilterIndex]);

    const sortArrayFunction = () => {
      if (originalData && originalData.length > 0 && columnsArray) {
        setOriginalData(
          originalData.sort((a, b) => {
            let AValueFirstRow =
              columnsArray[0].type === 'Number'
                ? parseInt(a[columnsArray[0].valueLabel])
                : a[columnsArray[0].valueLabel];
            let BValueFirstRow =
              columnsArray[0].type === 'Number'
                ? parseInt(b[columnsArray[0].valueLabel])
                : b[columnsArray[0].valueLabel];
            if (selectedColumnFilterIndex !== 0) {
              let AValueSelectedRow =
                columnsArray[selectedColumnFilterIndex].type === 'Number'
                  ? parseInt(a[columnsArray[selectedColumnFilterIndex].valueLabel])
                  : a[columnsArray[selectedColumnFilterIndex].valueLabel];
              let BValueSelectedRow =
                columnsArray[selectedColumnFilterIndex].type === 'Number'
                  ? parseInt(b[columnsArray[selectedColumnFilterIndex].valueLabel])
                  : b[columnsArray[selectedColumnFilterIndex].valueLabel];
              if (sortDescending) {
                return AValueSelectedRow === BValueSelectedRow
                  ? AValueFirstRow === BValueFirstRow
                    ? 0
                    : AValueFirstRow > BValueFirstRow
                    ? 1
                    : -1
                  : AValueSelectedRow > BValueSelectedRow
                  ? 1
                  : -1;
              } else {
                return AValueSelectedRow === BValueSelectedRow
                  ? AValueFirstRow === BValueFirstRow
                    ? 0
                    : AValueFirstRow > BValueFirstRow
                    ? 1
                    : -1
                  : AValueSelectedRow > BValueSelectedRow
                  ? -1
                  : 1;
              }
            } else {
              if (sortDescending) {
                return AValueFirstRow === BValueFirstRow ? 0 : AValueFirstRow > BValueFirstRow ? 1 : -1;
              } else {
                return AValueFirstRow === BValueFirstRow ? 0 : AValueFirstRow > BValueFirstRow ? -1 : 1;
              }
            }
          })
        );
        setForceUpdateFunction();
      }
    };

    const formatDay = (date: Date | string) => {
      if (date) {
        date = new Date(date);
        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();

        return month < 10 ? `0${month}/${day}/${year}` : `${month}/${day}/${year}`;
      }
      return '';
    };

    useEffect(() => {
      setPages(Math.floor((originalData.length - 1) / pagination));
      setActualPage(0);
      setFirstElement(pagination > 0 ? actualPage * pagination + 1 : 0);
      setlastElement(
        originalData.length >= (actualPage + 1) * pagination ? (actualPage + 1) * pagination : originalData.length
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pagination]);

    useEffect(() => {
      setFirstElement(pagination > 0 ? actualPage * pagination + 1 : 0);
      setlastElement(
        originalData.length >= (actualPage + 1) * pagination ? (actualPage + 1) * pagination : originalData.length
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [actualPage]);

    return (
      <div className="tableDiv" style={{ height: tableHeight }}>
        <div className="TableContainer">
          <div className="Titles">
            <div className={!hideCheckBox ? 'FirstColumn' : 'FirstColumnHide'}></div>
            <div className={showOptions ? 'FirstColumn' : 'FirstColumnHide'}></div>
            {columnsArray.length > 0 &&
              columnsArray.map((column: any, i: any) => (
                <div
                  className="TitleColumns"
                  style={{ width: 100 >= column.widthPX ? '100px' : column.widthPX + 'px' }}
                >
                  <span className="TextLabelRowTitle">{column.Label}</span>
                  {column.Filtrable && (
                    <div className="divIconsTitle">
                      {i !== selectedColumnFilterIndex ? (
                        <button
                          className="buttonTable"
                          onClick={() => setSelectedColumnFilterIndex(i)}
                          data-testid="cilFilterIdx_btn"
                        >
                          <Icon name={'bullseye'} className="iconClass" />
                        </button>
                      ) : sortDescending ? (
                        <button
                          className="buttonTable"
                          onClick={() => setSortDescending(false)}
                          data-testid="sortDescending_btn"
                        >
                          <Icon name={'angle-down'} className="iconClass" />
                        </button>
                      ) : (
                        <button
                          className="buttonTable"
                          onClick={() => setSortDescending(true)}
                          data-testid="sortDescendingOne_btn"
                        >
                          <Icon name={'angle-up'} className="iconClass" />
                        </button>
                      )}
                    </div>
                  )}
                </div>
              ))}
          </div>
          <div className="TableRowContainer">
            {originalData.length > 0 &&
              originalData.map((row, e) =>
                e >= firstElement - 1 && lastElement - 1 >= e ? (
                  <div className="GenericRow">
                    <div className={!hideCheckBox ? 'FirstColumn' : 'FirstColumnHide'}>
                      {!hideCheckBox && (
                        <label className="containerTable">
                          <input
                            type="checkbox"
                            onClick={() => setSelectedRowIndexFuntion(e)}
                            defaultChecked={row.isSelectedOnTable ?? false}
                            checked={row.isSelectedOnTable ?? false}
                            data-testid="tableCheckBoxOne"
                          />
                          <span className="checkmark"></span>
                        </label>
                      )}
                    </div>
                    <div className={showOptions ? 'FirstColumn' : 'FirstColumnHide'}>
                      {showOptions && originalData[e].TABLEOPTIONS && (
                        <dropdownR.Dropdown
                          toggle={() => {
                            originalData[e].isActive = !originalData[e].isActive;
                            setForceUpdateFunction();
                          }}
                          isOpen={originalData[e].isActive}
                        >
                          <dropdownR.DropdownToggle color={'link'}>
                            <Icon name="ellipsis-h" />
                          </dropdownR.DropdownToggle>
                          <dropdownR.DropdownMenu>
                            {originalData[e].TABLEOPTIONS.map((option: any, x: number) => {
                              return (
                                <dropdownR.DropdownItem
                                  onClick={() => optionHandler && optionHandler(option.Label, originalData[e])}
                                >
                                  <Icon name={option.Icon} />
                                  <span>{option.Label}</span>
                                </dropdownR.DropdownItem>
                              );
                            })}
                          </dropdownR.DropdownMenu>
                        </dropdownR.Dropdown>
                      )}
                    </div>
                    {columnsArray.map((column, i) => {
                      return (
                        <div
                          className="GenericColumn"
                          style={{ width: 100 >= column.widthPX ? '100px' : column.widthPX + 'px' }}
                        >
                          <label className="TextLabelRow">
                            {column.type !== 'Array'
                              ? column.type !== 'Date'
                                ? row[column.valueLabel]
                                : formatDay(row[column.valueLabel])
                              : row[column.valueLabel].length}
                          </label>
                        </div>
                      );
                    })}
                  </div>
                ) : null
              )}
          </div>
        </div>
        <div className="PaginationDiv">
          <label>
            Showing{' '}
            <input
              className="PaginationNumber"
              onChange={(e) =>
                (parseInt(e.target.value) < 100 || !e.target.value) && setPagination(parseInt(e.target.value))
              }
              type="number"
              value={pagination}
              data-testid="tablePagination"
            />{' '}
            Elements
          </label>
          <div style={{ marginLeft: '10px' }}>
            <button
              className="buttonTable"
              onClick={() => actualPage >= 1 && setActualPage(0)}
              data-testid="actualpage_btn"
            >
              <Icon
                name={'angle-double-left'}
                className={actualPage >= 1 ? 'PaginationIconActive' : 'PaginationIconInActive'}
              />
            </button>
            <button
              className="buttonTable"
              onClick={() => actualPage >= 1 && setActualPage(actualPage - 1)}
              data-testid="actualpageOne_btn"
            >
              <Icon
                name={'angle-left'}
                className={actualPage >= 1 ? 'PaginationIconActive' : 'PaginationIconInActive'}
              />
            </button>
            <label>
              {firstElement}-{lastElement}/{originalData.length}
            </label>
            <button
              className="buttonTable"
              data-testid="actualpageTwo_btn"
              onClick={() => pages > actualPage && pages !== 0 && setActualPage(actualPage + 1)}
            >
              <Icon
                name={'angle-right'}
                className={pages > actualPage && pages !== 0 ? 'PaginationIconActive' : 'PaginationIconInActive'}
              />
            </button>
            <button
              className="buttonTable"
              onClick={() => pages > actualPage && pages !== 0 && setActualPage(pages)}
              data-testid="actualpageThree_btn"
            >
              <Icon
                name={'angle-double-right'}
                className={pages > actualPage && pages !== 0 ? 'PaginationIconActive' : 'PaginationIconInActive'}
              />
            </button>
          </div>
        </div>
      </div>
    );
  }
);

export default Table;
