import { useSelector } from 'react-redux';
import { getUserSettings } from 'state/modules/user';
import { User } from 'types';

const useUserSettings = (): User['settings'] => {
  const settings = useSelector(getUserSettings);
  // @ts-ignore
  return settings;
};

export default useUserSettings;
