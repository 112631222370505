import moment from 'moment-timezone';
import { useState } from 'react';

type Hook = {
  currentCountry: string;
  currentTimezone: string;
  timezones: string[];
};

const useTimezone = (): Hook => {
  const [currentTimezone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone);
  const [currentCountry] = useState(currentTimezone && currentTimezone.split('/')[0]);
  const [timezones] = useState(
    moment.tz.names().sort((a, b) => {
      if (currentTimezone && currentTimezone === a) {
        return -1;
      }
      if (currentCountry && a.includes(currentCountry) && !b.includes(currentCountry)) {
        return -1;
      }
      return 0;
    })
  );

  return {
    currentCountry,
    currentTimezone,
    timezones,
  };
};

export default useTimezone;
