import { LinkEligibleJob } from 'types/job';

export const createJobLabel = (job: LinkEligibleJob['job']) => {
  let label = job.name;

  if (job.externalId) label += ` (${job.externalId})`;
  if (job.location && job.location.city) label += ` - ${job.location.city}`;
  if (job.location && job.location.stateAbbreviation) label += `, ${job.location.stateAbbreviation}`;

  return label;
};
