import api from 'services/api';
import { ReportProperties } from 'types';
// eslint-disable-next-line
import Worker from 'worker-loader!./reports.worker';

export const fetchReports = async () => {
  const response: ReportProperties[] = await api('/reports/user');

  return response;
};

export const fetchReportByReportId = async (reportId: number) => {
  const report: ReportProperties = await api(`/reports/${reportId}`);
  return report;
};

export async function fetchReportFromS3(s3Url: string) {
  if (window.Worker) {
    return new Promise((resolve, reject) => {
      const myWorker = new Worker();
      const response: any[] = [];

      myWorker.postMessage(s3Url);
      myWorker.onerror = function (error) {
        reject(error);
      };
      myWorker.onmessage = function ({ data }) {
        if (data.ended) {
          resolve(response);
          myWorker.terminate();
          return;
        }

        response.push(...data.items);
      };
    });
  } else {
    console.error('Browser does not support worker');
    return [];
  }
}

// This is a simple 'save' where overwrite the current custom report with new state. It does NOT create a new report
export const saveCustomReport = async (
  reportId: number,
  body: { agGridColumnState: any; agGridFilterState: any; flagIsPivotMode: boolean }
) => {
  const { agGridColumnState, agGridFilterState, flagIsPivotMode: isPivotMode } = body;

  const response = await api(`/reports/${reportId}/save`, {
    method: 'PUT',
    data: {
      agGridColumnState,
      agGridFilterState,
      isPivotMode,
    },
  });

  return response;
};

// This is essentially a 'save as' where we create a new custom report with its own custom state
export const createCustomReport = async (customReportProperties: ReportProperties) => {
  const {
    agGridColumnState,
    agGridFilterState,
    reportName,
    flagIsPivotMode,
    reportDescription,
    companyReportDatasetId: companyDatasetId,
  } = customReportProperties;

  // For custom created reports, they are always created as non-public
  const isPublic = false;

  const response: { createdReport: ReportProperties; latestUserReportOptions: ReportProperties[] } = await api(
    '/reports/create',
    {
      method: 'POST',
      data: {
        agGridColumnState,
        agGridFilterState,
        isPivotMode: flagIsPivotMode,
        reportName,
        reportDescription,
        companyDatasetId,
        isPublic,
      },
    }
  );

  return response;
};

export const deleteReport = async (reportId: number) => {
  const response = await api(`/reports/${reportId}`, {
    method: 'DELETE',
  });

  return response;
};

export const editReportDetails = async (
  reportId: number,
  reportName: string,
  reportDescription: string,
  sharedWith: any[]
) => {
  const response = await api(`/reports/details/${reportId}`, {
    method: 'PUT',
    data: { reportName, reportDescription, sharedWith },
  });
  return response;
};
