import { SyntheticEvent } from 'react';
import { CustomInput } from 'reactstrap';

interface Props {
  options: Array<any>;
  displayValue: string;
  returnValue: string;
  title?: string;
  testid: string;
  id?: string;
  isDisabled?: boolean;
  defaultValueIndex?: any;
  placeholder?: string;
  onChange: (data: any) => void;
}

const Dropdown = ({
  options,
  displayValue,
  title,
  onChange,
  testid,
  returnValue,
  id,
  defaultValueIndex,
  isDisabled,
  placeholder,
}: Props) => {
  return (
    <CustomInput
      disabled={isDisabled}
      defaultValue={(options[defaultValueIndex] && options[defaultValueIndex][returnValue]) || null}
      id={id || ''}
      type="select"
      onChange={({ currentTarget }: SyntheticEvent<HTMLInputElement>) => {
        onChange(currentTarget.value);
      }}
      data-testid={testid}
    >
      <option hidden value="">
        {placeholder}
      </option>
      {title && <option value="">{title}</option>}
      {options.map((option, index) => (
        <option key={option[displayValue] + index} value={option[returnValue]}>
          {option[displayValue]}
        </option>
      ))}
    </CustomInput>
  );
};

export default Dropdown;
