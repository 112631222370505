import { Component, ReactNode } from 'react';

export interface AppErrorBoundaryProps {
  children: ReactNode;
}

export interface AppErrorBoundaryState {
  error: Error | null;
  hasError: boolean;
}

/**
 * ErrorBoundary class
 */
class AppErrorBoundary extends Component<AppErrorBoundaryProps, AppErrorBoundaryState> {
  /**
   * @type {AppErrorBoundaryState}
   */
  state: AppErrorBoundaryState = {
    error: null, // eslint-disable-line
    hasError: false,
  };

  /**
   * Method to get the derived state from the error
   * @param {any} error error
   * @returns {void} void
   */
  static getDerivedStateFromError(error: Error): AppErrorBoundaryState {
    return { error, hasError: true };
  }

  /**
   * Render method
   * @returns {ReactElement} node
   */
  render() {
    const { children } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      return <p>Something went wrong. Please try refreshing the page and trying again.</p>;
    }

    return children;
  }
}

export default AppErrorBoundary;
