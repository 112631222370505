import Icon from 'components/Icon';
import { useUtmParams } from 'hooks';
import { memo, useLayoutEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import { convertDate, formatDate } from 'services/date';

export interface CompanyFooterProps {}

/**
 * Footer component
 * @param {CompanyFooterProps} props props for the component
 */
const CompanyFooter = () => {
  const qwsrc = useUtmParams();
  const date = convertDate(undefined);
  const year = formatDate(date, 'YYYY');

  useLayoutEffect(() => {
    if (qwsrc && qwsrc === 'rx') {
      const tag = document.createElement('script');
      const body: HTMLCollectionOf<HTMLBodyElement> | null = document.getElementsByTagName('body');
      tag.async = false;
      tag.src = '//jsv3.recruitics.com/6b0b1097-388e-11ea-90c5-5db55f0b8e51.js';
      if (body) {
        body[0].appendChild(tag);
      }
    }
  }, [qwsrc]);

  return (
    <footer className="w-100 p-2 mt-auto">
      <Container>
        <Row className="d-flex flex-column flex-lg-row justify-content-center justify-content-md-start align-items-center align-items-lg-baseline">
          <Col xs="auto">
            <p className="mb-0 text-center">
              <Icon name="copyright" />
              <FormattedMessage
                id="allRightsReserved"
                defaultMessage="{year} Acumen® Source, an Allegis Group company. All rights reserved."
                values={{ year }}
              />
            </p>
          </Col>
          <Col xs="auto">
            <p className="mb-0">
              <a
                className="footer-link"
                href="https://www.allegisgroup.com/en/privacy-notices"
                target="_blank"
                rel="noopener noreferrer"
                data-testid="allegisgroup_CompanyFooter_link"
              >
                <FormattedMessage id="privacyPolicy" defaultMessage="Privacy Policy" />
              </a>
            </p>
          </Col>
          <Col xs="auto">
            <p className="mb-0">
              <Link className="footer-link" to="/terms" data-testid="termsAndCondtionsFooter_Link">
                <FormattedMessage id="termsAndConditions" defaultMessage="Terms & Conditions" />
              </Link>
            </p>
          </Col>
          <Col xs="auto">
            <p className="mb-0">
              <a
                className="footer-link"
                href="https://ags360.force.com/ServiceRequests/s/"
                target="_blank"
                rel="noopener noreferrer"
                data-testid="ags360_companyFooter_link"
              >
                <FormattedMessage id="support" defaultMessage="Support" />
              </a>
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default memo(CompanyFooter);
