import memoize from 'lodash/memoize';
import { decodeHash, encodeHash } from 'services/hashIds';

type HashId = [number, string];

type HashIdHook = {
  encodeHash: (id: number) => HashId;
  decodeHash: (hash: string) => HashId;
};

const useHashId = (): HashIdHook => {
  return {
    encodeHash: memoize((id: number): HashId => {
      return [id as number, encodeHash(id)];
    }),
    decodeHash: memoize((hash: string): HashId => {
      return [decodeHash(hash)[0] as number, hash];
    }),
  };
};

export default useHashId;
