import HashIds from 'hashids';

const hids = new HashIds('QUANTUM', 10, 'abcdefghijklmnopqrstuvwxyz0123456789');

export const encodeHash = (id: number): string => {
  return hids.encode(id);
};

export const decodeHash = (hash: string): (number | bigint)[] => {
  return hids.decode(hash);
};

export default hids;
