interface Props {
  currentTab: string;
  tabs: Array<string>;
  setCurrentTab: (newActiveTab: string) => void;
}

const GenericTabs = ({ tabs, currentTab, setCurrentTab }: Props) => {
  return (
    <ul className="nav nav-tabs" data-testid="genericTab_ul">
      {tabs.map((tab) => {
        const classVar = tab.toLowerCase() === currentTab.toLowerCase() ? 'nav-link active' : 'nav-link';
        return (
          <li className="nav-item" key={`${tab}`} onClick={() => setCurrentTab(tab)} data-testid="genericTab_li">
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a key={`${tab}`} className={classVar}>
              {tab}
            </a>
          </li>
        );
      })}
    </ul>
  );
};

export default GenericTabs;
