import { useHashId } from 'hooks';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';

export interface HashLinkProps {
  className?: string;
  to: string;
  title?: string;
  children?: ReactNode;
  onClick?: () => void;
}

const HashLink = ({ to, title, children, ...props }: HashLinkProps) => {
  const { encodeHash } = useHashId();
  const regex = /(?:^.+\/)(\d{1,})(?:\/.+)?/;
  const matches = to.match(regex);
  let url = to;

  if (matches && matches[1]) {
    const match = parseInt(matches[1], 10);
    const [, hash] = encodeHash(match);

    url = to.replace(matches[1], hash || '');
  }

  return (
    <Link to={url} {...props} title={title}>
      {children}
    </Link>
  );
};

HashLink.defaultProps = {
  className: '',
};

export default HashLink;
