import './MultiSelect.scss';

import Icon from 'components/Icon';
import { useRef, useState } from 'react';
import { useEffect } from 'react';

interface Props {
  options: Array<any>;
  defaultValues?: Array<any>;
  allowOnlyOne?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  valueToDisplay: string;
  onChange: (data: any, fullData: any) => void;
}

const MultiSelect = ({ options, onChange, valueToDisplay, defaultValues, allowOnlyOne, readOnly, disabled }: Props) => {
  const inputRef: any = useRef(null);
  const [focus, setFocus] = useState<boolean>(false);
  const [flag, setFlag] = useState<boolean>(true);
  const [openDropDawn, setOpenDropDawn] = useState<boolean>(true);
  const [textValue, setTextValue] = useState<string>('');
  const [currentOptions, setCurrentOptions] = useState<any>([...options]);
  const [currentValues, setCurrentValues] = useState<Array<any>>(
    defaultValues ? (Array.isArray(defaultValues) ? defaultValues : [defaultValues]) : []
  );

  const handleKeyPress = (text: string) => {
    setTextValue(text);
    const allOptionsC = options.filter(
      (object) => !currentValues.filter((value) => JSON.stringify(value) === JSON.stringify(object)).length
    );
    setCurrentOptions(
      text
        ? allOptionsC.filter((option: any) =>
            option[valueToDisplay].toString().toLowerCase().includes(text.toLowerCase())
          )
        : allOptionsC
    );
    setOpenDropDawn(true);
  };

  const handleBackSpace = (e: any) => {
    if (e.key === 'Backspace' && textValue === '') {
      setCurrentValues(currentValues.length > 0 ? [...currentValues.slice(0, currentValues.length - 1)] : []);
    }
  };

  const addClick = (object: any) => {
    if (currentValues.filter((value) => JSON.stringify(value) === JSON.stringify(object)).length === 0) {
      setCurrentValues(currentValues.length > 0 && !allowOnlyOne ? [...currentValues, object] : [object]);
      setTextValue('');
      setOpenDropDawn(false);
    }
  };

  useEffect(() => {
    if (disabled) {
      setCurrentValues([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabled]);

  useEffect(() => {
    if (defaultValues) {
      setCurrentOptions(
        currentOptions.filter((value: any) => {
          return JSON.stringify(value) !== JSON.stringify(defaultValues);
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    onChange(
      currentValues,
      options.filter((option: any) => currentValues.includes(option[valueToDisplay].toString()))
    );
    setCurrentOptions(
      options.filter(
        (object) => !currentValues.filter((value) => JSON.stringify(value) === JSON.stringify(object)).length
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentValues]);

  const removeClick = (object: any) => {
    setCurrentValues(currentValues.filter((value) => JSON.stringify(value) !== JSON.stringify(object)));
    setOpenDropDawn(false);
  };

  const handlerFocus = (object: any) => {
    if (flag) {
      setCurrentValues(currentValues.filter((value) => JSON.stringify(value) !== JSON.stringify(object)));
      setFlag(false);
    }
    setFocus(true);
    handlerBlurF = () => {};
  };
  const handlerBlur = (object: any) => {
    handlerBlurF = () => {
      setTextValue('');
      setFocus(false);
      setOpenDropDawn(true);
    };
    setTimeout(() => {
      handlerBlurF();
    }, 200);
  };

  const openFullDropDown = () => {
    if (openDropDawn) {
      setTextValue('');
      setCurrentOptions(
        options.filter(
          (object) => !currentValues.filter((value) => JSON.stringify(value) === JSON.stringify(object)).length
        )
      );
    }
    setOpenDropDawn(!openDropDawn);
  };

  let handlerBlurF = () => {
    setTextValue('');
    setFocus(false);
    setOpenDropDawn(true);
  };

  const clickClear = () => {
    inputRef.current?.focus();
    setCurrentValues([]);
  };

  return (
    <div style={{ width: '100%', position: 'relative' }}>
      <div
        tabIndex={0}
        onFocus={() => handlerFocus({})}
        onBlur={() => handlerBlur({})}
        className="InputContainer"
        style={{
          border: focus ? (readOnly ? '1px solid hsl(0,0%,80%)' : '1px solid #238CE7') : '1px solid hsl(0,0%,80%)',
          boxShadow: focus ? (readOnly ? 'unset' : '0 0 0 2pt #4a769d66') : 'unset',
        }}
      >
        <div className="InputContainerInner">
          {currentValues.map((value) => (
            <div className="Flex" key={value[valueToDisplay]}>
              <p
                className="Tag"
                style={
                  readOnly
                    ? {
                        paddingRight: '10px',
                        borderBottomRightRadius: '5px',
                        borderTopRightRadius: '5px',
                        marginRight: '5px',
                      }
                    : {}
                }
              >
                {value[valueToDisplay]}
              </p>
              {!readOnly && (
                <div onClick={() => removeClick(value)} className="TagIcon">
                  <Icon name={'times'} />
                </div>
              )}
            </div>
          ))}
          <input
            type="text"
            value={textValue}
            onChange={(e) => handleKeyPress(e.target.value)}
            onKeyDown={(e) => handleBackSpace(e)}
            className="InputInput"
            ref={inputRef}
            disabled={readOnly}
            data-testid="MultiSelectInput"
          />
        </div>
        {!readOnly && (
          <div className="Flex">
            <div className="InputIcon1" onClick={() => clickClear()}>
              <Icon name={'times'} />
            </div>
            <div className="InputIcon2" onClick={() => openFullDropDown()}>
              <Icon name={'angle-down'} />
            </div>
          </div>
        )}
      </div>
      {focus && openDropDawn && !readOnly ? (
        currentOptions.length ? (
          <div className="MultiSelectBody" tabIndex={0} onFocus={() => handlerFocus({})} onBlur={() => handlerBlur({})}>
            <ul data-testid="multiSelectCurrentOptions_ul">
              {currentOptions.map((option: any) => (
                <li
                  key={option['id'] ? option['id'] : option[valueToDisplay]}
                  onClick={() => addClick(option)}
                  data-testid="multiSelectCurrentOptions_li"
                >
                  {option[valueToDisplay]}
                </li>
              ))}
            </ul>
          </div>
        ) : (
          <div className="MultiSelectNoOptions">No options</div>
        )
      ) : null}
    </div>
  );
};

export default MultiSelect;
