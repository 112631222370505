import { AddProfileAttachmentModalProps } from 'components/AddProfileAttachmentModal';
import api from 'services/api';
import eventsApi from 'services/eventsApi';
import {
  ApiError,
  JobProfileConsent,
  JobProfileFeedback,
  JobWorkResult,
  LinkEligibleJob,
  PaginatedResults,
  PaginationParams,
  Profile,
  ProfileEducation,
  ProfileExperience,
  ProfileHtmlResume,
  ProfileLanguage,
  ProfileMessage,
  ProfileMessageSend,
  ProfileNote,
  ProfilePersonal,
  ProfileRightToRepresent,
  ProfileSkillResponse,
  ProfileTag,
} from 'types';
import ProfileAttachmentResponse from 'types/profile';

export const fetchProfiles = async (params: PaginationParams): Promise<PaginatedResults<Profile> | ApiError> => {
  const response = await api('/profiles', { params });

  return response;
};

export const fetchRecentProfiles = async (): Promise<PaginatedResults<Profile> | ApiError> => {
  const response = await api('/profiles/recent');

  return response;
};

export const fetchProfile = async (id: number): Promise<Profile | ApiError> => {
  const response: Profile = await api(`/profiles/${id}`);

  return response;
};

export const fetchImportedConnectedProfile = async (externalId: string): Promise<Profile | ApiError> => {
  const response: Profile = await api(`/profiles/get-imported-connected-profile/${externalId}`);

  return response;
};

export const fetchExistingConnectedProfile = async (email: string): Promise<Profile | ApiError> => {
  const response: Profile = await api(`/profiles/get-existing-connected-profile/${email}`);

  return response;
};

export const fetchProfileFeedback = async (id: number): Promise<JobProfileFeedback | ApiError> => {
  const response: JobProfileFeedback = await api(`/profiles/${id}/feedback`);

  return response;
};

export const fetchProfileConsent = async (id: number): Promise<JobProfileConsent | ApiError> => {
  const response: JobProfileConsent = await api(`/profiles/${id}/consent`);

  return response;
};

export const createProfile = async (profile: Profile): Promise<Profile | ApiError> => {
  const response: Profile = await api('/profiles', {
    method: 'POST',
    data: profile,
  });

  return response;
};

export const updateProfile = async (id: number, profile: Profile): Promise<Profile | ApiError> => {
  const response: Profile = await api(`/profiles/${id}`, {
    method: 'PUT',
    data: profile,
  });
  return response;
};

export const updateProfileEducation = async (id: number, education: ProfileEducation): Promise<Profile | ApiError> => {
  const response: Profile = await api(`/profiles/${id}/education`, {
    method: 'PUT',
    data: education,
  });

  return response;
};

export const updateProfileLanguage = async (id: number, language: ProfileLanguage): Promise<Profile | ApiError> => {
  const response: Profile = await api(`/profiles/${id}/languages`, {
    method: 'PUT',
    data: language,
  });

  return response;
};

export const updateProfilePersonal = async (id: number, personal: ProfilePersonal): Promise<Profile | ApiError> => {
  const response: Profile = await api(`/profiles/${id}/personal`, {
    method: 'PUT',
    data: personal,
  });
  return response;
};

export const updateProfileExperience = async (
  id: number,
  experience: ProfileExperience
): Promise<Profile | ApiError> => {
  const response: Profile = await api(`/profiles/${id}/experience`, {
    method: 'PUT',
    data: experience,
  });

  return response;
};

export const deleteProfile = async (id: number): Promise<{ message: string } | ApiError> => {
  const response = await api(`/profiles/${id}`, {
    method: 'DELETE',
  });

  return response;
};

export const importProfile = async (id: number): Promise<Profile | ApiError> => {
  const response: Profile = await api(`/profiles/${id}/copy`);

  return response;
};

export const updateProfileFromResume = async (id: number, resume: any): Promise<ProfileAttachmentResponse | any> => {
  const data = new FormData();

  data.append('file', resume);

  const response = await api(`/profiles/${id}/resume`, {
    method: 'PUT',
    data,
    headers: {
      accept: 'application/json',
    },
  });

  return response;
};

export const fetchProfileNotes = async (id: number, params: object = {}): Promise<PaginatedResults<ProfileNote>> => {
  const response = await api(`/profiles/${id}/get-notes`, { params });

  return response;
};

export const fetchProfileActivities = async (param: { profile_ids: number; company_id: number }) => {
  const response = await eventsApi(
    {
      method: 'GET',
      params: param,
    },
    '/events/search'
  );

  return response;
};

export const addNoteToProfile = async (id: number, notes: ProfileNote): Promise<PaginatedResults<ProfileNote>> => {
  const response = await api(`/profiles/${id}/create-note`, {
    method: 'POST',
    data: notes,
  });

  return response;
};

export const fetchProfileMessages = async (
  id: number,
  params: object = {}
): Promise<PaginatedResults<ProfileMessage>> => {
  const response = await api(`/profiles/${id}/communications`, { params });

  return response;
};

export const fetchProfileWork = async (id: number, params: object = {}): Promise<PaginatedResults<JobWorkResult>> => {
  const response = await api(`/profiles/${id}/search-jobs`, { params });

  return response;
};

export const fetchAllProfileWork = async (id: number): Promise<Array<any>> => {
  const response = await api(`/profiles/${id}/all-jobs`);

  return response;
};

export const updateProfileOwner = async (profileId: number, userId: number) => {
  const response = await api(`/profiles/${profileId}/assign`, {
    method: 'POST',
    data: { userId },
  });

  return response;
};

export const uploadRtrDocument = async (file: any) => {
  const data = new FormData();

  data.append('rtrFile', file[0]);

  const response = await api('/files/attachments/right-to-represent', {
    method: 'POST',
    data,
    headers: {
      accept: 'application/json',
    },
  });

  return response;
};

export const uploadResume = async (file: any, type: string) => {
  const data = new FormData();

  data.append('file', file);
  data.append('type', type);

  const response = await api('/profiles/resume', {
    method: 'POST',
    data,
    headers: {
      accept: 'application/json',
    },
  });

  return response;
};

export const uploadResumes = async (
  files: any[],
  type: string,
  source: string,
  companyId: string,
  userId: string,
  jobIdToLink?: number,
  profileTagsIds?: number[]
) => {
  const errors: string[] = [];
  const bulkUploadPromises: any = files.map(async (file) => {
    const data = new FormData();
    data.append(`${file.path}`, file);

    data.append('type', type);
    data.append('source', source);
    data.append('companyId', companyId);
    data.append('userId', userId);

    if (jobIdToLink) data.append('workId', jobIdToLink.toString());
    if (profileTagsIds) data.append('profileTagsIds', JSON.stringify(profileTagsIds));

    let response: any = {};
    if (file.size > 6000000) {
      return {
        response: {
          error: 413,
          message: `Over 6mb file size limit and cannot be uploaded.`,
        },
        fileName: file.name,
      };
    } else {
      response = await api('/profiles/resume/bulk', {
        method: 'POST',
        data,
        headers: {
          accept: 'application/json',
        },
      });
    }

    const responseWithFileName = { response, fileName: file.name };
    return responseWithFileName;
  });

  const bulkUploadResponses = await Promise.all(bulkUploadPromises);
  console.log(bulkUploadResponses);
  // eslint-disable-next-line array-callback-return
  const errorsFromBulkRequest: any = bulkUploadResponses.filter((res: any) => {
    if (res.response.error) return `${res.fileName}: ${res.response.message}`;
  });
  const sqsIdsFromResponse: any = bulkUploadResponses.filter((res: any) => res.response.sqs_ids);
  console.log('full sqsIds array from responses', bulkUploadResponses);
  console.log('errors', errorsFromBulkRequest);
  console.log('sqsIds from response', sqsIdsFromResponse);

  if (errorsFromBulkRequest) {
    errorsFromBulkRequest.forEach((err: any) => errors.push(`${err.fileName}: ${err.response.message}`));
  }
  console.log('concat errors', errors);
  let sqsIdsForProgressTracker: any[] = [];
  if (sqsIdsFromResponse)
    sqsIdsFromResponse.forEach((resp: any) => sqsIdsForProgressTracker.push(resp.response.sqs_ids.toString()));
  return { sqs_ids: sqsIdsForProgressTracker, error: errors };
};

export const uploadRtrForm = async (rightToRepresent: ProfileRightToRepresent) => {
  const data = new FormData();

  const { id, rtrExpirationDate, rtrFile } = rightToRepresent;
  data.append('id', id);
  data.append('rtrExpirationDate', rtrExpirationDate);
  data.append('rtrFile', rtrFile);

  const response = await api(`/profiles/${id}/right-to-represent`, {
    method: 'POST',
    data,
    headers: {
      accept: 'application/json',
    },
  });

  return response;
};

export const sendMessageToProfile = async (id: number, message: ProfileMessageSend) => {
  const response = await api(`/profiles/${id}/send-${message.type.toLowerCase()}`, {
    method: 'POST',
    data: message,
  });

  return response;
};

export const fetchLinkEligibleJobs = async (id: number): Promise<LinkEligibleJob[]> => {
  const response = await api(`/profiles/${id}/eligible-jobs`);

  return response;
};

export const fetchLinkEligibleJobsForConnected = async (): Promise<LinkEligibleJob[]> => {
  const response = await api('/profiles/connected/get-eligible-jobs');

  return response;
};

export const updateLinkEligibleJobs = async (
  id: number,
  jobs: { jobs: LinkEligibleJob[] }
): Promise<LinkEligibleJob[]> => {
  const response = await api(`/profiles/${id}/manual-add-to-jobs`, {
    method: 'POST',
    data: jobs,
  });

  return response;
};

export const changeProfileSkillsOrder = async (id: number, orderArray: Array<number>): Promise<unknown | ApiError> => {
  const response: Profile = await api(`/profiles/${id}/orderings`, {
    method: 'PUT',
    data: orderArray,
  });

  return response;
};

export const addProfileSkills = async (
  id: number,
  newSkill: any
): Promise<{ profileSkillId: number; message: string; originalTermsFromResume: string }> => {
  const response: { profileSkillId: number; message: string; originalTermsFromResume: string } = await api(
    `/profiles/${id}/skills`,
    {
      method: 'POST',
      data: newSkill,
    }
  );

  return response;
};

export const editProfileSkills = async (id: number, newSkill: any): Promise<ProfileSkillResponse> => {
  const response: ProfileSkillResponse = await api(`/profiles/${id}/skills`, {
    method: 'PUT',
    data: newSkill,
  });

  return response;
};

// Attachments

export const fetchProfileAttachments = async (id: number): Promise<any> => {
  const response = await api(`/profiles/${id}/attachments`);

  return response;
};

export const fetchProfileAttachment = async (id: number, attachmentId: number): Promise<any> => {
  const response = await api(`/profiles/${id}/attachments/${attachmentId}`);

  return response;
};

export const uploadProfileAttachment = async (
  id: number,
  values: { fileName: string; file: File; type: string; jobId?: number }
): Promise<AddProfileAttachmentModalProps> => {
  const data = new FormData();

  data.append('fileName', values.fileName);
  data.append('file', values.file);
  data.append('type', values.type);
  if (values.jobId) data.append('jobId', String(values.jobId));

  const response = await api(`/profiles/${id}/attachments`, {
    method: 'POST',
    data,
    headers: {
      accept: 'application/json',
    },
  });

  return response;
};

// Graphs

/**
 * Consent (CASL)
 */
export const confirmConsentForCasl = async (profileId: number): Promise<any> => {
  const response = await api(`/profiles/${profileId}/compliance/casl/send-confirm-consent-email`);
  return response;
};

export const grantConsentForCasl = async (profileId: number, messageId: any) => {
  const response = await api(`/profiles/${profileId}/compliance/casl/grant-consent`, {
    method: 'POST',
    data: { messageId: messageId },
  });

  return response;
};

export const fetchHtmlResumeForProfile = async (profileId: number): Promise<ProfileHtmlResume> => {
  const response = await api(`/profiles/${profileId}/html-resume`);

  return response;
};

export const downloadHtmlResume = async (s3Url: string) => {
  return fetch(`${s3Url}`)
    .then((response) => {
      return response.text();
    })
    .catch((error) => {
      console.error(error);
    });
};

export const reparseResume = async (profileId: number): Promise<ApiError | any | Profile> => {
  const response = await api(`/profiles/${profileId}/reparse-resume`, {
    method: 'PUT',
  });

  return response;
};

export const createProfileTags = async (id: number = 0, values: any): Promise<ProfileTag> => {
  const response: ProfileTag = await api(`/profiles/${id}/tags`, {
    method: 'POST',
    data: values,
  });

  return response;
};
