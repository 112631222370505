import { matchPath, useLocation } from 'react-router';

type Hook = { isCompanyPage: boolean; companySlug: string };

const useIsCompanyPage = (): Hook => {
  const location = useLocation();
  let isCompanyPage = false;
  let companySlug = '';
  let match = matchPath<{ slug: string }>(location.pathname, {
    path: '/c/:slug',
  });

  if (!match) {
    match = matchPath<{ slug: string; hash: string }>(location.pathname, {
      path: '/c/:slug/w/:hash',
    });
  }

  if (match?.params.slug) {
    isCompanyPage = true;
    companySlug = match.params.slug;
  }

  if (location?.pathname.search(/settings/) !== -1) isCompanyPage = false;

  return { isCompanyPage, companySlug };
};

export default useIsCompanyPage;
