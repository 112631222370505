import { createActionTypes } from 'state/modules/utils';
import { StoreAction, StoreActionTypes, Toast } from 'types';

export const actionTypes: StoreActionTypes = createActionTypes('toasts', ['SHOW_TOAST', 'REMOVE_TOAST']);

export const displayToast: StoreAction = (type: 'success' | 'warning' | 'error' = 'success', toast: Toast) => ({
  type: actionTypes.SHOW_TOAST,
  data: {
    toast: {
      ...toast,
      isSuccess: type === 'success',
      isWarning: type === 'warning',
      isError: type === 'error',
    },
  },
});

export const removeToast: StoreAction = (index: number) => ({
  type: actionTypes.REMOVE_TOAST,
  data: {
    index,
  },
});
