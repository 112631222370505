import classNames from 'classnames';
import { Nav, NavItem, NavLink } from 'reactstrap';

export default function Tabs({
  activeTab,
  toggle,
  listOfTabs,
}: {
  activeTab: string;
  toggle: (tab: string) => void;
  listOfTabs: { name: string; title: string }[];
}) {
  return (
    <Nav tabs style={{ marginBottom: '10px' }}>
      {listOfTabs.map(({ name, title }) => (
        <NavItem key={name}>
          <NavLink className={classNames({ active: activeTab === name })} onClick={() => toggle(name)}>
            <span style={{ cursor: 'pointer', userSelect: 'none' }}>{title}</span>
          </NavLink>
        </NavItem>
      ))}
    </Nav>
  );
}
