import Icon from 'components/Icon';
import { MouseEvent, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { ProfileLanguage } from 'types';

interface Props {
  language: ProfileLanguage;
  editState: boolean;
  displayEdit: boolean;
  children?: any;
  onEditChange: (state: boolean) => void;
}

const InLineRow = ({ language, onEditChange, editState, displayEdit, children }: Props) => {
  const [isEditable, setIsEditable] = useState(false);

  const changeEditState = (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    onEditChange && onEditChange(!editState);
    setIsEditable(true);
  };

  return (
    <>
      <Row>
        <Col xs="12">
          {!editState && (
            <Row>
              {language.languageName && (
                <Col xs="auto">
                  <strong className="font-size-sm">Language</strong>
                  <p>{language.languageName}</p>
                </Col>
              )}
              {language.proficiencyName && (
                <Col xs="auto">
                  <strong className="font-size-sm">Proficiency Level</strong>
                  <p>{language.proficiencyName}</p>
                </Col>
              )}
              {displayEdit && (
                <div onClick={(e) => changeEditState(e)} style={{ marginLeft: '15px', cursor: 'pointer' }}>
                  <Icon name="edit" size="sm" />
                </div>
              )}
            </Row>
          )}
        </Col>
      </Row>
      {isEditable && children}
    </>
  );
};

export default InLineRow;
