import { useEffect, useState } from 'react';
import { Profile, ProfileFromSearch } from 'types';

import useCompany, { useCompanySuppliers, useCompanyType } from './useCompany';
import useUser from './useUser';

type Hook = {
  showContactIcons: boolean;
  userPhone: boolean;
};

const useShowContactIcons = (profile: Profile | ProfileFromSearch): Hook => {
  const { phone } = useUser();
  const userPhone = Boolean(phone);
  const company = useCompany();
  const companySuppliers = useCompanySuppliers();
  const { isClient } = useCompanyType();
  const [showContactIcons, setShowContactIcons] = useState<boolean>(false);

  useEffect(() => {
    setShowContactIcons(
      isClient &&
        !companySuppliers
          .filter((companySupplier) => companySupplier.supplierId !== company.id)
          .map((companySupplier) => companySupplier.supplierId)
          .includes(profile.companyId ?? 0)
    );
  }, [companySuppliers, company, isClient, profile.companyId]);

  return { showContactIcons, userPhone };
};
export default useShowContactIcons;
