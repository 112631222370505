import { CustomInput } from 'reactstrap';

interface Props {
  testid: string;
  id: string;
  classNames?: string;
  isDisabled?: boolean;
  value: boolean;
  onChange: () => void;
  useCheckedProp?: boolean;
}

const Switch = ({ onChange, testid, classNames, id, isDisabled, value, useCheckedProp = false }: Props) => {
  if (useCheckedProp) {
    return (
      <CustomInput
        id={`${id}`}
        data-testid={testid}
        classNames={classNames}
        type="switch"
        checked={value}
        onChange={(e) => onChange()}
        disabled={isDisabled}
      />
    );
  }

  return (
    <CustomInput
      id={`${id}`}
      data-testid={testid}
      classNames={classNames}
      type="switch"
      defaultChecked={value}
      onChange={(e) => onChange()}
      disabled={isDisabled}
    />
  );
};

export default Switch;
