import * as eventsApi from 'api/events';
import { useUser } from 'hooks';
import { useCallback } from 'react';

const useAPIEvents = () => {
  const user = useUser();

  const sendUserActionEvent = useCallback(
    (name: string, action: string, type: string, context?: any, object?: any, event?: any, parent?: any) => {
      const userId = user.id === 0 ? null : user.id;
      const companyId = user.companyId === 0 ? null : user.companyId;

      userId &&
        eventsApi.createEvent({
          userId,
          userName: user.firstName + user.lastName,
          companyId,
          eventName: name,
          eventType: type,
          eventAction: action,
          context,
          object,
          event,
          parent,
        });
    },
    [user]
  );

  return {
    sendUserActionEvent,
  };
};

export default useAPIEvents;
