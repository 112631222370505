import AppFooter from 'components/App/AppFooter';
import CompanyFooter from 'components/CompanyFooter';
import { useIsCompanyPage } from 'hooks';

export interface FooterProps {}

/**
 * Footer component
 * @param {FooterProps} props props for the component
 */
const Footer = () => {
  const { isCompanyPage } = useIsCompanyPage();

  return isCompanyPage ? <CompanyFooter /> : <AppFooter />;
};

export default Footer;
