import { useSelector } from 'react-redux';
import { getJobWorkflows } from 'state/modules/user';
import { JobProfileStageWorkflows } from 'types';

// type Hook = [StageWorkflows, React.Dispatch<React.SetStateAction<StageWorkflows>>];

const useJobProfileStageWorkflows = (): JobProfileStageWorkflows => {
  const stageWorkflows = useSelector(getJobWorkflows);

  return stageWorkflows;
};

export default useJobProfileStageWorkflows;
