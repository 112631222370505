import { createSelector } from 'reselect';
import { denormalizeState } from 'state/modules/utils';
import { Company, StoreState } from 'types';

const getMetadata = (state: StoreState) => state.metadata;

export const getUser = (state: StoreState) => state.user.user;

export const isFetchingUser = (state: StoreState) => state.user.isLoading;

export const isSuccessFetchingUser = (state: StoreState) => state.user.isSuccess;

export const getErrorFetchingUser = (state: StoreState) => state.user.err;

export const getUserId = createSelector([getUser], (user) => user.id);

export const getCompany = createSelector([getUser], (user) => user.company);

export const getCompanySettingRaw = createSelector([getCompany], (company) => company.settings);

export const getCompanySettings = createSelector([getCompany], (company) => {
  return {
    ...company.settings,
    profiles: {
      ...company.settings.profiles,
      dispositionReasons: denormalizeState(company.settings.profiles.dispositionReasons),
      noteTypes: denormalizeState(company.settings.profiles.noteTypes),
    },
    jobs: {
      ...company.settings.jobs,
      jobSources: denormalizeState(company.settings.jobs.jobSources),
      jobStatuses: denormalizeState(company.settings.jobs.jobStatuses),
      jobStatusChangeReasons: denormalizeState(company.settings.jobs.jobStatusChangeReasons),
      noteTypes: denormalizeState(company.settings.jobs.noteTypes),
      invoiceOptions: denormalizeState(company.settings.jobs.noteTypes),
    },
  } as Company['settings'];
});

export const getCompanyId = createSelector([getUser], (user) => user.company.id);

export const getCompanySettingsId = createSelector([getUser], (user) => user.company.id);

export const getCompanySuppliers = createSelector([getCompany], (company) =>
  company.suppliers.byId ? company.suppliers.ids.map((id) => company.suppliers.byId[id]) : []
);

export const getCompanyServiceCloudPartners = createSelector([getCompany], (company) =>
  company.serviceCloudPartners && company.serviceCloudPartners.byId
    ? company.serviceCloudPartners.ids.map((id) => company.serviceCloudPartners.byId[id])
    : []
);

export const getUserCompanies = createSelector([getUser], (user) => user.companies);

export const getCompanyUsers = createSelector([getCompany], (company) =>
  company.users && company.users.byId ? company.users.ids.map((id) => company.users.byId[id]) : []
);

export const getCreatePermissions = createSelector([getUser], (user) => {
  return {
    createInternalUser: user.company.permissions.companyUser?.createInternalUser,
    createExternalReviewer: user.company.permissions.companyUser?.createExternalReviewer,
    createHiringManager: user.company.permissions.companyUser?.createHiringManager,
  };
});

export const getReadPermissions = createSelector([getUser], (user) => {
  return {
    readInternalUser: user.company.permissions.companyUser?.readInternalUser,
    readHiringManager: user.company.permissions.companyUser?.readHiringManager,
    readExternalReviewer: user.company.permissions.companyUser?.readExternalReviewer,
  };
});

export const getInternalCompanyUsers = createSelector([getCompany], (company) => {
  const userIds = company.users.ids.filter((userId: number) => company.users.byId[userId].userRole !== 'external');
  return userIds ? userIds.map((id) => company.users.byId[id]) : [];
});

export const getPermissions = createSelector([getCompany], (company) => company.permissions ?? {});

export const getJobWorkflows = createSelector([getCompany], (company) => company.jobWorkflows);

export const getUserSettings = createSelector([getUser], (user) => user.settings ?? {});

export const getEmailTemplates = createSelector([getUserSettings], (settings: any) => settings?.emailTemplates ?? {});

export const getSchedulingURL = createSelector([getUserSettings], (settings: any) => settings?.schedulingUrl ?? '');

export const getTimezone = createSelector([getUserSettings], (settings: any) => settings?.timezone ?? '');

export const getCompanySettingsForSelectors = createSelector([getCompany], (company) => company.settings);

export const getNoteTypes = createSelector([getCompanySettingsForSelectors], (settings) =>
  settings && settings.profiles.noteTypes.byId
    ? settings.profiles.noteTypes.ids.map((id) => settings.profiles.noteTypes.byId[id])
    : []
);

export const getCompanySettingsAttachmentTypes = createSelector(
  [getCompanySettingsForSelectors],
  (settings) => settings.attachmentTypes
);

export const getCompanySettingsDetails = createSelector(
  [getCompanySettingsForSelectors],
  (settings) => settings.details
);

export const getCompanySettingsEmails = createSelector([getCompanySettingsForSelectors], (settings) => settings.emails);

export const getCompanyFeatures = createSelector([getCompanySettingsForSelectors], (settings) => settings.features);

export const getCompanyJobs = createSelector([getCompanySettingsForSelectors], (settings) => ({
  ...settings.jobs,
  jobSources: denormalizeState(settings.jobs.jobSources),
  jobStatuses: denormalizeState(settings.jobs.jobStatuses),
  jobStatusChangeReasons: denormalizeState(settings.jobs.jobStatusChangeReasons),
  noteTypes: denormalizeState(settings.jobs.noteTypes),
}));

export const getCompanyInvoices = createSelector([getCompanySettingsForSelectors], (settings) => ({
  ...settings.invoices,
}));

export const getCompanyProfiles = createSelector([getCompanySettingsForSelectors], (settings) => ({
  ...settings.profiles,
  dispositionReasons: denormalizeState(settings.profiles.dispositionReasons),
  noteTypes: denormalizeState(settings.profiles.noteTypes),
}));

export const getCompanyJobBoard = createSelector([getCompanySettingsForSelectors], (settings) => settings.jobBoard);

export const getCompanyEmailTemplates = createSelector(
  [getCompanySettingsForSelectors],
  (settings) => settings.emailTemplates
);

export const getJobNoteTypes = createSelector([getCompanySettingsForSelectors], (settings) =>
  settings && settings.jobs.noteTypes.byId
    ? settings.jobs.noteTypes.ids.map((id) => settings.jobs.noteTypes.byId[id])
    : []
);

export const getStatusChangeReasons = createSelector([getCompanySettingsForSelectors], (settings) =>
  settings && settings.jobs.jobStatusChangeReasons.byId
    ? settings.jobs.jobStatusChangeReasons.ids.map((id) => settings.jobs.jobStatusChangeReasons.byId[id])
    : []
);

export const getWorkStatuses = createSelector([getCompanySettingsForSelectors], (settings) =>
  settings && settings.jobs.jobStatuses.byId
    ? settings.jobs.jobStatuses.ids.map((id) => settings.jobs.jobStatuses.byId[id])
    : []
);

export const getDispositionReasons = createSelector([getCompanySettingsForSelectors], (settings) =>
  settings && settings.profiles.dispositionReasons.byId
    ? settings.profiles.dispositionReasons.ids.map((id) => settings.profiles.dispositionReasons.byId[id])
    : []
);
export const getJobTypes = createSelector([getUser], (user) => user.jobTypes);

export const getJobSources = createSelector([getCompanySettingsForSelectors], (settings) =>
  settings && settings.jobs.jobSources.byId
    ? settings.jobs.jobSources.ids.map((id) => settings.jobs.jobSources.byId[id])
    : []
);

export const getSourcesMetadata = createSelector([getMetadata], (metadata) =>
  metadata.sources.byId ? metadata.sources.ids.map((id: number) => metadata.sources.byId[id]) : []
);
