import { JobType } from 'types';

export const api = {
  publicKey: process.env.REACT_APP_API_PUBLIC_KEY,
  publicUrl: process.env.REACT_APP_API_PUBLIC_URL,
  secureKey: process.env.REACT_APP_API_KEY,
  secureUrl: process.env.REACT_APP_API_URL,
};

export const app = {
  gaTrackingId: process.env.REACT_APP_GA_TRACKING_ID,
  url: process.env.REACT_APP_URL,
  walkmeurl: process.env.REACT_APP_WALKME_URL,
  enableMessages: process.env.REACT_APP_ENABLE_MESSAGES === 'true',
  enableEventsApi: process.env.REACT_APP_ENABLE_EVENTS_API === 'true',
  enableNewJobSearch: process.env.REACT_APP_ENABLE_NEW_JOB_SEARCH === 'true',
  enableExternalSearch: process.env.REACT_APP_ENABLE_EXTERNAL_SEARCH === 'true',
  enableBulkResumeUploads: process.env.REACT_APP_BULK_RESUME_UPLOADS === 'true',
  enableReportingGrids: process.env.REACT_APP_ENABLE_REPORTING_GRIDS === 'true',
  enableCustomRoleCreation: process.env.REACT_APP_ENABLE_CUSTOM_ROLE_CREATION === 'true',
  enableLocalProfileBulkUpload: process.env.REACT_APP_IS_LOCAL_BULK_PROFILE_UPLOAD === 'true',
};

export const eventsApi = {
  url: process.env.REACT_APP_EVENTS_API_URL,
};

export const env = process.env.REACT_APP_ENV;

export const files = {
  url: process.env.REACT_APP_API_FILES_URL,
};

export const google = {
  places: {
    key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  },
};

export const externalSearchTokens = {
  token: process.env.REACT_APP_EXTERNAL_SEARCH_AUTOCOMPLETE_BEARER_TOKEN,
  pdlAutoComplete: process.env.REACT_APP_PDL_TYPEAHEAD_KEY,
};

export const connected = {
  importWebSocketUrl: process.env.REACT_APP_CONNECTED_IMPORT_WEB_SOCKET_URL,
  profileViewUrl: process.env.REACT_APP_CONNECTED_PROFILE_VIEW_URL,
};

export const jobTypes: JobType[] = [
  { jobTypeId: 1, jobType: 'Contingent' },
  { jobTypeId: 2, jobType: 'Full-Time' },
  { jobTypeId: 3, jobType: 'Full-Time-Custom' },
  { jobTypeId: 4, jobType: 'Pipeline' },
  { jobTypeId: 5, jobType: 'Shortlist' },
  { jobTypeId: 6, jobType: 'Contingent-Custom' },
];

export const jobTypesAlternate = {
  Contingent: 1,
  'Full-Time': 2,
  'Full-Time-Custom': 3,
  Pipeline: 4,
  Shortlist: 5,
  'Contingent-Custom': 6,
};

export const okta = {
  issuerId: process.env.REACT_APP_OKTA_ISSUER_ID,
  clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
  baseUrl: process.env.REACT_APP_OKTA_BASE_URL,
  redirectUrl: `${window.location.origin}/implicit/callback`,
  scopes: process.env.REACT_APP_OKTA_SCOPES ? process.env.REACT_APP_OKTA_SCOPES.split(' ') : [],
  responseMode: process.env.REACT_APP_OKTA_RESPONSE_MODE,
  responseType: process.env.REACT_APP_OKTA_RESPONSE_TYPE ? process.env.REACT_APP_OKTA_RESPONSE_TYPE.split(' ') : [],
  pkce: process.env.REACT_APP_OKTA_PKCE,
  prompt: process.env.REACT_APP_OKTA_PROMPT,
  timeout: 1000 * 60 * parseInt(process.env.REACT_APP_OKTA_TIMEOUT as string, 10),
};
