import * as companyApi from 'api/company';
import { memo, useEffect, useState } from 'react';
import { Container, Navbar, NavbarBrand } from 'reactstrap';
import { CompanyBranding } from 'types';

export interface CompanyHeaderProps {
  slug: string;
}

/**
 * Header component
 * @param {CompanyHeaderProps} props props for the component
 */
const CompanyHeader = ({ slug }: CompanyHeaderProps) => {
  const [branding, setBranding] = useState<CompanyBranding>();

  useEffect(() => {
    if (slug) {
      companyApi.fetchCompanyBranding(slug).then((response) => {
        setBranding(response);
      });
    }
  }, [slug]); // eslint-disable-line

  return branding ? (
    <Navbar className="navbar-expand-lg" style={{ backgroundColor: branding?.mainColor ?? '#fff' }}>
      <Container>
        <NavbarBrand tag="div">
          {branding?.logoUrl ? (
            <img
              data-testid="headerCompanyBrand"
              className="navbar-logo d-inline-block align-top py-0"
              src={branding?.logoUrl ?? ''}
              alt="Quantum Work"
            />
          ) : null}
        </NavbarBrand>
      </Container>
    </Navbar>
  ) : null;
};

export default memo(CompanyHeader);
