import { useSelector } from 'react-redux';
import {
  getCompanyEmailTemplates,
  getCompanyFeatures,
  getCompanyJobBoard,
  getCompanyJobs,
  getCompanyProfiles,
  getCompanySettings,
  getCompanySettingsAttachmentTypes,
  getCompanySettingsDetails,
  getCompanySettingsEmails,
} from 'state/modules/user';
import { Company, CompanySettingsJobsTab, CompanySettingsProfilesTab } from 'types';

export const useCompanySettingsJobsTab = (): CompanySettingsJobsTab => {
  const attachmentTypes = useSelector(getCompanySettingsAttachmentTypes);
  const jobsBoard = useSelector(getCompanyJobBoard);
  const jobs = useSelector(getCompanyJobs);
  return {
    ...jobs,
    attachmentTypes,
    jobsBoard,
  };
};

export const useCompanySettingsProfilesTab = (): CompanySettingsProfilesTab => {
  const settings = useSelector(getCompanyProfiles);
  const attachmentTypes = useSelector(getCompanySettingsAttachmentTypes);
  return {
    ...settings,
    attachmentTypes,
  };
};

export const useCompanySettingsDetails = (): Company['settings']['details'] => {
  const settings = useSelector(getCompanySettingsDetails);
  return settings;
};

export const useCompanySettingsEmails = (): Company['settings']['emails'] => {
  const settings = useSelector(getCompanySettingsEmails);
  return settings;
};

export const useCompanySettingsEmailTemplates = (): Company['settings']['emailTemplates'] => {
  const settings = useSelector(getCompanyEmailTemplates);
  return settings;
};

export const useCompanySettingsFeatures = (): Company['settings']['features'] => {
  const settings = useSelector(getCompanyFeatures);
  return settings;
};

export const useCompanySettingsJobs = (): Company['settings']['jobs'] => {
  const settings = useSelector(getCompanyJobs);
  return settings;
};

export const useCompanySettingsProfiles = (): Company['settings']['profiles'] => {
  const settings = useSelector(getCompanyProfiles);
  return settings;
};

export const useCompanySettingsJobBoard = (): Company['settings']['jobBoard'] => {
  const settings = useSelector(getCompanyJobBoard);
  return settings;
};

const useCompanySettings = (): Company['settings'] => {
  const settings = useSelector(getCompanySettings);
  return settings;
};

export default useCompanySettings;
