import Icon from 'components/Icon';
import { ReactNode, useState } from 'react';
import { Col, Collapse, Row } from 'reactstrap';

interface Props {
  title: string | ReactNode;
  startOpen?: boolean;
  keepOpen?: boolean;
  keepClose?: boolean;
  children?: any;
  chevronClass?: string;
}

const CollapseRow = ({ title, startOpen, children, chevronClass, keepClose, keepOpen }: Props) => {
  const [isActive, setIsActive] = useState(keepOpen ? true : keepClose ? false : startOpen);
  return (
    <>
      <Row>
        <Col xs="12">
          <h5 onClick={() => setIsActive(keepOpen ? true : keepClose ? false : !isActive)} style={{ display: 'flex' }}>
            {keepClose || keepOpen ? null : chevronClass ? (
              <div className={chevronClass}>
                <Icon name={isActive ? 'angle-down' : 'angle-right'} />
              </div>
            ) : (
              <Icon name={isActive ? 'angle-down' : 'angle-right'} />
            )}
            {title}
          </h5>
        </Col>
      </Row>
      <Collapse isOpen={isActive}>{children}</Collapse>
    </>
  );
};

export default CollapseRow;
