import axios from 'axios';
import { stringify } from 'qs';
import authenticate from 'services/auth';
import * as intl from 'services/intl';
import uuid from 'services/uuid';

// eslint-disable-next-line
export default async (url: string, options: any = {}): Promise<any> => {
  const originatingUrl = window.location.href;
  try {
    const auth = await authenticate();

    if (!options.headers) {
      options.headers = {
        accept: 'application/json',
        'content-type': 'application/json',
      };
    }

    if (auth.token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${auth.token}`,
      };
    }

    if (auth.key) {
      options.headers = {
        ...options.headers,
        'x-api-key': auth.key,
      };
    }

    if (auth.publicToken) {
      options.headers = {
        ...options.headers,
        'x-api-public-token': auth.publicToken,
      };
    }

    options.headers = {
      ...options.headers,
      'x-api-session-id': uuid({ key: 'sessionid' }),
      'x-api-lang': intl.locale,
      'Originating-Url': originatingUrl,
    };

    const response = await axios({
      baseURL: auth.url,
      url,
      ...options,
      paramsSerializer: (params: any) => {
        return stringify(params);
      },
    });

    return response.data;
  } catch (error: any) {
    return { ...error, error };
  }
};
