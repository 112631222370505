import { useSelector } from 'react-redux';
import {
  getCompanyInvoiceTypesMetadata,
  getCompensationClassificationsMetadata,
  getCompensationTypesMetadata,
  getCurrenciesMetadata,
  getDeliveryCenterMetadata,
  getEducationLevelsMetadata,
  getEmsiSkillsMetadata,
  getExperienceLevelsMetadata,
  getGlobalUserMetadata,
  getLanguagesMetadata,
  getLocationsMetadata,
  getPaymentModelTypesMetadata,
  getTimezonesMetadata,
  getTypeformsMetadata,
  getWorkCategoriesMetadata,
} from 'state/modules/metadata';
import {
  CompanyInvoiceTypes,
  DeliveryCenter,
  EducationLevel,
  EmsiSkill,
  ExperienceLevel,
  GlobalUsers,
  Languages,
  Location,
  ProfileCurrency,
  Typeform,
  WorkCategory,
} from 'types';

interface Hook {
  educationLevels: EducationLevel[];
  experienceLevels: ExperienceLevel[];
  emsiSkills: EmsiSkill[];
  globalUsers: GlobalUsers[];
  workCategory: WorkCategory[];
  companyInvoiceTypes: CompanyInvoiceTypes[];
  currencies: ProfileCurrency[];
  compensationTypes: { id: number; name: string }[];
  compensationClassifications: { id: number; name: string }[];
  paymentModelTypes: { id: number; name: string }[];
  locations: Location[];
  typeforms: Typeform[];
  languages: Languages;
  timezones: { id: number; timezone: string }[];
  deliveryCenters: DeliveryCenter[];
}
/**
 * Returns the app specific metadata. If looking for company related metadata, see {@link useCompanyMetadata}.
 * @returns App metadata
 * @example
 * const { educationLevels, experienceLevels, companyInvoiceTypes } = useMetadata();
 */
const useMetadata = (): Hook => {
  return {
    educationLevels: useSelector(getEducationLevelsMetadata),
    experienceLevels: useSelector(getExperienceLevelsMetadata),
    emsiSkills: useSelector(getEmsiSkillsMetadata),
    globalUsers: useSelector(getGlobalUserMetadata),
    workCategory: useSelector(getWorkCategoriesMetadata),
    companyInvoiceTypes: useSelector(getCompanyInvoiceTypesMetadata),
    currencies: useSelector(getCurrenciesMetadata),
    compensationTypes: useSelector(getCompensationTypesMetadata),
    compensationClassifications: useSelector(getCompensationClassificationsMetadata),
    paymentModelTypes: useSelector(getPaymentModelTypesMetadata),
    locations: useSelector(getLocationsMetadata),
    typeforms: useSelector(getTypeformsMetadata),
    languages: useSelector(getLanguagesMetadata),
    timezones: useSelector(getTimezonesMetadata),
    deliveryCenters: useSelector(getDeliveryCenterMetadata),
  };
};
export default useMetadata;
