import { useOktaAuth } from '@okta/okta-react';
import * as profilesApi from 'api/profiles';
import { ConfirmationModal } from 'components/commons';
import { currentJobSubject } from 'components/commons/services/subjects';
import Icon from 'components/Icon';
import Logo from 'components/Logo';
import Permissions from 'components/Permissions';
import PublicResource from 'components/PublicResource';
import * as config from 'config';
import {
  useCompany,
  useCompanyServiceCloudPartners,
  useCompanySettings,
  useIsSuccessFetchingUser,
  usePublicToken,
  useUser,
} from 'hooks';
import { memo, useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
// prettier-ignore
import { Collapse, Container, DropdownItem,DropdownMenu, DropdownToggle, Nav, Navbar, NavbarBrand, NavbarToggler, NavItem, UncontrolledDropdown } from 'reactstrap';
import { authLogout } from 'services/auth';
import { encodeHash } from 'services/hashIds';
import {
  fetchUnreadNotifications,
  getNotificationsTotalResults,
  resetNotifications,
} from 'state/modules/notifications';
import { getCompanyUsers, getSourcesMetadata, resetUser } from 'state/modules/user';
import { ServiceCloudPartners } from 'types';
import { createJobLabel } from 'utils/jobs';

export interface AppHeaderProps {}

/**
 * Header component
 * @param {AppHeaderProps} props props for the component
 */
const AppHeader = () => {
  const { authService } = useOktaAuth();
  const dispatch = useDispatch();
  const location = useLocation();
  const notificationsTotalResults = useSelector(getNotificationsTotalResults);
  const user = useUser();
  const isSuccessFetchingUser = useIsSuccessFetchingUser();
  const companyUsers = useSelector(getCompanyUsers);
  const profileSources = useSelector(getSourcesMetadata);
  profileSources.sort((a, b) => a.value.localeCompare(b.value));
  const company = useCompany();
  const companyServiceCloudPartners = useCompanyServiceCloudPartners();
  const {
    features: { actionManagementFlag },
  } = useCompanySettings();
  const [isActive, setIsActive] = useState<boolean>(false);
  const { hasPublicToken } = usePublicToken();
  const [actionModalSelectedModalOption, setActionModalSelectedModalOption] = useState<string | null>(null);
  const [actionModalModalDisabled, setActionModalModalDisabled] = useState<boolean>(false);
  const [actionModalEntriesObject, setActionModalEntriesObject] = useState<any>({});
  const [currentWorksList, setCurrentWorksList] = useState<any>([]);
  const [currentStagesList, setCurrentStagesList] = useState<any>([]);
  const [currentJob, setCurrentJob] = useState<any>(null);

  const generateEmail = 'generateEmail';
  useEffect(() => {
    // confirms user is logged in successfully before attempting to retrieve unread notifications
    if (!hasPublicToken && isSuccessFetchingUser) {
      dispatch(fetchUnreadNotifications());
    }
  }, [location.pathname, hasPublicToken, isSuccessFetchingUser]); // eslint-disable-line

  const handleNavbarClick = (): void => {
    setIsActive(!isActive);
  };

  /**
   * Not looking specifically for 'search' typeName partners because we want end users to know about
   * the external search even if they don't specifically have permission.
   * @param serviceCloudPartners
   */
  const checkIfSearchServiceCloudPartnersExist = (serviceCloudPartners: ServiceCloudPartners[]) => {
    if (serviceCloudPartners.length === 0) {
      return false;
    }

    return true;
  };

  /**
   * Specifically check for 'reporting' typeName partners in order to show the reports section in the navigation
   * @param serviceCloudPartners
   */
  const checkIfReportingServiceCloudPartnersExist = useCallback(() => {
    if (Array.isArray(companyServiceCloudPartners)) {
      if (companyServiceCloudPartners.filter((partner) => partner.typeName === 'reporting').length > 0) {
        return true;
      }

      return false;
    }

    return false;
  }, [companyServiceCloudPartners]);

  const handleLogoutClick = (): void => {
    dispatch(resetNotifications());
    dispatch(resetUser());

    (async () => {
      await authLogout(authService);
    })();
  };

  const actionModalTitleLiteral: any = {
    generateEmail: () => 'Generate Email',
  };

  const getActionModalModalTitleLiteral = () => {
    return Object.keys(actionModalTitleLiteral).includes(actionModalSelectedModalOption as string)
      ? actionModalTitleLiteral[actionModalSelectedModalOption as string]()
      : '';
  };

  const actionModalUseOnlyCloseButtonArray: Array<string> = [generateEmail];

  const getActionModalModalButtonsArray = () => {
    if (actionModalUseOnlyCloseButtonArray.includes(actionModalSelectedModalOption as string))
      return [{ label: 'Close', type: 'secondary' }];
    return [];
  };

  const handleActionModalChangeEntries = (props: any, value: any) => {
    const copy = actionModalEntriesObject;
    copy[props.name] = value;
    if ((value && typeof value === 'object' && value.attachmentTypeId === 'Select type') || value === '') {
      delete copy[props.name];
    }
    if (actionModalSelectedModalOption === generateEmail && props.name === 'work') {
      setCurrentStagesList(
        copy['work'] &&
          copy['work'][0] &&
          copy['work'][0].workflowId &&
          company.jobWorkflows[copy['work'][0].workflowId]
          ? Object.values(company.jobWorkflows[copy['work'][0].workflowId].stages)
          : []
      );
      // delete copy['stage'];
    }
    setActionModalEntriesObject({ ...copy });
  };

  const handleActionModalModalChange = (button: string) => {
    if (button === 'Close' && actionModalSelectedModalOption === generateEmail) {
      setActionModalEntriesObject({});
      setActionModalSelectedModalOption(null);
      currentJobSubject.next(null);
    }
  };

  const actionModalInputLiteral: any = {
    generateEmail: () => [
      {
        type: 'Label',
        props: {
          label: 'Fill the relevant information out based on what you want to track.',
        },
      },
      {
        type: 'Label',
        props: { label: 'User', class: 'required-field' },
      },
      {
        type: 'MultiSelect',
        props: {
          name: 'userGroup',
          displayValue: 'fullName',
          disableIfNull: true,
          allowOnlyOne: true,
          defaultValues: {
            fullName: `${user.firstName} ${user.lastName}`,
            ...companyUsers.filter((userI: { firstName: any; lastName: any }) => {
              return `${user.firstName} ${user.lastName}` === `${userI.firstName} ${userI.lastName}`;
            })[0],
          },
          options: companyUsers.map((user: { firstName: any; lastName: any }) => {
            return { fullName: `${user.firstName} ${user.lastName}`, ...user };
          }),
        },
      },
      {
        type: 'Label',
        props: { label: 'Source', class: 'required-field' },
      },
      {
        type: 'Dropdown',
        props: {
          name: 'source',
          displayValue: 'value',
          returnValue: 'id',
          placeholder: 'Select Source',
          options: [{ name: 'Select Source' }, ...profileSources],
        },
      },
      {
        type: 'Label',
        props: { label: 'Work' },
      },
      {
        type: 'MultiSelect',
        props: {
          name: 'work',
          displayValue: 'displayName',
          disableIfNull: true,
          allowOnlyOne: true,
          defaultValues: currentJob
            ? [
                {
                  ...currentJob,
                  displayName: `${currentJob.name} - ${currentJob.location ? currentJob.location.city : ''}, ${
                    currentJob.location
                      ? currentJob.location.stateAbbreviation
                        ? currentJob.location.stateAbbreviation
                        : currentJob.location.stateCode
                      : ''
                  }`,
                },
              ]
            : null,
          options: currentWorksList,
          readOnly: currentJob || currentWorksList.length === 0,
        },
      },
      {
        type: 'Label',
        props: { label: 'Stage', ...(currentStagesList.length !== 0 && { class: 'required-field' }) },
      },
      // {
      //   type: 'MultiSelect',
      //   props: {
      //     name: 'stage',
      //     displayValue: 'name',
      //     disableIfNull: true,
      //     allowOnlyOne: true,
      //     options: currentStagesList,
      //     readOnly: currentStagesList.length === 0,
      //     disabled: !actionModalEntriesObject.stage,
      //   },
      // },
      {
        type: 'MultiSelect',
        props: {
          name: 'stage',
          displayValue: 'name',
          disableIfNull: true,
          allowOnlyOne: true,
          defaultValues: [{ id: 1261, name: 'Sourced' }],
          options: [],
          readOnly: true,
        },
      },
      {
        type: 'Label',
        props: { label: 'Email' },
      },
      {
        type: 'Copy',
        props: {
          value:
            actionModalEntriesObject.userGroup &&
            actionModalEntriesObject.userGroup[0] &&
            actionModalEntriesObject.userGroup[0].id &&
            actionModalEntriesObject.source &&
            actionModalEntriesObject.source !== 'Select Source/Category'
              ? actionModalEntriesObject.work && actionModalEntriesObject.work[0] && actionModalEntriesObject.work[0].id
                ? actionModalEntriesObject.stage &&
                  actionModalEntriesObject.stage[0] &&
                  actionModalEntriesObject.stage[0].id
                  ? `u${encodeHash(actionModalEntriesObject.userGroup[0].id)}-j${encodeHash(
                      actionModalEntriesObject.work[0].id
                    )}-w${encodeHash(actionModalEntriesObject.stage[0].id)}-s${encodeHash(
                      actionModalEntriesObject.source
                    )}@${company.settings.details.inboundProfileEmailPrefix}`
                  : ''
                : `u${encodeHash(actionModalEntriesObject.userGroup[0].id)}-s${encodeHash(
                    actionModalEntriesObject.source
                  )}@${company.settings.details.inboundProfileEmailPrefix}`
              : '',
        },
      },
    ],
  };

  useEffect(() => {
    const filteredEntriesObject = { ...actionModalEntriesObject };
    delete filteredEntriesObject.Note;
    let IsInvalid = Object.values(filteredEntriesObject)
      .map((value: any) => {
        if (value && typeof value === 'object' && 'file' in value && (!value.file || value.file.size > 6000000)) {
          return false;
        }
        return value ? (Array.isArray(value) ? value.length > 0 : value !== '') : false;
      })
      .includes(false);
    setActionModalModalDisabled(IsInvalid);
  }, [actionModalEntriesObject]); // eslint-disable-line react-hooks/exhaustive-deps

  const getActiveJobs = async () => {
    profilesApi.fetchLinkEligibleJobsForConnected().then((response) => {
      setCurrentWorksList(
        response.map((job: any) => {
          return {
            ...job.job,
            displayName: createJobLabel(job.job),
          };
        })
      );
    });
  };

  useEffect(() => {
    if (actionModalSelectedModalOption === generateEmail && !currentJob) {
      getActiveJobs();
    }
    if (!actionModalSelectedModalOption) {
      currentJobSubject.next(null);
    }
  }, [actionModalSelectedModalOption]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    currentJobSubject.subscribe((job) => {
      setCurrentJob(job);
      job && setActionModalSelectedModalOption(generateEmail);
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setCurrentStagesList(
      currentJob && company.jobWorkflows[currentJob.workflowId]
        ? Object.values(company.jobWorkflows[currentJob.workflowId].stages)
        : []
    );
  }, [currentJob, company]); // eslint-disable-line react-hooks/exhaustive-deps

  const getActionModalModalInputLiteral = () => {
    return Object.keys(actionModalInputLiteral).includes(actionModalSelectedModalOption as string)
      ? actionModalInputLiteral[actionModalSelectedModalOption as string]()
      : [];
  };

  if (location.pathname.includes('/casl/confirm-consent')) {
    return null;
  }

  return (
    <Navbar className="navbar-expand-lg bg-primary" dark>
      <Container>
        <NavbarBrand tag="div">
          <Link to="/" data-testid="headerBrand">
            <Logo className="navbar-logo d-inline-block align-top py-0" />
          </Link>
        </NavbarBrand>
        {!isSuccessFetchingUser ? (
          <PublicResource>
            <Collapse className="navbar-collapse" isOpen={isActive}>
              <Nav className="d-none d-md-flex ml-auto" navbar>
                <NavItem>
                  <Link className="nav-link text-white" to="/login" data-testid="headerUserLogin">
                    <Icon name="sign-in-alt" />
                    <span>
                      <FormattedMessage id="login" defaultMessage="Login" />
                    </span>
                  </Link>
                </NavItem>
              </Nav>
              <Nav className="d-flex d-md-none align-items-end" navbar>
                <NavItem>
                  <Link className="nav-link text-white float-right" to="/login" data-testid="headerUserLoginMobile">
                    <Icon name="sign-in-alt" />
                    <FormattedMessage id="login" defaultMessage="Login" />
                  </Link>
                </NavItem>
              </Nav>
            </Collapse>
          </PublicResource>
        ) : (
          <>
            <NavbarToggler onClick={handleNavbarClick} data-testid="headerToggle" />
            <Collapse className="navbar-collapse" isOpen={isActive}>
              <Nav className="d-none d-lg-flex w-100" navbar>
                <NavItem>
                  <Link className="nav-link text-white" to="/work" data-testid="headerWork">
                    <Icon name="briefcase" />
                    <FormattedMessage id="work" defaultMessage="Work" />
                  </Link>
                </NavItem>
                <NavItem>
                  <Link className="nav-link text-white" to="/profiles" data-testid="headerProfiles">
                    <Icon name="users" />
                    <FormattedMessage id="profiles" defaultMessage="Profiles" />
                  </Link>
                </NavItem>
                {/* {config.app.enableReportingGrids && checkIfReportingServiceCloudPartnersExist() ? ( */}
                {/* ) : null} */}
                {config.app.enableEventsApi ? (
                  <NavItem className="d-sm-only-none">
                    <Link className="nav-link text-white" to="/activity" data-testid="headerActivity">
                      <Icon name="rss" />
                      <FormattedMessage id="activity" defaultMessage="Activity" />
                    </Link>
                  </NavItem>
                ) : null}
                {config.app.enableExternalSearch &&
                checkIfSearchServiceCloudPartnersExist(companyServiceCloudPartners) ? (
                  <NavItem>
                    <Link className="nav-link text-white" to="/search/external" data-testid="headerExternalSearch">
                      <Icon name="search" />
                      <FormattedMessage id="navSearch" defaultMessage="External Search" />
                    </Link>
                  </NavItem>
                ) : null}
                {config.app.enableReportingGrids && checkIfReportingServiceCloudPartnersExist() ? (
                  <NavItem className="ml-auto" nav inNavbar>
                    <Link className="nav-link text-white" to="/reports-library" data-testid="headerReportsLibrary">
                      <Icon name="file-chart-line" prefix="fas" />
                    </Link>
                  </NavItem>
                ) : null}
                {config.app.enableMessages ? (
                  <NavItem nav inNavbar>
                    <Link className="nav-link text-white" to="/messages" data-testid="headerMessages">
                      <Icon name="envelope" />
                    </Link>
                  </NavItem>
                ) : null}
                <NavItem className="" nav inNavbar>
                  <Link className="nav-link text-white" to="/notifications" data-testid="headerNotifications">
                    <Icon
                      size="sm"
                      counter={notificationsTotalResults || 0}
                      name={notificationsTotalResults && notificationsTotalResults > 0 ? 'bell' : 'bell-slash'}
                    />
                  </Link>
                </NavItem>
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle className="text-white" nav caret data-testid="headerUser">
                    <Icon name="user" />
                  </DropdownToggle>
                  <DropdownMenu right positionFixed data-testid="headerUserDropdown">
                    <NavItem className="font-size-sm mx-3">
                      <span className="font-weight-bold">User </span>
                      <span className="text-muted">{`${user.firstName} ${user.lastName}`}</span>
                    </NavItem>
                    <NavItem className="font-size-sm mx-3">
                      <span className="font-weight-bold">Role </span>
                      <span className="text-muted">{`${user.company.role} on ${user.company.name}`}</span>
                    </NavItem>
                    <DropdownItem divider />
                    {actionManagementFlag ? (
                      <>
                        <DropdownItem header>
                          <FormattedMessage id="actions" defaultMessage="Actions" />
                        </DropdownItem>
                        <DropdownItem to="/action-management" tag={Link} data-testid="headerActionManagementActions">
                          <Icon name="clipboard-list" />
                          <FormattedMessage id="management" defaultMessage="Management" />
                        </DropdownItem>
                      </>
                    ) : null}
                    {company.settings.details.inboundProfileEmailPrefix && (
                      <DropdownItem
                        style={{ outline: 'none' }}
                        onClick={() => setActionModalSelectedModalOption(generateEmail)}
                      >
                        <Icon name="paper-plane-alt" />
                        <FormattedMessage id="generateEmail" defaultMessage="Generate Email" />
                      </DropdownItem>
                    )}
                    <DropdownItem header>
                      <FormattedMessage id="settings" defaultMessage="Settings" />
                    </DropdownItem>
                    <DropdownItem to="/user/settings" tag={Link} data-testid="headerUserSettings">
                      <Icon name="user-cog" />
                      <FormattedMessage id="user" defaultMessage="User" />
                    </DropdownItem>
                    {company?.isCompanyAdmin ? (
                      <>
                        <DropdownItem to="/company/settings" tag={Link} data-testid="headerUserCompanySettings">
                          <Icon name="users-cog" />
                          <FormattedMessage id="company" defaultMessage="Company" />
                        </DropdownItem>
                      </>
                    ) : null}
                    <Permissions feature="company" action="readCompliance">
                      {(hasPermission) =>
                        hasPermission ? (
                          <DropdownItem to="/compliance/settings" tag={Link} data-testid="headerUserComplianceSettings">
                            <Icon name="shield-check" />
                            <FormattedMessage id="compliance" defaultMessage="Compliance" />
                          </DropdownItem>
                        ) : null
                      }
                    </Permissions>
                    <Permissions
                      feature="companyUser"
                      action={['readInternalUser', 'readExternalReviewer', 'readHiringManager']}
                    >
                      {(hasPermission) =>
                        hasPermission ? (
                          <DropdownItem to="/user-management" tag={Link} data-testid="headerUserManagement">
                            <Icon name="users" />
                            <FormattedMessage id="User Management" defaultMessage="User Management" />
                          </DropdownItem>
                        ) : null
                      }
                    </Permissions>
                    <DropdownItem divider />
                    <DropdownItem tag="span" onClick={handleLogoutClick} data-testid="headerUserLogout">
                      <Icon name="sign-out-alt" />
                      <FormattedMessage id="logout" defaultMessage="Logout" />
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </Nav>
              <Nav className="d-flex d-lg-none align-items-end" navbar>
                <NavItem>
                  <span className="font-size-lg text-white">User - </span>
                  <span className="text-white">{`${user.firstName} ${user.lastName}`}</span>
                </NavItem>
                <NavItem className="text-right">
                  <span className="font-size-lg text-white">Role - </span>
                  <span className="text-white">{`${user.company.role} on ${user.company.name}`}</span>
                </NavItem>
                <hr></hr>
                <NavItem>
                  <Link
                    className="nav-link text-white"
                    to="/work"
                    onClick={handleNavbarClick}
                    data-testid="headerWorkMobile"
                  >
                    <Icon name="briefcase" />
                    <FormattedMessage id="work" defaultMessage="Work" />
                  </Link>
                </NavItem>
                <NavItem>
                  <Link
                    className="nav-link text-white"
                    to="/profiles"
                    onClick={handleNavbarClick}
                    data-testid="headerProfilesMobile"
                  >
                    <Icon name="user" />
                    <FormattedMessage id="profiles" defaultMessage="Profiles" />
                  </Link>
                </NavItem>
                {/* {config.app.enableReportingGrids && checkIfReportingServiceCloudPartnersExist() ? ( */}
                {/* ) : null} */}
                {config.app.enableMessages ? (
                  <NavItem className="d-none">
                    <Link
                      className="nav-link text-white"
                      to="/messages"
                      onClick={handleNavbarClick}
                      data-testid="headerMessagesMobile"
                    >
                      <Icon name="user" />
                      <FormattedMessage id="messages" defaultMessage="Messages" />
                    </Link>
                  </NavItem>
                ) : null}
                {config.app.enableEventsApi ? (
                  <NavItem className="d-none">
                    <Link
                      className="nav-link text-white"
                      to="/activity"
                      onClick={handleNavbarClick}
                      data-testid="headerActivityMobile"
                    >
                      <Icon name="rss" />
                      <FormattedMessage id="activity" defaultMessage="Activity" />
                    </Link>
                  </NavItem>
                ) : null}
                <NavItem>
                  <Link
                    className="nav-link text-white"
                    to="/notifications"
                    onClick={handleNavbarClick}
                    data-testid="headerNotificationsMobile"
                  >
                    <Icon
                      name={notificationsTotalResults && notificationsTotalResults > 0 ? 'bell' : 'bell-slash'}
                      counter={notificationsTotalResults || 0}
                    />
                    <FormattedMessage id="notifications" defaultMessage="Notifications" />
                  </Link>
                </NavItem>
                {config.app.enableExternalSearch &&
                checkIfSearchServiceCloudPartnersExist(companyServiceCloudPartners) ? (
                  <NavItem>
                    <Link
                      className="nav-link text-white"
                      to="/search/external"
                      data-testid="headerExternalSearchMobile"
                    >
                      <Icon name="search" />
                      <FormattedMessage id="navSearch" defaultMessage="External Search" />
                    </Link>
                  </NavItem>
                ) : null}
                {config.app.enableReportingGrids && checkIfReportingServiceCloudPartnersExist() ? (
                  <NavItem>
                    <Link className="nav-link text-white" to="/reports-library" data-testid="headerReportsLibrary">
                      <Icon name="file-chart-line" prefix="fas" />
                      <FormattedMessage id="reportsLibrary" defaultMessage="Reports" />
                    </Link>
                  </NavItem>
                ) : null}
                <NavItem>
                  <Link
                    className="nav-link text-white"
                    to="/"
                    onClick={handleLogoutClick}
                    data-testid="headerUserLogoutMobile"
                  >
                    <Icon name="sign-out-alt" />
                    <FormattedMessage id="logout" defaultMessage="Logout" />
                  </Link>
                </NavItem>
              </Nav>
              <ConfirmationModal
                title={getActionModalModalTitleLiteral()}
                buttonsArray={getActionModalModalButtonsArray()}
                inputsArray={getActionModalModalInputLiteral()}
                onClose={handleActionModalModalChange}
                onModalChange={handleActionModalChangeEntries}
                isActive={!!actionModalSelectedModalOption}
                isDisabled={actionModalModalDisabled}
                ID="appActionModal"
                onToggle={() => {
                  setActionModalEntriesObject({});
                  setActionModalSelectedModalOption(null);
                }}
              />
            </Collapse>
          </>
        )}
      </Container>
    </Navbar>
  );
};

export default memo(AppHeader);
