import * as config from 'config';
import api from 'services/api';
import { ApiError, Job, JobEvent, Profile, ProfileEvent } from 'types';

// Job Events
export const createJobEvent = async (jobEventObject: {
  userId: number;
  companyId: number;
  jobId: number;
  event: JobEvent;
}): Promise<Job | ApiError> => {
  const response: Job = await api('/events/job', {
    method: 'POST',
    data: jobEventObject,
  });

  return response;
};

// Profile Events
export const createProfileEvent = async (profileEventObject: {
  userId: number;
  companyId: number;
  profileId: number;
  event: ProfileEvent;
}): Promise<Profile | ApiError> => {
  const response: Profile = await api('/events/profile', {
    method: 'POST',
    data: profileEventObject,
  });

  return response;
};

// Filter Options
export const fetchUsersForEvents = async () => {
  const response: { id: number; firstName: string; lastName: string; companyUserStatus: string }[] = await api(
    '/events/users'
  );

  return response;
};

export const fetchRelatedJobsForEvents = async () => {
  const response: { id: number; name: string }[] = await api('/events/jobs');

  return response;
};

// Generic Events
export const createEvent = (eventObject: {
  userId: number | null;
  userName: string;
  companyId: number | null;
  eventName: string;
  eventType: string;
  eventAction: string;
  event?: any;
  context?: any;
  object?: any;
  parent?: any;
}): void => {
  const querystring = eventObject.companyId ? `?company_id=${eventObject.companyId}` : '';
  api(config.eventsApi.url ? `${config.eventsApi.url}/events${querystring}` : '', {
    method: 'POST',
    data: {
      timestamps: {
        recordedAt: new Date(),
        sentAt: new Date(),
      },
      channel: 'quantumwork-web-app',
      company: {
        id: eventObject.companyId,
      },
      agent: {
        id: eventObject.userId,
        type: 'user',
        agent: {
          id: eventObject.userId,
          name: eventObject.userName,
        },
      },
      event: {
        name: eventObject.eventName,
        type: eventObject.eventType,
        action: eventObject.eventAction,
        ...eventObject.event,
      },
      object: eventObject.object,
      parent_object: eventObject.parent,
      context: eventObject.context,
    },
  });
};
