import './MultiSelectWithSubSections.scss';

import Icon from 'components/Icon';
import { useRef, useState } from 'react';
import { useEffect } from 'react';

interface Props {
  options: Array<any>;
  defaultValues?: [];
  allowOnlyOne?: boolean;
  valueToDisplay: string;
  onChange: (data: any) => void;
}

const MultiSelectWithSubSections = ({ options, onChange, valueToDisplay, defaultValues, allowOnlyOne }: Props) => {
  const inputRef: any = useRef(null);
  const [focus, setFocus] = useState<boolean>(false);
  const [openDropDawn, setOpenDropDawn] = useState<boolean>(true);
  const [textValue, setTextValue] = useState<string>('');
  const [currentOptions, setCurrentOptions] = useState<any>([...options]);
  const [currentValues, setCurrentValues] = useState<Array<any>>(
    defaultValues ? (Array.isArray(defaultValues) ? defaultValues : [defaultValues]) : []
  );

  const handleKeyPress = (text: string) => {
    setTextValue(text);
    setOpenDropDawn(true);
  };

  const handleBackSpace = (e: any) => {
    if (e.key === 'Backspace' && textValue === '') {
      setCurrentValues(currentValues.length > 0 ? [...currentValues.slice(0, currentValues.length - 1)] : []);
    }
  };

  const addClick = (object: any) => {
    if (currentValues.filter((value) => JSON.stringify(value) === JSON.stringify(object)).length === 0) {
      setCurrentValues(currentValues.length > 0 && !allowOnlyOne ? [...currentValues, object] : [object]);
      setTextValue('');
      setOpenDropDawn(false);
    }
  };

  useEffect(() => {
    const optionsWithoutCurrentValues = options.map((allOptionsCSubsection: any) => {
      const filteredOptions = allOptionsCSubsection.options.filter((allOptionsCOption: any) => {
        let appearOnValues = false;
        for (let currentOptionsOption of currentValues) {
          if (JSON.stringify(currentOptionsOption) === JSON.stringify(allOptionsCOption)) {
            appearOnValues = true;
          }
        }

        return !appearOnValues;
      });
      return { title: allOptionsCSubsection.title, options: filteredOptions };
    });
    const filtererByText = optionsWithoutCurrentValues.map((subsection: any) => {
      const filteredOptions = subsection.options.filter((option: any) => {
        return option[valueToDisplay]?.toString()?.toLowerCase().includes(textValue.toLowerCase());
      });
      return { title: subsection.title, options: filteredOptions };
    });

    setCurrentOptions(textValue ? [...filtererByText] : optionsWithoutCurrentValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textValue]);

  useEffect(() => {
    if (defaultValues) {
      const filteredOptions = options.map((allOptionsCSubsection) => {
        const filteredOptionsInner = allOptionsCSubsection.options.filter((allOptionsCOption: any) => {
          let dontAppearOnValues = true;
          for (let currentOptionsOption of defaultValues) {
            if (JSON.stringify(currentOptionsOption) === JSON.stringify(allOptionsCOption)) {
              dontAppearOnValues = false;
            }
          }
          return dontAppearOnValues;
        });
        return { title: allOptionsCSubsection.title, options: filteredOptionsInner };
      });
      setCurrentOptions(filteredOptions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    onChange(currentValues);
    const filteredOptions = options.map((allOptionsCSubsection) => {
      const filteredOptionsInner = allOptionsCSubsection.options.filter((allOptionsCOption: any) => {
        let dontAppearOnValues = true;
        for (let currentOptionsOption of currentValues) {
          if (JSON.stringify(currentOptionsOption) === JSON.stringify(allOptionsCOption)) {
            dontAppearOnValues = false;
          }
        }
        return dontAppearOnValues;
      });
      return { title: allOptionsCSubsection.title, options: filteredOptionsInner };
    });
    setCurrentOptions(filteredOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentValues]);

  const removeClick = (object: any) => {
    setCurrentValues(currentValues.filter((value) => JSON.stringify(value) !== JSON.stringify(object)));
    setOpenDropDawn(false);
  };

  const handlerFocus = () => {
    setFocus(true);
    handlerBlurF = () => {};
  };

  const handlerBlur = () => {
    handlerBlurF = () => {
      setTextValue('');
      setFocus(false);
      setOpenDropDawn(true);
    };
    setTimeout(() => {
      handlerBlurF();
    }, 200);
  };

  const openFullDropDown = () => {
    if (openDropDawn) {
      setTextValue('');
    }
    setOpenDropDawn(!openDropDawn);
  };

  let handlerBlurF = () => {
    setTextValue('');
    setFocus(false);
    setOpenDropDawn(true);
  };

  const clickClear = () => {
    inputRef.current?.focus();
    setCurrentValues([]);
  };

  return (
    <div style={{ width: '100%', position: 'relative' }}>
      <div
        tabIndex={0}
        onFocus={() => handlerFocus()}
        onBlur={() => handlerBlur()}
        className="InputContainer"
        style={{
          border: focus ? '1px solid #238CE7' : '1px solid hsl(0,0%,80%)',
          boxShadow: focus ? '0 0 0 2pt #4a769d66' : 'unset',
        }}
      >
        <div className="InputContainerInner">
          {currentValues.map((value) => (
            <div className="Flex" key={value[valueToDisplay]}>
              <p className="Tag">{value[valueToDisplay]}</p>
              <div onClick={() => removeClick(value)} className="TagIcon">
                <Icon name={'times'} />
              </div>
            </div>
          ))}
          <input
            type="text"
            value={textValue}
            onChange={(e) => handleKeyPress(e.target.value)}
            onKeyDown={(e) => handleBackSpace(e)}
            className="InputInput"
            ref={inputRef}
            data-testid="MultiSelectWithSubSectionText"
          />
        </div>
        <div className="Flex">
          <div className="InputIcon1" onClick={() => clickClear()}>
            <Icon name={'times'} />
          </div>
          <div className="InputIcon2" onClick={() => openFullDropDown()}>
            <Icon name={'angle-down'} />
          </div>
        </div>
      </div>
      {focus && openDropDawn ? (
        currentOptions.length ? (
          <div
            className="MultiSelectWithSubSectionsBody"
            tabIndex={0}
            onFocus={() => handlerFocus()}
            onBlur={() => handlerBlur()}
          >
            <ul data-testid="multiSelectWithSubSec_ul">
              {currentOptions.map((subsection: any) => (
                <>
                  {subsection.options.length > 0 && (
                    <div style={{ padding: '5px 10px' }}>
                      <b>{subsection.title}</b>
                    </div>
                  )}
                  {subsection.options.map((option: any, index: number) => (
                    <li
                      key={option[valueToDisplay] + index}
                      onClick={() => addClick(option)}
                      data-testid="SubSectionOption_ul"
                    >
                      {option[valueToDisplay]}
                    </li>
                  ))}
                </>
              ))}
            </ul>
          </div>
        ) : (
          <div className="MultiSelectWithSubSectionsNoOptions">No options</div>
        )
      ) : null}
    </div>
  );
};

export default MultiSelectWithSubSections;
