import { useEffect, useState } from 'react';
import { GoogleLocation, Location } from 'types';

const useFormattedLocation = (
  location: Location | GoogleLocation | Object | Partial<Location> | undefined | null
): string => {
  const loc = location as Location;
  const [formattedLocation, setFormattedLocation] = useState<string>('');

  useEffect(() => {
    if (loc?.city && loc?.stateAbbreviation) {
      setFormattedLocation(`${loc.city}, ${loc.stateAbbreviation}`);
    } else if (loc?.city && loc?.state) {
      setFormattedLocation(`${loc.city}, ${loc.state}`);
    } else if (loc?.state && loc?.countryCode) {
      setFormattedLocation(`${loc.state}, ${loc.countryCode}`);
    } else if (loc?.state && loc?.country) {
      setFormattedLocation(`${loc.state}, ${loc.country}`);
    } else if (loc?.country) {
      setFormattedLocation(`${loc.country}`);
    } else {
      setFormattedLocation('Unknown');
    }
  }, [loc]);

  return formattedLocation;
};

export default useFormattedLocation;
