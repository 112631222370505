import { AuthService } from '@okta/okta-react';
import * as config from 'config';
import cookie from 'js-cookie';
import { parse } from 'qs';
import history from 'services/history';
import uuid from 'services/uuid';

export const authLogout = async (authService: any) => {
  uuid({ key: 'sessionid', remove: true });

  localStorage.removeItem('qw.idleLastActiveTime');
  localStorage.removeItem('qw.idleRemainingTime');

  localStorage.removeItem('okta-pkce-storage');
  localStorage.removeItem('okta-cache-storage');
  localStorage.removeItem('okta-token-storage');

  sessionStorage.removeItem('okta-pkce-storage');

  cookie.remove('okta-oauth-nonce');
  cookie.remove('okta-oauth-state');

  await authService.logout('/login');
};

export const authService = new AuthService({
  clientId: config.okta.clientId,
  issuer: config.okta.issuerId,
  redirectUri: config.okta.redirectUrl,
  scopes: config.okta.scopes,
  responseMode: config.okta.responseMode,
  responseType: config.okta.responseType,
  pkce: config.okta.pkce,
  prompt: config.okta.prompt,
  cookies: {
    secure: config.env !== 'development' && config.env !== 'develop',
  },
  onAuthRequired: authLogout,
});

const auth = async () => {
  const path = 'accessToken';
  // eslint-disable-next-line no-underscore-dangle
  const tokens: any = await authService._oktaAuth.tokenManager.get(path);
  const params = parse(history.location.search, { ignoreQueryPrefix: true });
  let key = config.api.secureKey;
  let url = config.api.secureUrl;
  const token = tokens?.[path] ?? null;
  const publicToken = params?.t ?? null;

  if (token === null) {
    key = config.api.publicKey as string;
    url = config.api.publicUrl;
  }

  return { token, publicToken, url, key };
};

export default auth;
