import {
  findIconDefinition,
  IconDefinition,
  IconLookup,
  IconName,
  IconPrefix,
} from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CSSProperties } from 'react';

export interface IconProps {
  className?: string;
  name: IconName;
  prefix?: 'fab' | 'fas' | 'far' | 'fal' | 'fad';
  size?: 'xs' | 'lg' | 'sm' | '1x' | '2x' | '3x' | '4x' | '5x' | '6x' | '7x' | '8x' | '9x' | '10x' | undefined;
  title?: string;
  counter?: any;
  style?: CSSProperties | undefined;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}

const Icon = ({ name: iconName, prefix: iconPrefix, counter, ...props }: IconProps) => {
  const lookup: IconLookup = { prefix: iconPrefix as IconPrefix, iconName };
  const definition: IconDefinition = findIconDefinition(lookup);

  return (
    <>
      <span className="fa-layers fa-fw">
        <FontAwesomeIcon {...props} icon={definition} />
        {!!(counter && counter > 0) && (
          <span className="fa-layers-counter fa-layers-top-left" style={{ marginTop: '-9px', marginLeft: '6px' }}>
            {counter > 99 ? '99+' : counter}
          </span>
        )}
      </span>
    </>
  );
};

Icon.defaultProps = {
  className: 'mr-2',
  name: undefined,
  prefix: 'fas',
  size: undefined,
  title: undefined,
  counter: undefined,
};

export default Icon;
