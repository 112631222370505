import { Dropdown, MultiSelect } from 'components/commons';
import FieldText from 'components/FieldText';
import Icon from 'components/Icon';
import useBase64 from 'hooks/useBase64';
import { Moment } from 'moment-timezone';
import { SyntheticEvent, useEffect, useRef, useState } from 'react';
import DayPicker from 'react-day-picker';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import { uid } from 'react-uid';
import {
  Button,
  Col,
  DropdownMenu,
  DropdownToggle,
  Input,
  InputGroup,
  InputGroupButtonDropdown,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import { convertDate } from 'services/date';

interface Props {
  title: string;
  inputsArray: Array<any>;
  isActive: boolean;
  isDisabled: boolean;
  isScrollable?: boolean;
  ID: string;
  onModalChange: (buttonType: any, values: any) => void;
  onClose: (buttonType: any) => void;
  onToggle: () => void;
}

const BeelineModal = ({
  title,
  onClose,
  inputsArray,
  isActive,
  isDisabled,
  isScrollable,
  ID,
  onToggle,
  onModalChange,
}: Props) => {
  const monthsObject = [
    { month: 'January', number: '01' },
    { month: 'February', number: '02' },
    { month: 'March', number: '03' },
    { month: 'April', number: '04' },
    { month: 'May', number: '05' },
    { month: 'June', number: '06' },
    { month: 'July', number: '07' },
    { month: 'August', number: '08' },
    { month: 'September', number: '09' },
    { month: 'October', number: '10' },
    { month: 'November', number: '11' },
    { month: 'December', number: '12' },
  ];

  const daysLiteral: any = {
    '01': () => 31,
    '02': () => 29,
    '03': () => 31,
    '04': () => 30,
    '05': () => 31,
    '06': () => 30,
    '07': () => 31,
    '08': () => 31,
    '09': () => 30,
    '10': () => 31,
    '11': () => 30,
    '12': () => 31,
  };

  const componentLiteral: any = {
    String: (props: any) => {
      const [value, setValue] = useState<string>('');
      const [disabled, setDisabled] = useState<boolean>(false);
      const [touched, setTouched] = useState<boolean>(false);
      const changeValue = (e: SyntheticEvent<HTMLInputElement>) => {
        let value: any = e.currentTarget.value;
        if (props.validations && props.validations['max-length']) {
          if (value.length <= parseInt(props.validations['max-length'])) {
            setValue(value);
            props.handleModalChange(props, value);
          }
        } else {
          setValue(value);
          props.handleModalChange(props, value);
        }
      };

      useEffect(() => {
        props.handleModalChange(props, value);
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

      useEffect(() => {
        setDisabled(() => {
          if (
            (props['is-required'] && (!value || value === '')) ||
            (value &&
              props['data-type'] === 'string' &&
              props.validations &&
              props.validations['min-length'] &&
              value.length < parseInt(props.validations['min-length']))
          )
            return true;
          return false;
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [value]);

      return (
        <div key={uid(props.id + props.label)}>
          {props['is-required'] ? <p>{`${props.label} *`}</p> : <p>{props.label}</p>}
          <input
            type="text"
            className="form-control"
            onFocus={() => setTouched(true)}
            style={{ border: disabled && touched ? '1px solid red' : '1px solid #ced4da' }}
            value={value}
            data-testid={props.id}
            onChange={changeValue}
          />
          {props.description && <FieldText className="d-block">{props.description}</FieldText>}
          <br />
        </div>
      );
    },
    Integer: (props: any) => {
      const [value, setValue] = useState<string>(
        props.validations && props.validations['min-value']
          ? parseFloat(props.validations['min-value']).toFixed((props.validations && props.validations.decimal) || 0)
          : props.value
          ? props.value.toFixed((props.validations && props.validations.decimal) || 0)
          : parseFloat('0').toFixed((props.validations && props.validations.decimal) || 0)
      );
      const changeValue = (e: SyntheticEvent<HTMLInputElement>) => {
        setValue(e.currentTarget.value);
      };

      const formatValue = (e: SyntheticEvent<HTMLInputElement>) => {
        let valueToUse: any = parseFloat(e.currentTarget.value);
        if (
          props.validations &&
          props.validations['min-value'] &&
          valueToUse < parseFloat(props.validations['min-value'])
        )
          valueToUse = parseFloat(props.validations['min-value']);
        if (
          props.validations &&
          props.validations['max-value'] &&
          valueToUse > parseFloat(props.validations['max-value'])
        )
          valueToUse = parseFloat(props.validations['max-value']);

        valueToUse = valueToUse.toFixed((props.validations && props.validations.decimal) || 0);
        setValue(valueToUse);
        props.handleModalChange(props, valueToUse);
      };

      useEffect(() => {
        props.handleModalChange(props, value);
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

      return (
        <div key={uid(props.id + props.label)}>
          {props['is-required'] ? <p>{`${props.label} *`}</p> : <p>{props.label}</p>}
          <input
            type="number"
            className="form-control"
            value={value}
            data-testid={props.id}
            onChange={changeValue}
            onBlur={formatValue}
          />
          {props.description && <FieldText className="d-block">{props.description}</FieldText>}
          <br />
        </div>
      );
    },
    Money: (props: any) => {
      const [value, setValue] = useState<string>(
        props.validations && props.validations['min-value']
          ? parseFloat(props.validations['min-value']).toFixed((props.validations && props.validations.decimal) || 2)
          : props.value
          ? props.value.toFixed((props.validations && props.validations.decimal) || 2)
          : parseFloat('0').toFixed((props.validations && props.validations.decimal) || 2)
      );
      const changeValue = (e: SyntheticEvent<HTMLInputElement>) => {
        setValue(e.currentTarget.value);
      };

      const formatValue = (e: SyntheticEvent<HTMLInputElement>) => {
        let valueToUse: any = parseFloat(e.currentTarget.value || '0');
        if (
          props.validations &&
          props.validations['min-value'] &&
          valueToUse < parseFloat(props.validations['min-value'])
        )
          valueToUse = parseFloat(props.validations['min-value']);
        if (
          props.validations &&
          props.validations['max-value'] &&
          valueToUse > parseFloat(props.validations['max-value'])
        )
          valueToUse = parseFloat(props.validations['max-value']);

        valueToUse = valueToUse.toFixed((props.validations && props.validations.decimal) || 2);
        setValue(valueToUse);
        props.handleModalChange(props, valueToUse);
      };

      useEffect(() => {
        props.handleModalChange(props, value);
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

      return (
        <div key={uid(props.id + props.label)}>
          {props['is-required'] ? <p>{`${props.label} *`}</p> : <p>{props.label}</p>}
          <input
            type="number"
            className="form-control"
            value={value}
            data-testid={props.id}
            onChange={changeValue}
            onBlur={formatValue}
          />
          {props.description && <FieldText className="d-block">{props.description}</FieldText>}
          <br />
        </div>
      );
    },
    MonthPicker: (props: any) => {
      const [month, setMonth] = useState<string>('01');
      const [day, setDay] = useState<string>('01');
      const [currentDaysArray, setCurrentDaysArray] = useState<Array<any>>(
        [...Array(daysLiteral['01']()).keys()].map((element: number) => {
          return { day: element < 9 ? `0${element + 1}` : element + 1 };
        })
      );

      const handleChangeMonth = (value: any) => {
        setCurrentDaysArray(
          [...Array(daysLiteral[value]()).keys()].map((element: number) => {
            return { day: element < 9 ? `0${element + 1}` : element + 1 };
          })
        );
        setMonth(value);
      };

      useEffect(() => {
        props.handleModalChange(props, `${month}/${day}`);
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [month, day]);

      useEffect(() => {
        props.handleModalChange(props, `${month}/${day}`);
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

      return (
        <div key={uid(props.id + props.label)} style={{ marginBottom: '10px' }}>
          {props['is-required'] ? <p>{`${props.label} *`}</p> : <p>{props.label}</p>}
          <div style={{ display: 'flex' }}>
            <Dropdown
              defaultValueIndex={0}
              options={monthsObject}
              displayValue={'month'}
              onChange={(value) => handleChangeMonth(value)}
              returnValue={'number'}
              testid={props.id + 'Month'}
            />
            <div style={{ marginLeft: '10px', width: '50%' }}>
              <Dropdown
                defaultValueIndex={0}
                options={currentDaysArray}
                displayValue={'day'}
                onChange={(value) => setDay(value)}
                returnValue={'day'}
                testid={props.id + 'Day'}
              />
            </div>
          </div>
          {props.description && <FieldText className="d-block">{props.description}</FieldText>}
          <br />
        </div>
      );
    },
    DayPicker: (props: any) => {
      const format = 'MM/DD/YYYY';
      const [value, setValue] = useState<string>('');
      const [fieldDate, setFieldDate] = useState<Moment>();
      const [minDate] = useState<Moment | undefined>(props.min ? convertDate(props.min, format) : undefined);
      const [maxDate] = useState<Moment | undefined>(props.max ? convertDate(props.max, format) : undefined);
      const [isActive, setIsActive] = useState<boolean>(false);

      useEffect(() => {
        if (props.value) {
          setValue(props.value);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

      const handleValueChange = ({ formattedValue, value }: NumberFormatValues) => {
        if (formattedValue.match(/\d{2}\/\d{2}\/\d{4}/)) {
          const date = convertDate(formattedValue, format);

          if (date.isValid()) {
            setFieldDate(date);
          }
          setValue(formattedValue);
        }
        props.handleModalChange(props, formattedValue);
      };

      useEffect(() => {
        props.handleModalChange(props, value);
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

      const handleToggle = () => {
        setIsActive(!isActive);
      };

      const handleDisabledDays = (day: Date) => {
        const date = convertDate(day.toUTCString(), '');
        let disabled = false;

        if (minDate && minDate.isValid() && date.isBefore(minDate, 'day')) {
          disabled = true;
        }

        if (maxDate && maxDate.isValid() && date.isAfter(maxDate, 'day')) {
          disabled = true;
        }

        return disabled;
      };

      const handleDayClick = (day: Date, { disabled, selected }: any) => {
        const date = convertDate(day.toUTCString(), '');

        if (date.isValid() && !disabled && !selected) {
          props.handleModalChange(props, date.format('MM/DD/YYYY'));
          setValue(date.format('MM/DD/YYYY'));
          setFieldDate(date);
          setIsActive(!isActive);
        }
      };

      return (
        <div style={{ marginBottom: '10px' }}>
          {props['is-required'] ? <p>{`${props.label} *`}</p> : <p>{props.label}</p>}
          <InputGroup>
            <NumberFormat
              className={props.classNames ? (props.isInvalid ? props.isInvalid : props.classNames) : ''}
              type="text"
              placeholder={props['helper-text'] || 'MM/DD/YYYY'}
              disabled={props.isDisabled}
              readOnly={props.isReadOnly}
              onValueChange={handleValueChange}
              format="##/##/####"
              value={value}
              onChange={(e: any) => setValue(e.target.value)}
              customInput={Input}
              data-testid={`${props.name}Input`}
            />
            <InputGroupButtonDropdown addonType="append" toggle={handleToggle} isOpen={isActive}>
              <DropdownToggle
                className="dropdown-toggle text-input"
                color="gray"
                caret={false}
                data-testid={`${props.name}Dropdown`}
              >
                <Icon name="calendar" />
              </DropdownToggle>
              <DropdownMenu positionFixed>
                <DayPicker
                  initialMonth={fieldDate && fieldDate.isValid() ? fieldDate.toDate() : undefined}
                  disabledDays={handleDisabledDays}
                  selectedDays={fieldDate && fieldDate.isValid() ? fieldDate.toDate() : undefined}
                  onDayClick={handleDayClick}
                  showOutsideDays
                  data-testid={`${props.name}DatePicker`}
                />
              </DropdownMenu>
            </InputGroupButtonDropdown>
          </InputGroup>
          {props.description && <FieldText className="d-block">{props.description}</FieldText>}
          <br />
        </div>
      );
    },
    MultiSelect: (props: any) => {
      const handleChange = (value: any) => {
        props.handleModalChange(props, [...value]);
      };

      useEffect(() => {
        props.handleModalChange(props, []);
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

      return (
        <div style={{ marginBottom: '10px' }}>
          {props['is-required'] ? <p>{`${props.label} *`}</p> : <p>{props.label}</p>}
          <MultiSelect
            key={uid(props.id + props.label)}
            options={props['field-Items']}
            onChange={handleChange}
            valueToDisplay={'Item'}
          />
          {props.description && <FieldText className="d-block">{props.description}</FieldText>}
          <br />
        </div>
      );
    },
    SingleSelect: (props: any) => {
      const handleChange = (value: any) => {
        props.handleModalChange(props, [...value]);
      };

      useEffect(() => {
        props.handleModalChange(props, []);
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

      return (
        <div style={{ marginBottom: '10px' }}>
          {props['is-required'] ? <p>{`${props.label} *`}</p> : <p>{props.label}</p>}
          <MultiSelect
            key={uid(props.id + props.label)}
            options={props['field-Items']}
            allowOnlyOne
            onChange={handleChange}
            valueToDisplay={'Item'}
          />
          {props.description && <FieldText className="d-block">{props.description}</FieldText>}
          <br />
        </div>
      );
    },
    Checkbox: (props: any) => {
      const handleChange = (value: any) => {
        props.handleModalChange(props, [...value]);
      };

      return (
        <div style={{ marginBottom: '10px' }}>
          <div>
            <label className="containerTable" style={{ marginBottom: '0px' }}>
              <input type="checkbox" onClick={() => handleChange} data-testid="beelinModelCheck" />
              <span className="checkmark"></span>
              {props.label}
            </label>
          </div>
          {props.description && <FieldText className="d-block">{props.description}</FieldText>}
          <br />
        </div>
      );
    },
    Multifile: (props: any) => {
      const fileInput = useRef<any>(null);
      const { files, setFiles, getBase64 } = useBase64();

      useEffect(() => {
        props.handleModalChange(props, files);
        // eslint-disable-next-line
      }, [files]);

      return (
        <div>
          {props['is-required'] ? <p>{`${props.label} *`}</p> : <p>{props.label}</p>}
          {files.map((file: any, index: number) => (
            <div className="attachmentMultipleRow">
              {file.name}
              <button
                onClick={() => setFiles(files.filter((file: any, indexToUse: number) => index !== indexToUse))}
                data-testid="timesCircle_btn"
              >
                <Icon name="times-circle" />
              </button>
            </div>
          ))}
          <div className="attachmentRow">
            <span className="custom-file" style={{ marginBottom: '3%' }}>
              <button
                className="attachmentButton"
                onClick={() => fileInput.current && fileInput.current.click()}
                data-testid="addAttachements_btn"
              >
                + Add Attachment
                <input
                  hidden
                  ref={fileInput}
                  name="file"
                  type="file"
                  placeholder="Enter file"
                  className="custom-file-input"
                  accept="application/msword,
                        application/vnd.ms-word,
                        application/vnd.openxmlformats-officedocument.wordprocessingml.document,
                        application/msexcel,
                        application/vnd.ms-excel,
                        application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
                        application/pdf"
                  onClick={(e: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
                    const element = e.target as HTMLInputElement;
                    element.value = '';
                  }}
                  onChange={(event) => {
                    const fileToUse: any = (event.target.files && event.target.files[0]) || null;

                    if (fileToUse) {
                      getBase64(fileToUse, 'multifile');
                    }
                  }}
                  data-testid="beelinModelFileInput"
                />
              </button>
              {files?.size > 6000000 && (
                <p className="text-danger font-size-sm mt-1" style={{ height: 'calc(1.5em + 0.75rem + 3px)' }}>
                  Uploads over 6 Mb will be rejected due to size limitation.
                </p>
              )}
            </span>
          </div>
        </div>
      );
    },
    File: (props: any) => {
      const [fileName, setFileName] = useState<string>('Enter file');
      const { files, getBase64 } = useBase64();

      useEffect(() => {
        props.handleModalChange(props, files);
        // eslint-disable-next-line
      }, [files]);

      return (
        <>
          {props['is-required'] ? <p>{`${props.label} *`}</p> : <p>{props.label}</p>}
          <div className="attachmentRow">
            <div className="custom-file" style={{ marginBottom: '3%' }}>
              <input
                name="file"
                type="file"
                placeholder="Enter file"
                className="custom-file-input"
                accept="application/msword,
                        application/vnd.ms-word,
                        application/vnd.openxmlformats-officedocument.wordprocessingml.document,
                        application/msexcel,
                        application/vnd.ms-excel,
                        application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
                        application/pdf"
                onClick={(e: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
                  const element = e.target as HTMLInputElement;
                  element.value = '';
                }}
                onChange={(event) => {
                  const fileToUse: any = (event.target.files && event.target.files[0]) || null;

                  if (fileToUse) {
                    setFileName(fileToUse.name);
                    getBase64(fileToUse, 'file');
                  }
                }}
                data-testid="beelinModalFileOne"
              />
              <p className="custom-file-label  font-size-sm" style={{ height: 'calc(1.5em + 0.75rem + 3px)' }}>
                {fileName ? fileName : 'Enter file'}{' '}
              </p>
              {files?.size > 6000000 && (
                <p className="text-danger font-size-sm mt-1" style={{ height: 'calc(1.5em + 0.75rem + 3px)' }}>
                  Uploads over 6 Mb will be rejected due to size limitation.
                </p>
              )}
            </div>
          </div>
        </>
      );
    },
  };

  const handleModalChange = (props: any, value: any) => {
    onModalChange(props, value);
  };

  const changeTypeLiteral: any = {
    string: () => 'String',
    integer: () => 'Integer',
    money: () => 'Money',
    date: () => 'DayPicker',
    month: () => 'MonthPicker',
    'multi-select-list': () => 'MultiSelect',
    'single-select-list': () => 'SingleSelect',
    multifile: () => 'Multifile',
    checkbox: () => 'Checkbox',
    file: () => 'File',
  };

  return (
    <Modal size="lg" isOpen={isActive} backdrop="static" data-testid={ID}>
      <ModalHeader toggle={onToggle}>{title}</ModalHeader>
      <ModalBody>
        <div style={isScrollable ? { maxHeight: '65vh', overflowY: 'auto' } : {}}>
          {isActive &&
            inputsArray.map((input: any) => {
              return componentLiteral[changeTypeLiteral[input['data-type']]()]({ ...input, handleModalChange });
            })}
        </div>
      </ModalBody>
      <ModalFooter className="d-none d-md-flex">
        <Row>
          <Col xs="12">
            <div className="ml-auto">
              <Button
                onClick={() => {
                  onClose('Cancel');
                }}
                className="mr-3"
                outline
                data-testid="beelineCancel_btn"
              >
                Cancel
              </Button>
              <Button
                disabled={isDisabled}
                onClick={() => {
                  onClose('Submit');
                }}
                className="mr-3"
                data-testid="beelineSubmit_btn"
              >
                Submit
              </Button>
            </div>
          </Col>
        </Row>
      </ModalFooter>
    </Modal>
  );
};

export default BeelineModal;
