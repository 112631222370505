import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getCompany } from 'state/modules/user';
import { Job } from 'types/job';

import useMetadata from './useMetadata';
import { useUserTimezone } from './useUser';

const useJobCompanyUserTimezone = (job: Job): string => {
  const company = useSelector(getCompany);
  const { timezones } = useMetadata();
  const timezone = useUserTimezone();
  const [selectedTimeZone, setSelectedTimeZone] = useState<string>('UTC');

  useEffect(() => {
    const selectedTimeZone = (job && job.timezoneId) || company.settings.details.timezoneId || timezone;

    if (selectedTimeZone) {
      const timezoneFound = timezones.find(
        ({ id, timezone }) =>
          (typeof selectedTimeZone == 'number' && id === selectedTimeZone) ||
          (selectedTimeZone !== 'UTC' && timezone === selectedTimeZone)
      );
      setSelectedTimeZone(timezoneFound?.timezone ?? 'UTC');
    } else setSelectedTimeZone('UTC');
  }, [timezones]); // eslint-disable-line

  return selectedTimeZone;
};

export default useJobCompanyUserTimezone;
