import { useCallback } from 'react';

type Hook = {
  exportCSV: (array: Array<any>, title: string) => void;
};

const useCSV = (): Hook => {
  const exportCSV = useCallback((array: any, title: string) => {
    if (array.length > 0) {
      const titles: any = [...new Set(array.flatMap((element: any) => Object.keys(element)))];
      const arrayToSend = array.map((element: any) =>
        Array.from(Object.values(titles.map((key: string | number) => (element[key] ? element[key].toString() : ''))))
      );
      let csvContent =
        'data:text/csv;charset=utf-8,' + titles.join(',') + '\n' + arrayToSend.map((e: any) => e.join(',')).join('\n');
      const encodedUri = encodeURI(csvContent);
      const link = document.createElement('a');
      link.setAttribute('href', encodedUri);
      link.setAttribute('download', title + '.csv');
      link.click();
    }
  }, []);

  return {
    exportCSV,
  };
};

export default useCSV;
