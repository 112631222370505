import immer from 'immer';
import { combineReducers } from 'redux';
import { MetadataState, StoreReducer } from 'types';

import {
  FETCH_COMPANY_INVOICE_TYPES,
  FETCH_COMPENSATION_ClASSIFICATIONS,
  FETCH_COMPENSATION_TYPES,
  FETCH_CONTINGENT_RATE_CARDS,
  FETCH_CURRENCIES,
  FETCH_DELIVERY_CENTERS,
  FETCH_EDUCATION_LEVELS,
  FETCH_EMSI_SKILLS,
  FETCH_EXPERIENCE_LEVELS,
  FETCH_FULLTIME_RATE_CARDS,
  FETCH_GLOBAL_USERS,
  FETCH_LANGUAGES_METADATA,
  FETCH_LOCATIONS,
  FETCH_PAYMENT_MODEL_TYPES,
  FETCH_SOURCES,
  FETCH_TIMEZONES,
  FETCH_TYPEFORMS,
  FETCH_WORK_CATEGORIES,
} from './actions';

export * from './actions';
export * from './selectors';

export const initialState: MetadataState = {
  educationLevels: { ids: [], byId: [] },
  emsiSkills: { ids: [], byId: [] },
  globalUsers: { ids: [], byId: [] },
  experienceLevels: { ids: [], byId: [] },
  locations: { ids: [], byId: [] },
  sources: { ids: [], byId: [] },
  compensationTypes: { ids: [], byId: [] },
  compensationClassifications: { ids: [], byId: [] },
  paymentModelTypes: { ids: [], byId: [] },
  currencies: { ids: [], byId: [] },
  contingentRateCards: { ids: [], byId: [] },
  fulltimeRateCards: { ids: [], byId: [] },
  typeforms: { ids: [], byId: [] },
  languages: { languages: [], languageProficiencyLevels: [] },
  timezones: { ids: [], byId: [] },
  workCategories: { ids: [], byId: [] },
  deliveryCenter: { ids: [], byId: [] },
  companyInvoiceTypes: { ids: [], byId: [] },
};

const educationLevelsReducer: StoreReducer = immer((draft, action) => {
  const { type, data } = action;
  let next = draft;

  switch (type) {
    case FETCH_EDUCATION_LEVELS:
      next = data;
      break;
    default:
      break;
  }

  return next;
}, []);

const emsiSkillsReducer: StoreReducer = immer((draft, action) => {
  const { type, data } = action;
  let next = draft;

  switch (type) {
    case FETCH_EMSI_SKILLS:
      next = data;
      break;
    default:
      break;
  }

  return next;
}, []);

const globalUserReducer: StoreReducer = immer((draft, action) => {
  const { type, data } = action;
  let next = draft;

  switch (type) {
    case FETCH_GLOBAL_USERS:
      next = data;
      break;
    default:
      break;
  }

  return next;
}, []);

const experienceLevelsReducer: StoreReducer = immer((draft, action) => {
  const { type, data } = action;
  let next = draft;

  switch (type) {
    case FETCH_EXPERIENCE_LEVELS:
      next = data;
      break;
    default:
      break;
  }

  return next;
}, []);

const locationsReducer: StoreReducer = immer((draft, action) => {
  const { type, data } = action;
  let next = draft;

  switch (type) {
    case FETCH_LOCATIONS:
      next = data;
      break;
    default:
      break;
  }

  return next;
}, []);

const sourcesReducer: StoreReducer = immer((draft, action) => {
  const { type, data } = action;
  let next = draft;

  switch (type) {
    case FETCH_SOURCES:
      next = data;
      break;
    default:
      break;
  }

  return next;
}, []);

const currenciesReducer: StoreReducer = immer((draft, action) => {
  const { type, data } = action;
  let next = draft;

  switch (type) {
    case FETCH_CURRENCIES:
      next = data;
      break;
    default:
      break;
  }

  return next;
}, []);

const deliveryCenterReducer: StoreReducer = immer((draft, action) => {
  const { type, data } = action;
  let next = draft;

  switch (type) {
    case FETCH_DELIVERY_CENTERS:
      next = data;
      break;
    default:
      break;
  }

  return next;
}, []);

const compensationTypesReducer: StoreReducer = immer((draft, action) => {
  const { type, data } = action;
  let next = draft;

  switch (type) {
    case FETCH_COMPENSATION_TYPES:
      next = data;
      break;
    default:
      break;
  }

  return next;
}, []);

const languagesMetadataReducer: StoreReducer = immer((draft, action) => {
  const { type, data } = action;
  let next = draft;

  switch (type) {
    case FETCH_LANGUAGES_METADATA:
      next = data;
      break;
    default:
      break;
  }

  return next;
}, []);

const compensationClassificationsReducer: StoreReducer = immer((draft, action) => {
  const { type, data } = action;
  let next = draft;

  switch (type) {
    case FETCH_COMPENSATION_ClASSIFICATIONS:
      next = data;
      break;
    default:
      break;
  }

  return next;
}, []);

const paymentModelTypesReducer: StoreReducer = immer((draft, action) => {
  const { type, data } = action;
  let next = draft;

  switch (type) {
    case FETCH_PAYMENT_MODEL_TYPES:
      next = data;
      break;
    default:
      break;
  }

  return next;
}, []);

const contingentRateCardsReducer: StoreReducer = immer((draft, action) => {
  const { type, data } = action;
  let next = draft;

  switch (type) {
    case FETCH_CONTINGENT_RATE_CARDS:
      next = data;
      break;
    default:
      break;
  }

  return next;
}, []);

const fulltimeRateCardsReducer: StoreReducer = immer((draft, action) => {
  const { type, data } = action;
  let next = draft;

  switch (type) {
    case FETCH_FULLTIME_RATE_CARDS:
      next = data;
      break;
    default:
      break;
  }

  return next;
}, []);

const typeformsReducer: StoreReducer = immer((draft, action) => {
  const { type, data } = action;
  let next = draft;

  switch (type) {
    case FETCH_TYPEFORMS:
      next = data;
      break;
    default:
      break;
  }

  return next;
}, []);

const timezonesReducer: StoreReducer = immer((draft, action) => {
  const { type, data } = action;
  let next = draft;

  switch (type) {
    case FETCH_TIMEZONES:
      next = data;
      break;
    default:
      break;
  }

  return next;
}, []);

const workCategoriesReducer: StoreReducer = immer((draft, action) => {
  const { type, data } = action;
  let next = draft;

  switch (type) {
    case FETCH_WORK_CATEGORIES:
      next = data;
      break;
    default:
      break;
  }

  return next;
}, []);

const companyInvoiceTypesReducer: StoreReducer = immer((draft, action) => {
  const { type, data } = action;
  let next = draft;

  switch (type) {
    case FETCH_COMPANY_INVOICE_TYPES:
      next = data;
      break;
    default:
      break;
  }

  return next;
}, []);

export const metadataReducer: StoreReducer<MetadataState> = combineReducers({
  educationLevels: educationLevelsReducer,
  emsiSkills: emsiSkillsReducer,
  globalUsers: globalUserReducer,
  experienceLevels: experienceLevelsReducer,
  locations: locationsReducer,
  sources: sourcesReducer,
  currencies: currenciesReducer,
  compensationTypes: compensationTypesReducer,
  compensationClassifications: compensationClassificationsReducer,
  paymentModelTypes: paymentModelTypesReducer,
  contingentRateCards: contingentRateCardsReducer,
  fulltimeRateCards: fulltimeRateCardsReducer,
  typeforms: typeformsReducer,
  languages: languagesMetadataReducer,
  timezones: timezonesReducer,
  workCategories: workCategoriesReducer,
  deliveryCenter: deliveryCenterReducer,
  companyInvoiceTypes: companyInvoiceTypesReducer,
});
