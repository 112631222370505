import immer from 'immer';
import { CompanySetting, StoreReducer, UserState } from 'types';

import { actions } from './actions';

export * from './actions';
export * from './selectors';

export const initialState: UserState = {
  isLoading: false,
  isSuccess: false,
  err: null,
  user: {
    id: 0,
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    userRole: '',
    companyId: 0,
    deliveryCenterId: null,
    company: {
      id: 0,
      userId: 0,
      name: '',
      slug: '',
      description: '',
      type: 'client',
      phone: null,
      users: { ids: [], byId: [] },
      suppliers: { ids: [], byId: [] },
      serviceCloudPartners: { ids: [], byId: [] },
      jobWorkflows: {},
      permissions: {},
      displaySource: false,
      isMSP: false,
      isInvoiceModuleEnabled: false,
      isRPO: false,
      isCompanyAdmin: false,
      settings: {
        features: {
          actionManagementFlag: false,
        },
        details: {
          id: 0,
          timezoneId: 0,
          name: '',
          inboundProfileEmailPrefix: '',
          description: '',
          slug: '',
          logo: {
            fileName: '',
            url: '',
          },
        },
        emails: {
          footer: '',
          footerLinks: [],
        },
        emailTemplates: {
          companyEmailTemplates: {},
        },
        jobs: {
          canEditJobDescription: false,
          isInvoiceModuleEnabled: false,
          attachmentTypes: [],
          jobSources: { ids: [], byId: [] },
          jobStatuses: { ids: [], byId: [] },
          jobTypes: { ids: [], byId: [] },
          noteTypes: { ids: [], byId: [] },
          jobStatusChangeReasons: { ids: [], byId: [] },
          remoteOptions: [],
          invoiceOptions: [],
        },
        invoices: {
          invoiceOptions: [],
        },
        jobBoard: {
          brandDescription: '',
          mainColor: '',
          primaryFontColor: '',
          secondaryColor: '',
          secondaryFontColor: '',
          banner: {
            fileName: '',
            url: '',
          },
          slug: '',
          isPublic: false,
          jobsUrl: '',
          jobDetailUrl: '',
        },
        profiles: {
          attachmentTypes: [],
          noteTypes: { ids: [], byId: [] },
          dispositionReasons: { ids: [], byId: [] },
          enableConsentProcess: false,
          enableLegitimateInterest: false,
        },
        compliance: {
          gdpr: {
            gdprEffectiveDate: null,
            gdprFlag: 0,
            sourceExclusions: [{ id: 22, sourceId: 10, value: 'Test', sourceExclusionType: 'GDPR' }],
            template: {
              templates: [
                {
                  id: 10,
                  subject: '<h1>Hello There</h1>',
                  body: '<p>Test Body</p>',
                },
              ],
            },
          },
          casl: {
            caslFlag: 0,
            sourceExclusions: [{ id: 22, sourceId: 10, value: 'Test', sourceExclusionType: 'CASL' }],
            template: {
              templates: [
                {
                  id: 10,
                  subject: '<h1>Hello There</h1>',
                  body: '<p>Test Body</p>',
                },
              ],
            },
            caslConsentConfirmText: 'Test to confirm CASL',
          },
        },
        attachmentTypes: {
          profileAttachmentTypes: [],
          jobAttachmentTypes: [],
          anyAttachmentTypes: [],
        },
      },
    },
    settings: {
      emailTemplates: {},
      timezone: 'America/New_York',
      location: {},
      emailSignature: '',
      schedulingUrl: '',
    },
    jobTypes: {},
    companies: {},
    companyRoles: [],
    oktaId: '',
    oktaToken: '',
  },
};

export const userReducer: StoreReducer<UserState> = immer((draft: UserState, action) => {
  const { type, data } = action;
  let next = draft;

  switch (type) {
    case actions.FETCH_USER:
      next = {
        ...draft,
        isLoading: true,
        isSuccess: false,
        err: null,
      };
      break;
    case actions.FETCH_BASIC_USER_SUCCESS:
      next = {
        user: {
          ...initialState.user,
          ...data.user,
          company: {
            ...initialState.user.company,
            ...data.user.company,
          },
        },
        isLoading: false,
        isSuccess: true,
        err: null,
      };
      break;
    case actions.FETCH_USER_SUCCESS:
      next = {
        user: {
          ...next.user,
          ...data.user,
          company: {
            ...next.user.company,
            ...data.user.company,
          },
        },
        isLoading: false,
        isSuccess: true,
        err: null,
      };
      break;
    // created case for an inactive user login, this will be leveraged for users being tied to multiple companies
    case actions.INACTIVE_USER:
    case actions.FETCH_USER_ERROR:
      next = { isLoading: false, isSuccess: false, user: initialState.user, err: data.err };
      break;
    case actions.RESET_USER:
      next = initialState;
      break;
    case actions.SET_COMPANY_SETTING: {
      const setting = data as CompanySetting;
      // @ts-ignore
      next.user.company.settings[setting.key] = setting.value;
      break;
    }
    case actions.SET_USER_DETAILS: {
      const setting = {
        settings: {
          emailTemplates: next.user.settings.emailTemplates,
          timezone: data.timezone,
          emailSignature: data.emailSignature,
          location: data.location,
          schedulingUrl: data.schedulingUrl,
        },
        firstName: data.firstName,
        lastName: data.lastName,
        deliveryCenterId: data.deliveryCenterId,
      };
      // @ts-ignore
      next.user = {
        ...next.user,
        ...setting,
      };
      next.user.company.users.byId[data.userId].firstName = data.firstName;
      next.user.company.users.byId[data.userId].lastName = data.lastName;
      next.user.company.users.byId[data.userId].deliveryCenterId = data.deliveryCenterId;
      break;
    }
    case actions.SET_USER_EMAIL_TEMPLATE: {
      if (next.user.settings.emailTemplates[data.categoryName]?.templates) {
        const templates = next.user.settings.emailTemplates[data.categoryName].templates;
        const templateExist = templates.find((template) => template.id === data.id);
        if (templateExist) {
          next.user.settings.emailTemplates[data.categoryName].templates = templates.map((template) => {
            if (template.id === data.id) {
              let newTemp = { ...data };
              delete newTemp.categoryName;
              return newTemp;
            }
            return template;
          });
        } else {
          let newTemp = { ...data };
          delete newTemp.categoryName;
          next.user.settings.emailTemplates[data.categoryName].templates.push(newTemp);
        }
      }
      break;
    }
    case actions.DEL_USER_EMAIL_TEMPLATE: {
      if (next.user.settings.emailTemplates[data.categoryName]?.templates) {
        const templates = next.user.settings.emailTemplates[data.categoryName].templates;
        next.user.settings.emailTemplates[data.categoryName].templates = templates.filter(
          (template) => template.id !== data.id
        );
      }
      break;
    }
    default:
      break;
  }

  return next;
}, initialState);
