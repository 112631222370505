import { useSelector } from 'react-redux';
import {
  getContingentRateCardsMetadata,
  getFulltimeRateCardsMetadata,
  getGlobalUserMetadata,
  getSourcesMetadata,
} from 'state/modules/metadata';
import { GlobalUsers, ProfileSource, RateCard } from 'types';

interface Hook {
  sources: ProfileSource[];
  contingentRateCards: RateCard[];
  fullTimeRateCards: RateCard[];
  globalusers: GlobalUsers[];
}
/**
 * Returns the company specific metadata. If looking for app related metadata, see {@link useMetadata}.
 * @returns Company metadata
 * @example
 * const { sources, contingentRateCards, fullTimeRateCards } = useCompanyMetadata();
 */
const useCompanyMetadata = (): Hook => {
  return {
    sources: useSelector(getSourcesMetadata),
    contingentRateCards: useSelector(getContingentRateCardsMetadata),
    fullTimeRateCards: useSelector(getFulltimeRateCardsMetadata),
    globalusers: useSelector(getGlobalUserMetadata),
  };
};

export default useCompanyMetadata;
