import UserResource from 'components/UserResource';

export const withUserResource = (WrappedComponent: any) => {
  const WithUserResource = () => {
    return (
      <UserResource>
        <WrappedComponent />
      </UserResource>
    );
  };

  return WithUserResource;
};

export default withUserResource;
