import { useEffect, useState } from 'react';
import { Profile, ProfileFromSearch } from 'types';

import useCompany, { useCompanySuppliers } from './useCompany';

type Hook = {
  showImport: boolean;
  showSuppliedBadge: boolean;
};

const useShowSupplierOptions = (profile: Profile | ProfileFromSearch): Hook => {
  const company = useCompany();
  const companySuppliers = useCompanySuppliers();
  const [showImport, setShowImport] = useState<boolean>(false);
  const [showSuppliedBadge, setShowSuppliedBadge] = useState<boolean>(false);

  useEffect(() => {
    if (companySuppliers && profile.companyId) {
      setShowImport(
        companySuppliers
          .filter((companySupplier) => companySupplier.isRPO && companySupplier.supplierId !== company.id)
          .map((companySupplier) => companySupplier.supplierId)
          .includes(profile.companyId)
      );

      setShowSuppliedBadge(
        companySuppliers
          .filter((companySupplier) => companySupplier.supplierId !== company.id)
          .map((companySupplier) => companySupplier.supplierId)
          .includes(profile.companyId)
      );
    }
  }, [companySuppliers, profile.companyId]); // eslint-disable-line

  return { showImport, showSuppliedBadge };
};
export default useShowSupplierOptions;
