import { Component, ReactNode } from 'react';
import { Container } from 'reactstrap';

export interface RouteErrorBoundaryProps {
  children: ReactNode;
}

export interface RouteErrorBoundaryState {
  error: Error | null;
  hasError: boolean;
}

/**
 * ErrorBoundary class
 */
class RouteErrorBoundary extends Component<RouteErrorBoundaryProps, RouteErrorBoundaryState> {
  /**
   * @type {RouteErrorBoundaryState}
   */
  state: RouteErrorBoundaryState = {
    error: null,
    hasError: false,
  };

  /**
   * Method to get the derived state from the error
   * @param {any} error error
   * @returns {void} void
   */
  static getDerivedStateFromError(error: Error): RouteErrorBoundaryState {
    return { error, hasError: true };
  }

  /**
   * Render method
   * @returns {ReactElement} node
   */
  render() {
    const { children } = this.props;
    const { error, hasError } = this.state;

    if (hasError) {
      return (
        <Container className="mt-5">
          <h1>Error</h1>
          <p>{error?.message}</p>
        </Container>
      );
    }

    return children;
  }
}

export default RouteErrorBoundary;
