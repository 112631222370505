import cookie from 'js-cookie';
import { v1 as uuid1 } from 'uuid';

export type UuidOptions = {
  key: 'uuid' | 'sessionid';
  refresh?: boolean;
  remove?: boolean;
};

export const uuid = (options: UuidOptions = { key: 'uuid', refresh: false, remove: false }): string | null => {
  const { key, refresh, remove } = { refresh: false, remove: false, ...options };
  let uuid: string | null | undefined = cookie.get(key);

  if (remove) {
    uuid = null;

    cookie.remove(`qw-${key}`);
  } else if (!uuid || refresh) {
    uuid = uuid1();

    cookie.set(`qw-${key}`, uuid);
  }

  return uuid;
};

export default uuid;
