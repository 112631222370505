import { useUser } from 'hooks';
import { ReactNode } from 'react';

export interface UserResourceProps {
  children: ReactNode;
}

const UserResource = ({ children }: UserResourceProps) => {
  const user = useUser();

  return <>{user.id > 0 ? children : null}</>;
};

export default UserResource;
