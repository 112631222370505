import { usePublicToken } from 'hooks';
import { memo, ReactNode } from 'react';

export interface PublicResourceProps {
  children: ReactNode;
}

const PublicResource = ({ children }: PublicResourceProps) => {
  const { hasPublicToken } = usePublicToken();

  return <>{hasPublicToken ? children : null}</>;
};

export default memo(PublicResource);
