import * as notificationsApi from 'api/notifications';
import { useEffect, useState } from 'react';

type Hook = {
  notificationTypes: { id: number; name: string; displayName: string }[];
  notificationCategories: { id: number; name: string; displayName: string }[];
  notificationStatuses: string[];
};
const notificationStatuses = ['Done', 'Open'];

const useNotificationsFilters = (isNotificationsFilter: boolean = false): Hook => {
  const [notificationTypes, setNotificationTypes] = useState<{ id: number; name: string; displayName: string }[]>([]);
  const [notificationCategories, setNotificationCategories] = useState<
    { id: number; name: string; displayName: string }[]
  >([]);

  useEffect(() => {
    async function fetchNotificationTypesAndCategories() {
      const types = await notificationsApi.fetchNotificationTypes();
      const categories = await notificationsApi.fetchNotificationCategories();

      setNotificationTypes(types);
      setNotificationCategories(categories);
    }

    fetchNotificationTypesAndCategories();
  }, [isNotificationsFilter]);

  return {
    notificationTypes,
    notificationCategories,
    notificationStatuses,
  };
};

export default useNotificationsFilters;
