import marked from 'marked';
import { useCallback, useMemo } from 'react';

const useMarkdown = () => {
  const preReplacements: [RegExp, string][] = useMemo(
    () => [
      [/\n-/g, '\n*'],
      [/\r\n/g, '\n\n'],
    ],
    []
  );

  const postReplacements: [RegExp, string][] = useMemo(
    () => [
      [/<li><p>/g, '<li>'],
      [/<\/p><\/li>/g, '</li>'],
      [/<\/p>\n<\/li>/g, '</li>'],
    ],
    []
  );

  return useCallback(
    (md: string) => {
      let markdown = md;

      preReplacements.forEach(([regex, replacement]) => {
        markdown = markdown.replace(regex, replacement);
      });

      let html = marked(markdown);

      postReplacements.forEach(([regex, replacement]) => {
        html = html.replace(regex, replacement);
      });

      return html;
    },
    [preReplacements, postReplacements]
  );
};

export default useMarkdown;
