import { logFrontendError } from 'api/frontendErrors';
import * as reportsApi from 'api/reports';
import { useHashId } from 'hooks';
import { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { displayToast } from 'state/modules/toasts';
import { ReportProperties } from 'types';

import useUser from './useUser';

const useReports = () => {
  const dispatch = useDispatch();
  const user = useUser();
  const { encodeHash } = useHashId();

  // A reference to the current grid, allows you to call current.api or current.columnApi
  const currentReportRef = useRef<any>(null);
  // The currently available options to select a report to run and fetch data for
  const [reportOptions, setReportOptions] = useState<ReportProperties[]>([]);
  // The reports created by the logged in user
  const [myReportOptions, setMyReportOptions] = useState<ReportProperties[]>([]);
  // The standard reports of the system
  const [standardReportOptions, setStandardReportOptions] = useState<ReportProperties[]>([]);
  // Last reports opened by the logged in user
  // const [recentReportOptions, setRecentReportOptions] = useState<ReportProperties[]>([]);
  // Reports shared by other users to the logged in user
  const [sharedReportOptions, setSharedReportOptions] = useState<ReportProperties[]>([]);
  // A JSON object with metadata about the currently selected report
  const [selectedReport, setSelectedReport] = useState<ReportProperties>(Object);
  // An array filled with the data used in the grid. Can be very large (100k+ rows)
  const [rows, setRows] = useState<any>([]);
  // An array housing the column definitions for the grid. Dynamically generated based on rows
  const [columnDefinitions, setColumnDefinitions] = useState<any[]>([]);
  // An array filled with the column names that are in the column definitions
  const [columnDefinitionsNames, setColumnDefinitionsNames] = useState<any[]>([]);

  // Function that generates the grid options. Mostly static values but relies on the column definitions
  const generateGridOptions = (columnDefinitions: any) => {
    const gridOptions = {
      defaultColDef: {
        editable: true,
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        sortable: true,
        resizable: true,
        filter: true,
        flex: 1,
        minWidth: 100,
      },
      suppressRowClickSelection: true,
      groupSelectsChildren: true,
      debug: true,
      rowSelection: 'multiple',
      rowGroupPanelShow: 'always',
      pivotPanelShow: 'always',
      columnDefs: columnDefinitions,
      pagination: true,

      sideBar: true, // this property enables the side nav to toggle pivot mode on/off

      // custom loading template. the class ag-overlay-loading-center is part of the grid,
      // it gives a white background and rounded border
      overlayLoadingTemplate:
        '<span class="ag-overlay-loading-center" style="background: #dee2e6">Your data is loading...</span>',
      overlayNoRowsTemplate:
        '<span style="padding: 10px; border: 1px solid #152f55; background: #dee2e6;">No data to show. Please select a valid report.</span>',
    };

    return gridOptions;
  };

  // Function that determines if the selectedReport is a custom as opposed to a standard
  const isCustomReport = (selectedReport: any): boolean => {
    if (selectedReport && selectedReport.reportType === 'Custom') {
      return true;
    }

    return false;
  };

  // Function that determines if the selected owner is owned by the active logged in user
  const isReportOwner = (selectedReport: any): boolean => {
    if (selectedReport && selectedReport.reportOwnerUserId === user.id) {
      return true;
    }

    return false;
  };

  // Function that Export the data as a excel file
  const handleExportReport = (): void => {
    currentReportRef.current.api.exportDataAsExcel();
  };

  // Function that Export the data as a excel file
  const handleExportCSVReport = (): void => {
    currentReportRef.current.api.exportDataAsCsv();
  };

  // Function that fetches the list of possible reports from the API. Not currently in use
  const fetchReports = async () => {
    const reports = await reportsApi.fetchReports().then((response) => {
      dispatch(
        displayToast('success', {
          title: 'Reports Fetched',
          message: 'Successfully fetched reports.',
        })
      );

      return response;
    });

    return reports;
  };

  const fetchReportByReportId = async (reportId: number) => {
    const report = await reportsApi.fetchReportByReportId(reportId).then((response) => {
      const status = response?.response?.data?.status;
      const message = response?.response?.data?.message;
      if (status === 'error') {
        logFrontendError({ message: `${message}. The reportId is ${reportId}.`, errorCategory: 'reporting' });
      }
      return response;
    });
    return report;
  };

  // Function that handles the initial data load of a report + column and filter state
  const handleFirstDataRendered = (params: any, colState: any, filState: any, flagIsPivotMode: boolean) => {
    let columnsState: any[] = JSON.parse(colState);

    // 1. This is to check if columns have been removed.
    const { fieldsRemovedFromColumnDefinitions, fieldsWithRowGrouping } = columnsState?.reduce(
      (accumulator, currentColumn) => {
        if (currentColumn.colId !== 'ag-Grid-AutoColumn' && !columnDefinitionsNames.includes(currentColumn.colId)) {
          accumulator.fieldsRemovedFromColumnDefinitions.push(currentColumn.colId);
        }

        if (currentColumn.rowGroup) {
          accumulator.fieldsWithRowGrouping.push(currentColumn.colId);
        }
        return accumulator;
      },
      { fieldsRemovedFromColumnDefinitions: [] as string[], fieldsWithRowGrouping: [] as string[] }
    ) ?? { fieldsRemovedFromColumnDefinitions: [], fieldsWithRowGrouping: [] };

    // 2. This is to check if a filter was applied to any removed column.
    const filtersState: any = JSON.parse(filState);
    const filterStateColumnKeys = filtersState ? Object.keys(filtersState) : null;
    const removedColumnsWithFilters = filterStateColumnKeys
      ? filterStateColumnKeys.filter((filterColumnName) =>
          fieldsRemovedFromColumnDefinitions.includes(filterColumnName)
        )
      : [];

    // 3. This is to check if there was a row grouping in any removed column.
    const removedColumnsWithRowGrouping = fieldsWithRowGrouping.filter((fieldName: string) =>
      fieldsRemovedFromColumnDefinitions.includes(fieldName)
    );

    if (filState) {
      const obj = JSON.parse(filState);
      params.api.setFilterModel(obj);
    }

    if (colState && !flagIsPivotMode) {
      if (!removedColumnsWithRowGrouping.length) {
        if (fieldsRemovedFromColumnDefinitions.length) {
          columnsState = columnsState.filter((column) => !fieldsRemovedFromColumnDefinitions.includes(column.colId));

          if (removedColumnsWithFilters.length) {
            dispatch(
              displayToast('warning', {
                title: 'Report Changed',
                message: `The following columns are no longer present in the report and therefore, any filters applied to them are removed: ${fieldsRemovedFromColumnDefinitions.join(
                  ', '
                )}`,
                timeInMilliseconds: 10000,
              })
            );
          } else if (!removedColumnsWithFilters.length) {
            dispatch(
              displayToast('warning', {
                title: 'Report Changed',
                message: `The following columns are no longer present in the report: ${fieldsRemovedFromColumnDefinitions.join(
                  ', '
                )}`,
                timeInMilliseconds: 10000,
              })
            );
          }
        }
      }

      const stateApplied = params.columnApi.applyColumnState({ state: columnsState, applyOrder: true });
      if (!stateApplied) {
        handleDeleteState(false);

        // The message is going to be a warning if a column has been deleted and/or if a filter was applied to such column
        if (removedColumnsWithRowGrouping.length) {
          dispatch(
            displayToast('error', {
              title: 'Report Changed',
              message: `The custom report no longer is able to render. The following columns are no longer present: ${fieldsRemovedFromColumnDefinitions.join(
                ', '
              )}. The report was reset to the original state. Please make the new changes and save again.`,
              timeInMilliseconds: 10000,
            })
          );
        }
      }
    }
    if (flagIsPivotMode) {
      params.columnApi.setPivotMode(flagIsPivotMode);
    }
  };

  // Function that handles saving filter and column state to the API for a custom report
  const handleSaveReport = async (reportId: number) => {
    const columnState = currentReportRef.current.columnApi.getColumnState();
    const filState = currentReportRef.current.api.getFilterModel();
    const flagIsPivotMode = currentReportRef.current.columnApi.isPivotMode();

    await reportsApi
      .saveCustomReport(reportId, { agGridColumnState: columnState, agGridFilterState: filState, flagIsPivotMode })
      .then((response) => {
        const { createdReport } = response;

        if (createdReport) {
          dispatch(
            displayToast('success', {
              title: 'Report Saved',
              message: 'Successfully saved report.',
            })
          );
        } else {
          dispatch(
            displayToast('error', {
              title: 'Error Saving Report',
              message: 'Did not save report. Please try again.',
            })
          );
          logFrontendError({
            message: `An error occured while saving the report with id ${reportId}`,
            errorCategory: 'reporting',
          });
        }
      });
  };

  const handleSaveAsReport = async (
    companyReportDatasetId: number,
    reportName: string,
    reportDescription: string,
    flagIsPublicReport: number,
    selectedReport?: ReportProperties
  ) => {
    let columnState: any;
    let filState: any;
    // This happens when copying from the Reports Library
    if (selectedReport) {
      columnState = JSON.parse(selectedReport?.agGridColumnState) || null;
      filState = JSON.parse(selectedReport?.agGridFilterState) || null;
    } else {
      // This happens when copying from the report grid page
      columnState = currentReportRef.current?.columnApi.getColumnState() || null;
      filState = currentReportRef.current?.api.getFilterModel() || null;
    }
    let flagIsPivotMode = currentReportRef.current?.columnApi.isPivotMode();
    if (!flagIsPivotMode) {
      flagIsPivotMode = !!selectedReport?.flagIsPivotMode;
    }

    const { createdReport, latestUserReportOptions } = await reportsApi
      .createCustomReport({
        agGridColumnState: columnState,
        agGridFilterState: filState,
        companyReportDatasetId,
        reportName,
        reportDescription,
        flagIsPublicReport,
        flagIsPivotMode,
      })
      .then((response) => {
        const { createdReport, latestUserReportOptions } = response;

        if (createdReport) {
          dispatch(
            displayToast('success', {
              title: 'Report Created',
              message: selectedReport
                ? 'The report was succesfully copied.'
                : 'The report was successfully created. The active view is now swapped to this new report.',
            })
          );
          const [, hashedReportId] = encodeHash(createdReport.reportId!);
          window.history.replaceState(null, 'Quantum Work', `/reports/${hashedReportId}`);
        } else {
          dispatch(
            displayToast('error', {
              title: 'Report Not Created',
              message: 'The report was not created. Please try again.',
            })
          );
          logFrontendError({
            message: `An error occured while ${
              selectedReport ? 'copying' : 'creating'
            } a report with the name ${reportName} and companyReportDatasetId ${companyReportDatasetId}`,
            errorCategory: 'reporting',
          });
        }

        return {
          createdReport,
          latestUserReportOptions,
        };
      });

    return {
      createdReport,
      latestUserReportOptions,
    };
  };

  const handleDeleteReport = async (reportId: number) => {
    const response = await reportsApi.deleteReport(reportId).then((response) => {
      const { status, message } = response;
      if (status === 'error') {
        dispatch(
          displayToast('error', {
            title: 'Report Not Deleted',
            message: `${message} Please try again.`,
          })
        );
        logFrontendError({
          message: `An error occured while deleting the report with id ${reportId}`,
          errorCategory: 'reporting',
        });
      } else {
        dispatch(
          displayToast('success', {
            title: 'Report Deleted',
            message: 'The report was successfully deleted.',
          })
        );
      }
      return response;
    });

    return response;
  };

  const handleEditReportDetails = async (
    reportId: number,
    name: string,
    description: string,
    sharedWith: any[],
    specificDetailChange: string = 'Details'
  ) => {
    const response = await reportsApi.editReportDetails(reportId, name, description, sharedWith).then((response) => {
      const { status, message } = response;
      if (status === 'error') {
        dispatch(
          displayToast('error', {
            title: `Report ${specificDetailChange} Not Updated`,
            message: `${message} Please try again.`,
          })
        );
        logFrontendError({
          message: `An error occured while editing the report with id ${reportId}`,
          errorCategory: 'reporting',
        });
      } else {
        dispatch(
          displayToast('success', {
            title: `Report ${specificDetailChange} Updated`,
            message: `The report ${specificDetailChange.toLowerCase()} were successfully updated.`,
          })
        );
      }
      return response;
    });

    return response;
  };

  // Function that resets the column and filter state (locally), no API calls are made
  const handleDeleteState = (showToast: boolean) => {
    if (showToast) {
      dispatch(
        displayToast('success', {
          title: 'State Reset.',
          message: 'Successfully reset columns on local session.',
        })
      );
    }

    currentReportRef.current.columnApi.resetColumnState();
    currentReportRef.current.api.setFilterModel(null);
    currentReportRef.current.columnApi.setPivotMode(false);
  };

  // Function that handles swapping reports by applying the new state (if any) to the grid + the data
  const handleSwapReportSelection = (
    individualReportData: any,
    columnState: any,
    filterState: any,
    flagIsPivotMode: boolean
  ) => {
    currentReportRef.current.api.setRowData(individualReportData);

    if (columnState) {
      currentReportRef.current.columnApi.applyColumnState({
        state: JSON.parse(selectedReport.agGridColumnState),
        applyOrder: true,
      });
    } else {
      currentReportRef.current.columnApi.resetColumnState();
    }

    if (filterState) {
      currentReportRef.current.api.setFilterModel(JSON.parse(selectedReport.agGridFilterState));
    } else {
      currentReportRef.current.api.setFilterModel(null);
    }

    if (flagIsPivotMode) {
      currentReportRef.current.columnApi.setPivotMode(flagIsPivotMode);
    } else {
      currentReportRef.current.columnApi.setPivotMode(false);
    }
  };

  return {
    currentReportRef,
    rows,
    setRows,
    selectedReport,
    setSelectedReport,
    reportOptions,
    setReportOptions,
    myReportOptions,
    setMyReportOptions,
    standardReportOptions,
    setStandardReportOptions,
    sharedReportOptions,
    setSharedReportOptions,
    columnDefinitions,
    columnDefinitionsNames,
    isCustomReport,
    isReportOwner,
    setColumnDefinitions,
    setColumnDefinitionsNames,
    fetchReports,
    fetchReportByReportId,
    generateGridOptions,
    handleDeleteState,
    handleFirstDataRendered,
    handleSaveReport,
    handleSaveAsReport,
    handleSwapReportSelection,
    handleDeleteReport,
    handleEditReportDetails,
    handleExportReport,
    handleExportCSVReport,
  };
};

export default useReports;
