import * as config from 'config';
import { useCallback } from 'react';
import ReactGA from 'react-ga4';

const useGoogleAnalytics = (userID?: number) => {
  config.app.gaTrackingId &&
    ReactGA.initialize([
      {
        trackingId: config.app.gaTrackingId,
        gaOptions: {
          userId: userID || '',
        },
      },
    ]);

  const userEvent = useCallback((category: string, action: string, type: string) => {
    ReactGA.event({ category, action, label: type });
  }, []);

  const pageEvent = useCallback((link: string) => {
    ReactGA.send({ hitType: 'pageview', page: link });
  }, []);

  return {
    userEvent,
    pageEvent,
  };
};

export default useGoogleAnalytics;
