import useSwr, { keyInterface } from 'swr';

type fetcherFn<Data> = (...args: any) => Data | Promise<Data>;

const useApi = (keys: keyInterface, method: fetcherFn<any>, config: object = {}) => {
  const { data, error } = useSwr(keys, method, config);

  return {
    data,
    isError: error,
    isLoading: !error && !data,
  };
};

export default useApi;
