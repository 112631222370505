import { useSelector } from 'react-redux';
import { getCompany, getCompanyId, getCompanyServiceCloudPartners, getCompanySuppliers } from 'state/modules/user';
import { CompanyState, CompanySupplier, ServiceCloudPartners } from 'types';

type CompanyHook = {
  isClient: boolean;
  isSupplier: boolean;
};

export const useCompanyId = (): number => {
  const id = useSelector(getCompanyId);
  return id;
};

export const useCompanyServiceCloudPartners = (): ServiceCloudPartners[] => {
  const companyServiceCloudPartners = useSelector(getCompanyServiceCloudPartners);
  return companyServiceCloudPartners;
};

export const useCompanySuppliers = (): CompanySupplier[] => {
  const companySuppliers = useSelector(getCompanySuppliers);

  return companySuppliers;
};

const useCompany = (): CompanyState => {
  const company = useSelector(getCompany);

  return company;
};

export const useCompanyType = (): CompanyHook => {
  const company = useSelector(getCompany);

  return {
    isClient: company?.type === 'client' ?? false,
    isSupplier: company?.type === 'supplier' ?? false,
  };
};

export default useCompany;
