import './confirmationModal.scss';

import AlignmentPlugin from '@ckeditor/ckeditor5-alignment/src/alignment';
import AutoFormatPlugin from '@ckeditor/ckeditor5-autoformat/src/autoformat';
import BoldPlugin from '@ckeditor/ckeditor5-basic-styles/src/bold';
import ItalicPlugin from '@ckeditor/ckeditor5-basic-styles/src/italic';
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';
import EssentialsPlugin from '@ckeditor/ckeditor5-essentials/src/essentials';
import LinkPlugin from '@ckeditor/ckeditor5-link/src/link';
import ListPlugin from '@ckeditor/ckeditor5-list/src/list';
import MentionPlugin from '@ckeditor/ckeditor5-mention/src/mention';
import ParagraphPlugin from '@ckeditor/ckeditor5-paragraph/src/paragraph';
import CKEditor from '@ckeditor/ckeditor5-react';
import WordCountPlugin from '@ckeditor/ckeditor5-word-count/src/wordcount';
import { Dropdown, Location, MultiSelect, MultiSelectWithSubSections, Switch } from 'components/commons';
import FieldText from 'components/FieldText';
import Icon from 'components/Icon';
import { useCompany, useCopyToClipboard } from 'hooks';
import useJobCompanyUserTimezone from 'hooks/useJobCompanyUserTimezone';
import moment, { Moment } from 'moment-timezone';
import { SyntheticEvent, useEffect, useMemo, useState } from 'react';
import DayPicker from 'react-day-picker';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import { uid } from 'react-uid';
import {
  Button,
  Col,
  DropdownMenu,
  DropdownToggle,
  Input,
  InputGroup,
  InputGroupButtonDropdown,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import { CustomInput } from 'reactstrap';
import { convertDate } from 'services/date';
import { CompanyAttachmentType } from 'types';
import { Job } from 'types';

interface Props {
  title: string;
  buttonsArray: Array<any>;
  inputsArray: Array<any>;
  isActive: boolean;
  isDisabled: boolean;
  isScrollable?: boolean;
  ID: string;
  job?: Job;
  onModalChange: (buttonType: any, values: any) => void;
  onClose: (buttonType: any) => void;
  onToggle: () => void;
}

const ConfirmationModal = ({
  title,
  buttonsArray,
  onClose,
  inputsArray,
  isActive,
  isDisabled,
  isScrollable,
  ID,
  onToggle,
  onModalChange,
  job,
}: Props) => {
  const componentLiteral: any = {
    Label: (props: any) =>
      props.dontDisplay ? null : (
        <p key={uid(props)} className={props.class || {}}>
          {props.label}
        </p>
      ),
    Bold: (props: any) => (
      <p key={uid(props)}>
        <b>{props.label}</b>
      </p>
    ),
    MultiSelect: (props: any) => {
      const handleChange = (value: any) => {
        props.handleModalChange(props, [...value]);
      };
      return !props.dontDisplay ? (
        <div style={{ marginBottom: '10px' }}>
          <MultiSelect
            key={uid(props.name)}
            options={props.options}
            disabled={props.disabled}
            allowOnlyOne={props.allowOnlyOne}
            onChange={handleChange}
            valueToDisplay={props.displayValue}
            defaultValues={props.defaultValues}
            readOnly={props.readOnly}
          />
        </div>
      ) : (
        <></>
      );
    },
    MultiSelectWithSubSections: (props: any) => {
      const handleChange = (value: any) => {
        props.handleModalChange(props, [...value]);
      };
      return !props.dontDisplay ? (
        <div style={{ marginBottom: '10px' }}>
          <MultiSelectWithSubSections
            key={uid(props.name)}
            options={props.options}
            allowOnlyOne={props.allowOnlyOne}
            onChange={handleChange}
            valueToDisplay={props.displayValue}
            defaultValues={props.defaultValues}
          />
        </div>
      ) : (
        <></>
      );
    },
    Dropdown: (props: any) => {
      const handleChange = (value: any) => {
        props.handleModalChange(props, value);
      };

      useEffect(() => {
        if (props.options.length > 0 && props.dontPreloadValue) {
          props.handleModalChange(props, props.options[0][props.returnValue]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

      return !props.dontDisplay ? (
        <div style={{ marginBottom: '10px' }}>
          <Dropdown
            defaultValueIndex={props.defaultValueIndex}
            isDisabled={props.isDisabled}
            options={props.options}
            title={props.title || ''}
            placeholder={props.placeholder || ''}
            displayValue={props.displayValue}
            onChange={handleChange}
            returnValue={props.returnValue}
            testid={props.name}
          />
        </div>
      ) : (
        <></>
      );
    },
    Copy: (props: any) => {
      const { copyToClipboard } = useCopyToClipboard();
      return (
        <div style={{ marginBottom: '10px', marginTop: '10px', width: '100%' }}>
          <input
            type="text"
            disabled={true}
            value={props.value}
            style={{ width: 'calc(100% - 50px)', padding: '10px' }}
            data-testid="confirmationModalOne"
          />
          <span
            className="copy-icon"
            onClick={() => !(props.disabled || !props.value) && copyToClipboard(props.value, 'Email')}
            style={{ marginLeft: '20px', color: !(props.disabled || !props.value) ? '#152f55' : '#a7a7a791' }}
          >
            <Icon name="copy" />
          </span>
        </div>
      );
    },
    Location: (props: any) => {
      const handleChange = (value: any) => {
        props.handleModalChange(props, value);
      };

      return !props.dontDisplay ? (
        <div style={{ marginBottom: '10px' }}>
          <Location onSelect={(e) => handleChange(e)} />
        </div>
      ) : (
        <></>
      );
    },
    MultiLocation: (props: any) => {
      const [locations, setLocations] = useState<Array<any>>([{}]);

      useEffect(() => {
        if (props.defaultValues) setLocations([...props.defaultValues]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

      useEffect(() => {
        props.handleModalChange(
          props,
          locations.filter((element) => Object.keys(element).length)
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [locations]);

      const handleChangeLocation = (value: any, index: number) => {
        const locationCopy = [...locations];
        locationCopy[index] = value;
        setLocations([...locationCopy]);
      };

      return !props.dontDisplay ? (
        <div style={{ marginBottom: '10px' }}>
          {
            // eslint-disable-next-line
            locations.map((location, index) => (
              <div style={{ marginTop: '10px' }}>
                <Location
                  onSelect={(e) => handleChangeLocation(e, index)}
                  formatted_address={typeof location === 'string' ? location : location.formattedAddress || undefined}
                />
              </div>
            ))
          }
          <button
            onClick={() => setLocations([...locations, {}])}
            className="buttonAdditionLocation"
            data-testid="additionalLocation_btn"
          >
            + Add Additional Location
          </button>
        </div>
      ) : (
        <></>
      );
    },
    TextArea: (props: any) => {
      const [characterCount, setCharacterCount] = useState<number>(props.maxLength || 5000);

      const changeValue = (event: any, editor: any) => {
        props.handleModalChange(props, editor.getData());
      };

      const config = useMemo(
        () => ({
          plugins: [
            EssentialsPlugin,
            BoldPlugin,
            ItalicPlugin,
            ParagraphPlugin,
            AlignmentPlugin,
            LinkPlugin,
            ListPlugin,
            MentionPlugin,
            AutoFormatPlugin,
            WordCountPlugin,
          ],
          toolbar: ['bold', 'italic', 'alignment', '|', 'bulletedList', 'numberedList', '|', 'link'],
          link: {
            addTargetToExternalLinks: true,
          },
          mention: {
            feeds: [
              {
                marker: '@',
                feed: () => (props.mentions as string[]).map((mention) => `@${mention}`),
                minimumCharacters: 0,
              },
            ],
          },
          wordCount: {
            onUpdate: ({ characters }: { words: number; characters: number }) => {
              setCharacterCount(characterCount - characters);
            },
          },
        }),
        [characterCount, props.mentions]
      );

      return (
        <>
          <div className={props.classNames || ''}>
            <CKEditor
              editor={ClassicEditor}
              config={config}
              placeholder={props.placeholder || ''}
              disabled={props.isDisabled || false}
              readOnly={props.isReadOnly || false}
              data-testid={props.name}
              onChange={changeValue}
            />
          </div>
          {props.hasCounter && characterCount !== undefined ? (
            <FieldText>{characterCount} characters remaining</FieldText>
          ) : null}
          <br></br>
        </>
      );
    },
    DayPicker: (props: any) => {
      const format = 'MM/DD/YYYY';
      const [value, setValue] = useState<string>('');
      const [fieldDate, setFieldDate] = useState<Moment>();
      const [minDate] = useState<Moment | undefined>(props.min ? convertDate(props.min, format) : undefined);
      const [maxDate] = useState<Moment | undefined>(props.max ? convertDate(props.max, format) : undefined);
      const [isActive, setIsActive] = useState<boolean>(false);

      useEffect(() => {
        if (props.value) {
          setValue(props.value);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

      const handleValueChange = ({ formattedValue, value }: NumberFormatValues) => {
        if (formattedValue.match(/\d{2}\/\d{2}\/\d{4}/)) {
          const date = convertDate(formattedValue, format);

          if (date.isValid()) {
            setFieldDate(date);
          }
          setValue(formattedValue);
        }
        props.handleModalChange(props, formattedValue);
      };

      const handleToggle = () => {
        setIsActive(!isActive);
      };

      const handleDisabledDays = (day: Date) => {
        const date = convertDate(day.toUTCString(), '');
        let disabled = false;

        if (minDate && minDate.isValid() && date.isBefore(minDate, 'day')) {
          disabled = true;
        }

        if (maxDate && maxDate.isValid() && date.isAfter(maxDate, 'day')) {
          disabled = true;
        }

        return disabled;
      };

      const handleDayClick = (day: Date, { disabled, selected }: any) => {
        const date = convertDate(day.toUTCString(), '');

        if (date.isValid() && !disabled && !selected) {
          props.handleModalChange(props, date.format(format));
          setValue(date.format(format));
          setFieldDate(date);
          setIsActive(!isActive);
        }
      };

      return (
        <div style={{ marginBottom: '10px' }}>
          <InputGroup>
            <NumberFormat
              className={props.classNames ? (props.isInvalid ? props.isInvalid : props.classNames) : ''}
              type="text"
              placeholder={props.placeholder || format}
              disabled={props.isDisabled}
              readOnly={props.isReadOnly}
              onValueChange={handleValueChange}
              format="##/##/####"
              value={value}
              onChange={(e) => setValue(e.target.value)}
              customInput={Input}
              data-testid={`${props.name}Input`}
            />
            <InputGroupButtonDropdown addonType="append" toggle={handleToggle} isOpen={isActive}>
              <DropdownToggle
                className="dropdown-toggle text-input"
                color="gray"
                caret={false}
                data-testid={`${props.name}Dropdown`}
              >
                <Icon name="calendar" />
              </DropdownToggle>
              <DropdownMenu positionFixed>
                <DayPicker
                  initialMonth={fieldDate && fieldDate.isValid() ? fieldDate.toDate() : undefined}
                  disabledDays={handleDisabledDays}
                  selectedDays={fieldDate && fieldDate.isValid() ? fieldDate.toDate() : undefined}
                  onDayClick={handleDayClick}
                  showOutsideDays
                  data-testid={`${props.name}DatePicker`}
                />
              </DropdownMenu>
            </InputGroupButtonDropdown>
          </InputGroup>
        </div>
      );
    },
    HourPicker: (props: any) => {
      const format = 'MM/DD/YYYY';
      const [value, setValue] = useState<string>('');
      const [hour, setHour] = useState<any>({ display: '12:00 PM', time: { hour: 12, minutes: 0 } });
      // const [timeZone, setTimeZone] = useState<any>(0);
      const [fieldDate, setFieldDate] = useState<Moment>();
      const [minDate] = useState<Moment | undefined>(props.min ? convertDate(props.min, format) : undefined);
      const [maxDate] = useState<Moment | undefined>(props.max ? convertDate(props.max, format) : undefined);
      const [isActive, setIsActive] = useState<boolean>(false);
      const selectedTimeZone = useJobCompanyUserTimezone(job!);

      const hoursObject = [
        { display: '12:00 AM', time: { hour: 0, minutes: 0 } },
        { display: '12:30 AM', time: { hour: 0, minutes: 30 } },
        { display: '01:00 AM', time: { hour: 1, minutes: 0 } },
        { display: '01:30 AM', time: { hour: 1, minutes: 30 } },
        { display: '02:00 AM', time: { hour: 2, minutes: 0 } },
        { display: '02:30 AM', time: { hour: 2, minutes: 30 } },
        { display: '03:00 AM', time: { hour: 3, minutes: 0 } },
        { display: '03:30 AM', time: { hour: 3, minutes: 30 } },
        { display: '04:00 AM', time: { hour: 4, minutes: 0 } },
        { display: '04:30 AM', time: { hour: 4, minutes: 30 } },
        { display: '05:00 AM', time: { hour: 5, minutes: 0 } },
        { display: '05:30 AM', time: { hour: 5, minutes: 30 } },
        { display: '06:00 AM', time: { hour: 6, minutes: 0 } },
        { display: '06:30 AM', time: { hour: 6, minutes: 30 } },
        { display: '07:00 AM', time: { hour: 7, minutes: 0 } },
        { display: '07:30 AM', time: { hour: 7, minutes: 30 } },
        { display: '08:00 AM', time: { hour: 8, minutes: 0 } },
        { display: '08:30 AM', time: { hour: 8, minutes: 30 } },
        { display: '09:00 AM', time: { hour: 9, minutes: 0 } },
        { display: '09:30 AM', time: { hour: 9, minutes: 30 } },
        { display: '10:00 AM', time: { hour: 10, minutes: 0 } },
        { display: '10:30 AM', time: { hour: 10, minutes: 30 } },
        { display: '11:00 AM', time: { hour: 11, minutes: 0 } },
        { display: '11:30 AM', time: { hour: 11, minutes: 30 } },
        { display: '12:00 PM', time: { hour: 12, minutes: 0 } },
        { display: '12:30 PM', time: { hour: 12, minutes: 30 } },
        { display: '01:00 PM', time: { hour: 13, minutes: 0 } },
        { display: '01:30 PM', time: { hour: 13, minutes: 30 } },
        { display: '02:00 PM', time: { hour: 14, minutes: 0 } },
        { display: '02:30 PM', time: { hour: 14, minutes: 30 } },
        { display: '03:00 PM', time: { hour: 15, minutes: 0 } },
        { display: '03:30 PM', time: { hour: 15, minutes: 30 } },
        { display: '04:00 PM', time: { hour: 16, minutes: 0 } },
        { display: '04:30 PM', time: { hour: 16, minutes: 30 } },
        { display: '05:00 PM', time: { hour: 17, minutes: 0 } },
        { display: '05:30 PM', time: { hour: 17, minutes: 30 } },
        { display: '06:00 PM', time: { hour: 18, minutes: 0 } },
        { display: '06:30 PM', time: { hour: 18, minutes: 30 } },
        { display: '07:00 PM', time: { hour: 19, minutes: 0 } },
        { display: '07:30 PM', time: { hour: 19, minutes: 30 } },
        { display: '08:00 PM', time: { hour: 20, minutes: 0 } },
        { display: '08:30 PM', time: { hour: 20, minutes: 30 } },
        { display: '09:00 PM', time: { hour: 21, minutes: 0 } },
        { display: '09:30 PM', time: { hour: 21, minutes: 30 } },
        { display: '10:00 PM', time: { hour: 22, minutes: 0 } },
        { display: '10:30 PM', time: { hour: 22, minutes: 30 } },
        { display: '11:00 PM', time: { hour: 23, minutes: 0 } },
        { display: '11:30 PM', time: { hour: 23, minutes: 30 } },
      ];

      useEffect(() => {
        if (props.value) {
          setValue(props.value);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

      useEffect(() => {
        if (value) {
          let newDate = moment(`${value} ${hour.time.hour}:${hour.time.minutes}`)
            .tz(selectedTimeZone)
            .set({ hour: hour.time.hour, minute: hour.time.minutes });
          const dateToSend = moment(newDate).toISOString();
          props.handleModalChange(props, dateToSend);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [hour, value]);

      const handleValueChange = ({ formattedValue, value }: NumberFormatValues) => {
        if (formattedValue.match(/\d{2}\/\d{2}\/\d{4}/)) {
          const date = convertDate(formattedValue, format);

          if (date.isValid()) {
            setFieldDate(date);
          }
          setValue(formattedValue);
        }
      };

      const handleToggle = () => {
        setIsActive(!isActive);
      };

      const handleDisabledDays = (day: Date) => {
        const date = convertDate(day.toUTCString(), '');
        let disabled = false;

        if (minDate && minDate.isValid() && date.isBefore(minDate, 'day')) {
          disabled = true;
        }

        if (maxDate && maxDate.isValid() && date.isAfter(maxDate, 'day')) {
          disabled = true;
        }

        return disabled;
      };

      const handleDayClick = (day: Date, { disabled, selected }: any) => {
        const date = convertDate(day.toUTCString(), '');

        if (date.isValid() && !disabled && !selected) {
          setValue(date.format('MM/DD/YYYY'));
          setFieldDate(date);
          setIsActive(!isActive);
        }
      };

      const handleChangeHour = (value: any) => {
        let selectedHour = null;
        for (let element of hoursObject) {
          if (element.display === value) {
            selectedHour = element;
          }
        }
        setHour(selectedHour);
      };
      // const handleChangeTimeZone = (value: any) => {
      //   setTimeZone(value);
      // };

      return (
        <div style={{ marginBottom: '10px' }} className="hourRow">
          <InputGroup>
            <NumberFormat
              className={props.classNames ? (props.isInvalid ? props.isInvalid : props.classNames) : ''}
              type="text"
              placeholder={props.placeholder || 'MM/DD/YYYY'}
              disabled={props.isDisabled}
              readOnly={props.isReadOnly}
              onValueChange={handleValueChange}
              format="##/##/####"
              value={value}
              onChange={(e) => setValue(e.target.value)}
              customInput={Input}
              data-testid={`${props.name}Input`}
            />
            <InputGroupButtonDropdown addonType="append" toggle={handleToggle} isOpen={isActive}>
              <DropdownToggle
                className="dropdown-toggle text-input"
                color="gray"
                caret={false}
                data-testid={`${props.name}Dropdown`}
              >
                <Icon name="calendar" />
              </DropdownToggle>
              <DropdownMenu positionFixed>
                <DayPicker
                  initialMonth={fieldDate && fieldDate.isValid() ? fieldDate.toDate() : undefined}
                  disabledDays={handleDisabledDays}
                  selectedDays={fieldDate && fieldDate.isValid() ? fieldDate.toDate() : undefined}
                  onDayClick={handleDayClick}
                  showOutsideDays
                  data-testid={`${props.name}DatePicker`}
                />
              </DropdownMenu>
            </InputGroupButtonDropdown>
          </InputGroup>
          <div style={{ margin: '0px 15px', width: '100%' }}>
            <Dropdown
              defaultValueIndex={24}
              isDisabled={!value}
              options={hoursObject}
              displayValue={'display'}
              onChange={(value) => handleChangeHour(value)}
              returnValue={'display'}
              testid={props.id + 'display'}
            />
          </div>
          {/* <Dropdown
            defaultValueIndex={27}
            options={timeZoneObject}
            displayValue={'label'}
            onChange={(value) => handleChangeTimeZone(value)}
            returnValue={'value'}
            testid={props.id + 'label'}
          /> */}
          <input style={{ textAlign: 'center', borderRadius: '4px' }} disabled value={selectedTimeZone}></input>
        </div>
      );
    },
    space: () => {
      return <br />;
    },
    customInput: (props: any) => {
      const changeValue = ({ currentTarget }: SyntheticEvent<HTMLInputElement>) => {
        props.handleModalChange(props, currentTarget.value);
      };

      return (
        <CustomInput
          id={uid(props.name)}
          key={uid(props.name)}
          label={props.label}
          type={props.type}
          onChange={changeValue}
          placeholder={props.placeholder}
          data-testid="PconfirmationModal_slct"
        />
      );
    },
    Switch: (props: any) => {
      const [value, setValue] = useState<boolean>(props.value || false);

      useEffect(() => {
        props.handleModalChange(props, value);
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [value]);

      return (
        <div style={{ display: 'flex' }}>
          <Switch id={uid(props.name)} testid={uid(props.name)} value={value} onChange={() => setValue(!value)} />
          {props.label ? props.label : ''}
        </div>
      );
    },
    Attachment: (props: any) => {
      const [attachmentTypes, setAttachmentTypes] = useState<Array<any>>([]);
      const company = useCompany();
      const [fileName, setFileName] = useState<string>('Enter file');
      const [fileType, setType] = useState<string>('Select type');
      const [file, setFile] = useState<any>(null);

      const changeValue = (file: any) => {
        setFile(file);
      };

      useEffect(() => {
        if (company.id) {
          const profileAttachmentTypesToDisplay = company.settings.attachmentTypes.profileAttachmentTypes.filter(
            (type: CompanyAttachmentType) => type.showOnChangeStageAttachmentAdd && type.active
          );
          const jobAttachmentTypesToDisplay = company.settings.attachmentTypes.jobAttachmentTypes.filter(
            (type: CompanyAttachmentType) => type.showOnChangeStageAttachmentAdd && type.active
          );
          const anyAttachmentTypesToDisplay = company.settings.attachmentTypes.anyAttachmentTypes.filter(
            (type: CompanyAttachmentType) => type.showOnChangeStageAttachmentAdd && type.active
          );

          setAttachmentTypes([
            { displayName: 'Select type' },
            ...[
              ...profileAttachmentTypesToDisplay,
              ...jobAttachmentTypesToDisplay,
              ...anyAttachmentTypesToDisplay,
            ].sort((a, b) => a.displayName.localeCompare(b.displayName)),
          ]);
        }
        return () => {
          // cancel the request before component unmounts
          setAttachmentTypes([]);
        };
        // eslint-disable-next-line
      }, [company]);

      useEffect(() => {
        if (fileType === 'Select type') {
          setFileName('Enter file');
          setFile(null);
        }
        props.handleModalChange(props, { file, attachmentTypeId: fileType });
        // eslint-disable-next-line
      }, [fileType, file]);

      useEffect(() => {
        setTimeout(() => {
          setType('Select type');
        }, 100);
        // eslint-disable-next-line
      }, []);

      useEffect(() => {
        attachmentTypes.length > 0 && setType(attachmentTypes[0].attachmentTypeId);
        // eslint-disable-next-line
      }, [attachmentTypes]);

      const handleChangeAttachmentTypes = (type: any) => {
        setType(type);
      };

      return (
        <div className="attachmentRow">
          <Dropdown
            defaultValueIndex={0}
            options={attachmentTypes}
            displayValue={'displayName'}
            onChange={(value) => handleChangeAttachmentTypes(value)}
            returnValue={'attachmentTypeId'}
            testid={props.id}
          />
          <div className="custom-file" style={{ marginLeft: '15px', marginBottom: '3%' }}>
            <input
              name="file"
              type="file"
              key={fileType}
              disabled={fileType === 'Select type'}
              placeholder="Enter file"
              className="custom-file-input"
              data-testid="confirmationModalcustomInputFile"
              accept="application/msword,
                        application/vnd.ms-word,
                        application/vnd.openxmlformats-officedocument.wordprocessingml.document,
                        application/msexcel,
                        application/vnd.ms-excel,
                        application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
                        application/pdf"
              onClick={(e: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
                const element = e.target as HTMLInputElement;
                element.value = '';
              }}
              onChange={(event) => {
                const file = (event.target.files && event.target.files[0]) || null;

                if (file) {
                  setFileName(file.name);
                  changeValue(file);
                }
              }}
            />
            <p className="custom-file-label  font-size-sm" style={{ height: 'calc(1.5em + 0.75rem + 3px)' }}>
              {fileName ? fileName : 'Enter file'}{' '}
            </p>
            {file?.size > 6000000 && (
              <p className="text-danger font-size-sm mt-1" style={{ height: 'calc(1.5em + 0.75rem + 3px)' }}>
                Uploads over 6 Mb will be rejected due to size limitation.
              </p>
            )}
          </div>
        </div>
      );
    },
    Input: (props: any) => {
      const [value, setValue] = useState<string>(props.value || '');
      const changeValue = (e: SyntheticEvent<HTMLInputElement>) => {
        let value: any = e.currentTarget.value;
        setValue(value);
        props.handleModalChange(props, value);
      };

      const blur = (e: SyntheticEvent<HTMLInputElement>) => {
        let value: any = e.currentTarget.value;
        if (props.step) {
          value = props.floatDecimals
            ? parseFloat(e.currentTarget.value).toFixed(props.floatDecimals)
            : parseInt(e.currentTarget.value);
          value = isNaN(value)
            ? value
            : props.min && props.min > value
            ? props.min
            : props.max && props.max < value
            ? props.max
            : value;
          value =
            (props.floatDecimals
              ? parseFloat(e.currentTarget.value).toFixed(props.floatDecimals)
              : parseInt(e.currentTarget.value)) + '';
        }
        setValue(value);
        props.handleModalChange(props, value);
      };

      return (
        <>
          <Input
            className={props.classNames}
            disabled={props.isDisabled}
            readOnly={props.isReadOnly}
            value={value}
            data-testid={props.name}
            key={uid(props.name)}
            type={props.type}
            min={props.min}
            max={props.max}
            step={props.step}
            onChange={changeValue}
            onBlur={blur}
            placeholder={props.placeholder}
          />
          {props.validationLabel && <span style={{ color: '#ff253a', fontSize: '12px' }}>{props.validationLabel}</span>}
        </>
      );
    },
    Row: (props: any) => {
      return (
        <div style={{ ...props['style'] }}>
          {props.columns.map((column: any) => (
            <div style={{ ...column['style'] }}>
              {column['items'].map((input: any) => {
                return componentLiteral[input.type]({ ...input.props, handleModalChange: props.handleModalChange });
              })}
            </div>
          ))}
        </div>
      );
    },
  };

  const handleModalChange = (props: any, value: any) => {
    onModalChange(props, value);
  };

  return (
    <Modal size="lg" isOpen={isActive} backdrop="static" data-testid={ID}>
      <ModalHeader toggle={onToggle}>{title}</ModalHeader>
      <ModalBody>
        <div style={isScrollable ? { maxHeight: '65vh', overflowY: 'auto' } : {}}>
          {isActive &&
            inputsArray.map((input: any) => {
              return componentLiteral[input.type]({ ...input.props, handleModalChange });
            })}
        </div>
      </ModalBody>
      <ModalFooter className="d-none d-md-flex mt-3">
        <Row>
          <Col xs="12">
            <div className="ml-auto">
              {buttonsArray.map((button: any) => {
                return (
                  <Button
                    disabled={button.disabledOnCondition ? isDisabled : false}
                    onClick={() => {
                      onClose(button.label);
                    }}
                    className="mr-3"
                    key={uid(button.label)}
                    outline={button.type !== 'primary'}
                    data-testid="confirmation_btn"
                  >
                    {button.label}
                  </Button>
                );
              })}
            </div>
          </Col>
        </Row>
      </ModalFooter>
    </Modal>
  );
};

export default ConfirmationModal;
