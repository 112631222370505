import { useOktaAuth } from '@okta/okta-react';
import * as userApi from 'api/user';
import AppErrorBoundary from 'boundaries/AppErrorBoundary';
import AppRouter from 'components/App/AppRouter';
import Footer from 'components/Footer';
import Header from 'components/Header';
import ToastRouter from 'components/ToastRouter';
import * as config from 'config';
import {
  useAPIEvents,
  useCompany,
  useErrorFetchingUser,
  useGoogleAnalytics,
  useIsSuccessFetchingUser,
  usePublicToken,
  useUser,
} from 'hooks';
import { Suspense, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import { useIdleTimer } from 'react-idle-timer';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { authLogout } from 'services/auth';
import uuid from 'services/uuid';
import { fetchMetadata, fetchMetadataPublic } from 'state/modules/metadata';
import { fetchUser } from 'state/modules/user';

export interface AppProps {}

/**
 * App component
 * @param {AppProps} props props for the component
 */
const App = () => {
  const { authService, authState } = useOktaAuth();
  const dispatch = useDispatch();
  const dispatchRef = useRef(false);
  const ref = useRef<boolean>(false);
  const company = useCompany();
  const { hasPublicToken } = usePublicToken();
  const history = useHistory();

  const handleIdle = () => {
    (async () => {
      await authLogout(authService);
    })();
  };

  const handleAction = async () => {
    localStorage.setItem('qw.idleLastActiveTime', getLastActiveTime()?.toString() ?? '');
    localStorage.setItem('qw.idleRemainingTime', getRemainingTime().toString());
  };

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    onIdle: handleIdle,
    onAction: handleAction,
    timeout: config.okta.timeout,
    events: [
      'mousemove',
      'keydown',
      'wheel',
      'DOMMouseScroll',
      'mousewheel',
      'mousedown',
      'touchstart',
      'touchmove',
      'MSPointerDown',
      'MSPointerMove',
      'visibilitychange',
    ],
    crossTab: true,
    syncTimers: 10,
    name: 'qw-idle-timer',
    debounce: 250,
    throttle: 0,
    eventsThrottle: 200,
    element: document,
    startOnMount: true,
    stopOnIdle: false,
  });

  // below code block is done to allow us to confirm and navigate that a deactivated user attempted to log in
  const { id } = useUser();
  const isSuccessFetchingUser = useIsSuccessFetchingUser();
  const errorFetchingUser = useErrorFetchingUser();
  const { pageEvent } = useGoogleAnalytics(id);
  const { sendUserActionEvent } = useAPIEvents();

  useEffect(() => {
    (async () => {
      if (isSuccessFetchingUser) {
        await authService.updateAuthState();

        if (history.location.pathname === '/' && !ref.current) {
          await userApi.recordUserLoginInformation();
          ref.current = true;
        }

        pageEvent(history.location.pathname);
        sendUserActionEvent('Page Viewed', 'read', 'page', {
          page: {
            path: history.location.pathname,
            referrer: document.referrer,
            title: document.title,
            url: document.baseURI,
          },
          userAgent: window.navigator.userAgent,
        });
      }
    })();
  }, [authService, history.location.pathname, isSuccessFetchingUser]); // eslint-disable-line

  useEffect(() => {
    const currentTime = +new Date();
    const lastActiveTime = parseInt(localStorage.getItem('qw.idleLastActiveTime') ?? '0', 10);
    const remainingTime = parseInt(localStorage.getItem('qw.idleRemainingTime') ?? '0', 10);

    (async () => {
      if (!authState.isPending) {
        if (history.location.pathname !== '/login') {
          if (lastActiveTime > 0 && remainingTime > 0 && lastActiveTime + remainingTime - currentTime < 0) {
            await authLogout(authService);
          } else if (authState.isAuthenticated && authState.accessToken === undefined) {
            await authLogout(authService);
          } else if (authState.isAuthenticated && authState.accessToken) {
            // if this condition is met the user will be directly navigated to the inactive user login page
            if (errorFetchingUser) {
              history.push('/inactive-user-login');
            } else if (!isSuccessFetchingUser) {
              dispatch(fetchUser());
              dispatchRef.current = true;
              uuid({ key: 'sessionid' });
            }
          }
        }
      }
    })();
  }, [authState, errorFetchingUser, isSuccessFetchingUser]); // eslint-disable-line

  useEffect(() => {
    if (!authState.isPending && authState.isAuthenticated) {
      if (company.id > 0) {
        dispatch(fetchMetadata(company.id));
      } else {
        setTimeout(() => {
          // This will check wether the fetchUser() has been dispatched or not.
          // If it was dispatched, it'll check if after 5 seconds the company.id property in the state is 0. If that's the case, we'll fetch the user data again.
          // If it was not dispatched initially (who knows why), we'll dispatch it once more, just in case.
          if (dispatchRef.current) {
            if (company.id === 0) {
              dispatch(fetchUser());
            }
          } else {
            dispatch(fetchUser());
          }
        }, 5000);
      }
    } else if (hasPublicToken) {
      dispatch(fetchMetadataPublic(company.id));
    }
  }, [company.id]); // eslint-disable-line

  return (
    <div className="d-flex flex-column vh-100">
      <Helmet defaultTitle="Acumen" />
      <Suspense fallback={<header />}>
        <Header isLoggedIn={isSuccessFetchingUser} />
      </Suspense>
      <Suspense fallback={<div />}>
        <div
          className="flex-grow-1 flex-shrink-0"
          style={!history.location.pathname.includes('/casl/confirm-consent') ? { marginTop: '66px' } : {}}
        >
          <AppErrorBoundary>
            <AppRouter />
            <ToastRouter />
          </AppErrorBoundary>
        </div>
      </Suspense>
      <Suspense fallback={<footer />}>
        <Footer />
      </Suspense>
    </div>
  );
};

export default App;
